import React from 'react';
import NewsFilter from './filter';
import { List, TextField, FunctionField } from 'react-admin';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { DownloadableField } from 'Downloadable/DownloadableComponents';

const buttonStyle = {
  background: '#4169e1', // Royal blue
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '12px 25px',
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  transition: '0.3s',
  outline: 'none'
};

const hoverStyle = {
  backgroundColor: '#4f74e3', // Lighter royal blue
  transform: 'scale(1.05)', // Slightly enlarge the button
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
};

export const NewsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <List
        title='News'
        filters={<NewsFilter />}
        pagination={<Paginator />}
        perPage={15}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
        empty={<EmptyPage create />}
        actions={<ListActions listExporterType='News' />}
      >
        <DataGridList edit show>

          <TextField source='title' label='Title' />
          <TextField source='description' label='Description' />

          <FunctionField
            label="Status"
            render={(record) => record.status === "active" ? "Active" : "Inactive"}
          />

          <FunctionField
            label="Users Group"
            render={(record) => {
              switch (record.user_group) {
                case "all":
                  return "All";
                case "network":
                  return "Network";
                case "students":
                  return "Students";
              }
            }}
          />

          <FunctionField
            label="Attachment Type"
            render={(record) => {
              switch (record.attachment_type) {
                case "website":
                  return "URL";
                case "file":
                  return "File";
                default:
                  return "N/A";
              }
            }}
          />

          <FunctionField
            label="Attachment"
            render={(record) => {
              if (!record.attachment_type) {
                return "N/A";
              }
              if (record.attachment_type === "website") {
                return <a
                  href={record.attachment_url}
                  target='_blank'
                  style={buttonStyle}
                  onMouseOver={e => Object.assign(e.target.style, hoverStyle)}
                  onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
                >
                  View
                </a>
              }
              return <DownloadableField source='attachment_url.url' label='File' />
            }}
          />

        </DataGridList>

      </List>
    </>
  )
};
