import AchievementsIcon from '@mui/icons-material/EmojiEvents';

import { AchievementsList } from './list'
import { AchievementsShow } from './show';
import { AchievementsEdit } from './edit';
import { AchievementCreate } from './create';

export default {
  name: "achievements",
  options: { label: "Achievements", menuParent: "flash_cards" },
  icon: AchievementsIcon,
  list: AchievementsList,
  show: AchievementsShow,
  create: AchievementCreate,
  edit: AchievementsEdit,
};
