import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  FileInput,
  ImageField,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { FileUploaderPlaceholder } from "../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder";
import Breadcrumbs from "components/Breadcrumbs";


export const NotificationTypeEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
        <Edit title={<EditTitle source="name" />} {...props}>
            <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
              <TextInput source="name" />
              <ReferenceInput
                source="client_action_id"
                reference="client_actions"
                label="Client action"
                perPage={25}
                filterToQuery={(searchText) => ({
                  iso: searchText,
                  _autocomplete: true,
                })}
                sort={{ field: "name", order: "ASC" }}
                fullWidth
                >
                <AutocompleteInput
                  matchSuggestion={(filterValue, suggestion) => true}
                  optionText={(choice) =>  `${choice?.name} (${choice?.required_linkable_type})`      
                  }
                />
            </ReferenceInput>
              <TextInput
                source="title_pattern"
                label="Title Pattern"
                fullWidth
              />
              <TextInput
                source="body_pattern"
                label="Body Pattern"
                fullWidth
              />
              <BooleanInput source="use_sender_thumbnail" label="Use sender's thumbnail?" fullWidth />
              <BooleanInput source="send_push" label="Send push notifications?" fullWidth />
              <BooleanInput source="send_sms" label="Send SMS notifications?" fullWidth />

                  <FileInput
                      source="thumbnail_file"
                      accept="image/*"
                      label="Thumbnail"
                      placeholder={<FileUploaderPlaceholder />}
                      fullWidth
                  >
                      <ImageField source="src" />
                  </FileInput>

            </SimpleForm>
        </Edit>
      </>
    );
}
