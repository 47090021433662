import Breadcrumbs from "components/Breadcrumbs";
import { DateFieldShow } from "components/Fields";

import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
import { ViewTitle } from "../../../components/AppBar/Titles";

export const ClientActionsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="name" label="Name" />
          <TextField source="required_linkable_type" />
          <DateFieldShow label="Created At" source="created_at" />
          <DateFieldShow label="Updated At" source="updated_at" />
        </SimpleShowLayout>
      </Show>
    </>
  );
}