import React from 'react';
import { useRecordContext, Labeled } from 'react-admin';

export const JSONField = ({ source, label }) => {
    let record = useRecordContext();

    if (!record) return null;

    const obj = typeof record[source] === 'string' ? JSON.parse(record[source]) : {};

    // If the object is null or empty, return N/A (not applicable)
    if (!obj || Object.keys(obj)?.length <= 0) {
        return (
            <Labeled label={label || "JSON Data"}>
                <div style={{ fontStyle: 'italic', color: '#bbb', fontSize: '14px', padding: '5px' }}>N/A</div>
            </Labeled>
        );
    }

    const previousValues = typeof record.previous_values === 'string' ? JSON.parse(record.previous_values) : {};
    const newValues = typeof record.new_values === 'string' ? JSON.parse(record.new_values) : {};

    // Find keys with differing values between previous and new values
    let differingKeys = [];
    if (Object.keys(previousValues).length === 0) {
        differingKeys = Object.keys(newValues);
    } else if (Object.keys(newValues).length === 0) {
        differingKeys = Object.keys(previousValues);
    } else {
        differingKeys = Object.keys(previousValues).filter(key => {
            if (typeof previousValues?.[key] === 'object' && typeof newValues?.[key] === 'object') {
                return JSON.stringify(previousValues?.[key]) !== JSON.stringify(newValues?.[key]);
            } else {
                return previousValues?.[key] !== newValues?.[key];
            }
        });
    }

    const getStyleForValue = (key) => {
        if (differingKeys.includes(key)) {
            return source === "previous_values" 
                ? { color: '#e60000', backgroundColor: '#ffe6e6', fontWeight: 'bold', padding: '4px 8px', borderRadius: '4px' } 
                : { color: '#00b300', backgroundColor: '#e6ffe6', fontWeight: 'bold', padding: '4px 8px', borderRadius: '4px' };
        }
        return {};
    };

    const containerStyle = {
        border: '1px solid #3a3a3a',
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: '#2a2a2a',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.12)',
        fontSize: '15px',
        overflow: 'auto'
    };

    const keyValueStyle = {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '14px',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    };

    const keyStyle = {
        fontWeight: '600',
        marginRight: '8px',
        color: '#e74c3c',
        backgroundColor: '#343434',
        borderRadius: '4px',
        padding: '4px 8px',
        flexShrink: 0
    };

    const valueStyle = {
        wordBreak: 'break-all',
        color: '#bdc3c7',
        backgroundColor: '#343434',
        padding: '4px 8px',
        borderRadius: '4px',
        maxWidth: '80%'
    };

    return (
        <Labeled label={label || "JSON Data"}>
            <div style={containerStyle}>
                {Object.entries(obj).map(([key, value]) => (
                    <div key={key} style={keyValueStyle}>
                        <span style={keyStyle}>{key}:</span>
                        <span style={{...valueStyle, ...getStyleForValue(key)}}>
                            {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                        </span>
                    </div>
                ))}
            </div>
        </Labeled>
    );
}
