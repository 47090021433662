import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
	Edit,
	required,
	FileInput,
	TextInput,
	ImageField,
	SimpleForm,
} from "react-admin";

export const CategoryEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent} />
			<Edit title={<EditTitle source="name" />} {...props}>
				<SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
					<TextInput source='name' validate={required()} />
					<TextInput source="sort_index" validate={required()} />
					<FileInput
						source="thumbnail_file"
						accept="image/*"
						label="Thumbnail"
						placeholder={<FileUploaderPlaceholder />}
						fullWidth
					>
						<ImageField source="src" />
					</FileInput>
				</SimpleForm>
			</Edit>
		</>
	);
}
