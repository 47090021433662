import React from "react";
import { JSONField } from 'components/JSON';
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField } from "react-admin";

export const UserAccessShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="id" />} {...props}>
        <SimpleShowLayout>
          
        <TextField source="id" label="ID"/>
        <TextField source="user_id" label="User ID"/>

        <ReferenceField 
          source="user_id"
          reference="users"
          label="Username"
          link={false}
        >
          <FunctionField render={(record) => `${record.username}`} />
        </ReferenceField>

        <TextField source='client_app_id' label='Application ID'/>
        <TextField source='last_login' label='Last Login'/>
      
        </SimpleShowLayout>
      </Show>
  </>
);
}