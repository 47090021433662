import { Resource } from "react-admin";
import SystemIcon from "@material-ui/icons/Settings";

import deviceViews from './Devices/index';
import deviceLimitViews from './DeviceLimit/index';
import minimumVersionViews from './MinimumVersion/index';
import settingViews from './Settings/index';
import adminLogViews from './AdminLogs/index';
import supportViews from './SupportTickets/index';
import moderatorsViews from './Moderators/index';
import supportersViews from './Supporters/index';
import userAccessViews from './UserAccess/index';

const SystemRoutes = [
  <Resource
    name="system"
    icon={SystemIcon}
    options={{ label: "System", isMenuParent: true }}
  />,
  <Resource {...adminLogViews} />,
  <Resource {...deviceViews}/>,
  <Resource {...deviceLimitViews}/>,
  <Resource {...minimumVersionViews}/>,
  <Resource {...userAccessViews}/>,
  <Resource {...supportViews}/>,
  <Resource {...moderatorsViews}/>,
  <Resource {...supportersViews}/>,
  <Resource {...settingViews}/>,
  <Resource name="admin_users"/>
];

export default SystemRoutes;
