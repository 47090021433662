import NotificationsIcon from '@mui/icons-material/Notifications';

// import { NotificationsEdit } from './edit';
import { NotificationsList } from './list'
import { NotificationsShow } from './show';
// import { NotificationCreate } from './create';

export default {
  name: "notifications",
  options: { label: "Main", menuParent: "_notifications" },
  icon: NotificationsIcon,
  list: NotificationsList,
  show: NotificationsShow,
  // create: NotificationCreate,
  // edit: NotificationsEdit,
};
