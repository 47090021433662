import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { 
   Edit,
   TabbedForm,
   TextInput,
   required,
   FormDataConsumer,
   ReferenceInput,
   AutocompleteInput,
   FormTab,
   ReferenceArrayInput,
   AutocompleteArrayInput,
   BooleanInput,
} from "react-admin";

import { Typography } from "@mui/material";

export const LessonEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>

        <TabbedForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
          <FormTab label='Details'>
              <TextInput source="name" validate={required()} fullWidth />
              <BooleanInput source='is_active' label='Is Active' validate={required()} />
              <TextInput source='sort_index' label="Sort Index" validate={required()} />

              <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  source="unit_id"
                  reference="units"
                  label="Unit"
                  validate={required()}
                  perPage={50}
                  filterToQuery={(searchText) => ({
                    course_short_name: searchText,
                    _autocomplete: true,
                  })}
                  sort={{ field: "id", order: "ASC" }}
                  fullWidth
                  // disabled
                  >
                  <AutocompleteInput
                    matchSuggestion={(filterValue, suggestion) => true}
                    optionText={(choice) =>
                      choice && choice.id
                        ? `${choice.course_short_name} ${choice.name} (${choice.id})`
                        : "None"
                    }
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>

          </FormTab>

          <FormTab label="Cards">
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <Typography variant="body2" style={{ marginBottom: 16, marginTop: 16 }}>
                    <strong><u>Note</u>:</strong> The available cards are determined by the cards associated with the selected Unit / Episode.<br></br>To make more cards available, make sure to add them to the selected Unit first.
                  </Typography>
                  
                  <ReferenceArrayInput
                      label="Cards"
                      source="cards_ids"
                      reference="cards"
                      perPage={25}
                      filterToQuery={(searchText) => ({
                        name: searchText,
                        unit_id: formData.unit_id,
                        _autocomplete: true,
                      })}
                      sort={{ field: "id", order: "ASC" }}
                      fullWidth
                      allowEmpty
                  >
                      <AutocompleteArrayInput
                          matchSuggestion={(filterValue, suggestion) => true}
                          optionText={(choice) =>
                              choice && choice.id
                                  ? `${choice.name} (${choice.id})`
                                  : "None"
                          }
                      />
                  </ReferenceArrayInput>
                </>
              )}
            </FormDataConsumer>
          </FormTab>

        </TabbedForm>
			</Edit>
		</>
	);
}