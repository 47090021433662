import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  required,
  TextInput,
  SimpleForm,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const DeviceLimitEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
        
        <SelectInput
            validate={required()}
            source="application"
            choices={[
              { id: "kangi", name: "Kangi" },
              { id: "stream", name: "Stream" },
              { id: "flashcards", name: "Flashcards" },
            ]}
        />

        <TextInput source="user_type" label="User Type" />
        <TextInput source='value' label='Value' />
        <TextInput source="description" multiline />

      </SimpleForm>
    </Edit>
    </>
  );
}