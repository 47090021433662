import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { ColorField } from 'react-admin-color-input';
import { 
  Tab, 
  Show,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  ReferenceArrayField,
} from "react-admin";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '80px',
      height: '100px',
    }
  },
});

export const StreamCoursesShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source='hdc_id' label="HDC ID" />
            <TextField source='name' label="Name" />
            <TextField source="short_name" label="Short Name" />
            <TextField source='sort_index' label='Sort Index' />
            <BooleanField source='is_active' label='Is Active' />
            <TextField source='progression_points' label='Progression Points' fullWidth />
            <ColorField source='main_screen_card_color' label="Main Screen Card Color" />
            <ImageField source="thumbnail.url" label="Thumbnail" />
            <ImageField source="featured_banner_image.url" label="Featured Banner Image" />
            <ImageField source="roadmap_background.url" label="Roadmap Background" />
          </Tab>

          {/* TODO: Add units tab */}
        </TabbedShowLayout>
      </Show>
  </>
);
}