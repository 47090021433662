import React from "react";
import { CreateToolbar } from "../../../components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  SelectInput,
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const DeviceLimitCreate = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Create {...props} title="Create Device Limit">
      <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
        <SelectInput
            validate={required()}
            source="application"
            choices={[
              { id: "kangi", name: "Kangi" },
              { id: "stream", name: "Stream" },
              { id: "flashcards", name: "Flashcards" },
            ]}
        />
        <TextInput source="user_type" label="User Type" />
        <TextInput source='value' label='Value' />
        <TextInput source="description" multiline />
      </SimpleForm>
    </Create>
    </>
  );
}