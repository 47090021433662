import React from "react";
import { JSONField } from 'components/JSON';
import {
  Show,
  FunctionField,
  UrlField,
  SimpleShowLayout,
  TextField,
  BooleanField
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";
import { DateFieldShow } from "components/Fields";
import { ViewTitle } from "../../../components/AppBar/Titles";

export const SettingsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Show title={<ViewTitle source="name" />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="key" label="Key" />
        <TextField source="value_type" />
        <FunctionField 
          label="Value"
          render={(record) => {
            if (record.value_type === 'string') {
              return  <TextField source='value' />
            } else if (record.value_type === 'json') {
              return <JSONField source='value' />
            }
            return <UrlField source='value'/>
          }
        }/>
        <TextField source="description" label="Description" />
        <BooleanField
          source="is_client_accessible"
          label="Is Client Accessible"
        />
        <DateFieldShow label="Created At" source="created_at" />
        <DateFieldShow label="Updated At" source="updated_at" />
      </SimpleShowLayout>
    </Show>
    </>
  );
}