import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { DownloadableInput } from 'Downloadable/DownloadableComponents';
import { FileUploaderPlaceholder } from 'components/FileUploaderPlaceholder';
import { Create, SimpleForm, TextInput, required, SelectInput, ReferenceInput, FileInput } from "react-admin";

export const KDownloadCreate = (props) => (
  <Create {...props} title="Create KDownload">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={required()} />

      <SelectInput
        label="Type"
        source="type"
        choices={[
          { id: 'audio', name: "Audio" },
          { id: 'video', name: "Video" },
          { id: 'document', name: "Document" },
        ]}
      />

      <ReferenceInput
        source="parent_set_public_id"
        reference="sets"
        label="Parent Set"
        perPage={500}
      >
        <SelectInput optionText="full_name" />
      </ReferenceInput>
      
    </SimpleForm>
  </Create>
  );

