import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  FileInput,
  ImageField,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const CourseAssetsCreate = (props) => {
  const query = useQuery();
  const courseId = query.get('courseId');

  return (
    <Create {...props} title="Create Course Asset">
      <SimpleForm variant="standard"
                  margin="normal"
                  toolbar={<CreateToolbar />}
                  defaultValue={{ course_id: courseId ? parseInt(courseId) : undefined }}>

        <TextInput source="name" validate={required()} fullWidth />

        <FileInput
          source="thumbnail_file"
          accept="image/*"
          label="Image"
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source="src" />
        </FileInput>

        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="course_id"
              reference="courses"
              label="Course"
              validate={required()}
              perPage={25}
              filterToQuery={(searchText) => ({
                name: searchText,
                _autocomplete: true,
              })}
              fullWidth
              >
              <AutocompleteInput
                matchSuggestion={(filterValue, suggestion) => true}
                optionText={(choice) =>
                  choice && choice.id
                    ? `${choice.nick_name} (${choice.id})`
                    : "None"
                }
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        
      </SimpleForm>
    </Create>
  );
}

