import DeviceIcon from '@mui/icons-material/TabletAndroid';

import { DevicesList } from './list';
import { DevicesShow } from './show';
import { DevicesEdit } from './edit';

export default {
  name: "devices",
  options: { label: "Devices", menuParent: "system" },
  icon: DeviceIcon,
  list: DevicesList,
  show: DevicesShow,
  edit: DevicesEdit
};
