import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { AudioInput } from "components/Audio/AudioComponents";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { 
  Edit,
  SimpleForm,
  TextInput,
  required,
  FileInput,
  ImageField,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

export const CardsEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>
				<SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
          <TextInput source="name" validate={required()} />
          <TextInput source="display_name" validate={required()} />
          <TextInput source="definition" fullWidth />
          <TextInput source="example" fullWidth />
          <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />

          <ReferenceArrayInput
            label="Units"
            source="units_ids"
            reference="units"
            validate={required()}
            perPage={1000}
            filterToQuery={(searchText) => ({
              course_short_name: searchText, // Special field name, handled specifically by the admin's Unit handler
              _autocomplete: true,
            })}
            sort={{ field: "id", order: "ASC" }}
            fullWidth
            allowEmpty
          >
            <AutocompleteArrayInput
              matchSuggestion={(filterValue, suggestion) => true}
              optionText={(choice) =>
                choice && choice.id
                  ? `${choice.course_short_name} ${choice.name} (${choice.id})`
                  : "None"
              }
            />
          </ReferenceArrayInput>

          <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            validate={required()}
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FileInput
            source="audio_file"
            accept="audio/*"
            label="Audio"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <AudioInput source="src" />
          </FileInput>

				</SimpleForm>
			</Edit>
		</>
	);
}
