import { List, TextField} from "react-admin";
import ClientActionsFilter from "./filter";
import DataGridList from "components/containers/List";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";
import ListActions from "components/ListAction/ListActions";
import Breadcrumbs from "components/Breadcrumbs";

export const ClientActionsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <List
        title="ClientActions"
        filters={<ClientActionsFilter />}
        pagination={<Paginator />}
        perPage={15}
        sort={{ field: "id", order: "DESC" }}
        {...props}
        empty={<EmptyPage create />}
        actions={<ListActions listExporterType="ClientActions" />}
      >
        <DataGridList show edit>
          <TextField source="name" label="Name" />
        </DataGridList>
      </List>
    </>
  );
}