import SetsIcon from '@mui/icons-material/Widgets';

import { SetsList } from './list';
import { SetsEdit } from './edit';
import { SetsShow } from './show';

export default {
  name: "sets",
  options: { label: "Sets", menuParent: "kangi" },
  icon: SetsIcon,
  list: SetsList,
  show: SetsShow,
  // create: SetsCreate,
  edit: SetsEdit,
};
