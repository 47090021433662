import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { SelectInput, AutocompleteInput, ReferenceInput } from 'react-admin';

const SupportTicketsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>

    <ReferenceInput
        source="user_id"
        reference="users"
        label="User"
        filterToQuery={(searchText) => ({
          name: searchText,
            id: parseInt(searchText),
            _autocomplete: true,
          })}
    >
        <AutocompleteInput
          matchSuggestion={(filterValue, suggestion) => true}
          optionText={(choice) => {
            return `${choice?.id ? `${choice.username} (${choice.id})` : "None" }`
          }}
          />
    </ReferenceInput>

    <SelectInput
        source='is_resolved'
        label="Resolved"
        choices={[
          { id: true, name: "Yes" },
          { id: false, name: "No" },
        ]}
    />
    
    <DebouncedTextField source='app_name' label='App Name' />

  </FilterContainer>
);

export default SupportTicketsFilter;