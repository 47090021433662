import SupportTicketsIcon from '@mui/icons-material/SupportAgent';

import { SupportTicketsList } from './list';
import { SupportTicketsShow } from './show';
import { SupportTicketsEdit } from './edit';

export default {
  name: "support_tickets",
  options: { label: "Support Tickets", menuParent: "system" },
  icon: SupportTicketsIcon,
  list: SupportTicketsList,
  show: SupportTicketsShow,
  edit: SupportTicketsEdit
};
