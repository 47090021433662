import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { CreateToolbar } from "../../../components/Toolbar/CreateToolbar";

import Breadcrumbs from "components/Breadcrumbs";


export const ClientActionsCreate = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Create {...props} title="Create ClientActions">
        <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
          <TextInput source="name" validate={required()} fullWidth />
            <TextInput
              source="required_linkable_type"
              label="Required Linkable Type"
              fullWidth
            />

        </SimpleForm>
      </Create>
    </>
  );
}