import React from "react";
import { ColorInput } from "react-admin-color-input"
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  FileInput,
  SimpleForm,
  ImageField,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

export const CourseCreate = (props) => (
  <Create {...props} title="Create Course">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source='hdc_id' label='HDC Id' validate={required()} />
      <TextInput source='hdc_name' validate={required()} />
      <TextInput source="nick_name" validate={required()} />
      <TextInput source="short_name" validate={required()} />
      <TextInput source='sort_index' validate={required()} />
      <TextInput source='course_points' validate={required()} />
      <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />

      <SelectInput source="category_id" label="Category" choices={[
        { id: '1', name: 'Babies' },
        { id: '2', name: 'Children' },
        { id: '3', name: 'Tweens' },
        { id: '4', name: 'Teens' },
        { id: '5', name: 'Holiday' },
      ]} />

      <ColorInput source='color_a' label="Color A" picker='Swatches'/>
      <ColorInput source='color_b' label="Color B" picker='Swatches'/>
      <ColorInput source='color_c' label="Color C" picker='Swatches'/>
      <ColorInput source='navbar_text_color' label="Navbar Text Color" picker='Swatches'/>
      <ColorInput source='background_color' label="Background Color" picker='Swatches'/>

      <FileInput
        source="thumbnail_file"
        accept="image/*"
        label="Thumbnail"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>

      <FileInput
        source="navbar_thumbnail_file"
        accept="image/*"
        label="Navbar Thumbnail"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>
    </SimpleForm>
  </Create>
  );

