
   
import React, { useState } from "react";
import { CreateToolbar } from "../../../components/Toolbar/CreateToolbar";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  SelectInput,
  FileInput,
  FileField,
  FunctionField
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";
import { FileContainer } from "components/ImageContainer/FileContainer";
import { FileUploaderPlaceholder } from "components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder";

export const SettingsCreate = (props) => {
  const menuParent = props.options.menuParent;
  const [selectedValueType, setSelectedValueType] = useState();

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Create {...props} title="Create Setting">
      <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
        <TextInput source="key" validate={required()} fullWidth />
        <SelectInput
            validate={required()}
            source="value_type"
            onChange={(e) => {
              setSelectedValueType(e.target.value);
            }}
            choices={[
              { id: "string", name: "string" },
              { id: "file", name: "file" },
              { id: "image", name: "image" },
              { id: "video", name: "video" },
              { id: "json", name: "json"}
            ]}
        />
        <FunctionField source="value" validate={required()} render={(record) => {
          if (selectedValueType === 'string' || selectedValueType === 'json') {
            return <TextInput source='value' label='' multiline fullWidth/>
          }

          return <FileInput
            source="value_file"
            accept="application/pdf,image/*"
            label="File"
            placeholder={<FileUploaderPlaceholder type="file" />}
            fullWidth
          >
            <FunctionField
              render={(record) => {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FileField source="src" />
                    {/* NOTICE: when uploading new file on a nested structure like the following, the src blob will be as a undefined key*/}
                    <FileContainer
                      url={record.src ? record.src : record.undefined}
                      type={
                        record?.rawFile
                          ? record.rawFile.type.includes("pdf")
                            ? "pdf"
                            : "image"
                          : "image"
                      }
                    />
                  </div>
                );
              }}
            />
          </FileInput>
        }}
        />
        <TextInput source="description" fullWidth />
        <BooleanInput source="is_client_accessible" label="Is Client Accessible" fullWidth />
      </SimpleForm>
    </Create>
    </>
  );
}