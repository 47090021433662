import React from "react";
import { ColorInput } from "react-admin-color-input"
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  FileInput,
  SimpleForm,
  ImageField,
  BooleanInput,
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

export const StreamCourseCreate = (props) => (
  <Create {...props} title="Create Course">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source='hdc_id' label='HDC Id' validate={required()} />
      <TextInput source='name' validate={required()} />
      <TextInput source="short_name" validate={required()} />
      <TextInput source='sort_index' validate={required()} />
      <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />
      <TextInput source='progression_points' validate={required()} />
      <ColorInput source='main_screen_card_color' label="Main Screen Card Color" picker='Swatches'/>

      <FileInput
        source="thumbnail_file"
        accept="image/*"
        label="Thumbnail"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>

      <FileInput
        source="featured_banner_image_file"
        accept="image/*"
        label="Featured Banner Image"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>

      <FileInput
        source="roadmap_background_file"
        accept="image/*"
        label="Roadmap Background" 
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>
    </SimpleForm>
  </Create>
  );

