import config from '../config';

// BULK_CREATE = Bulk create resources from a ZIP file
// HANDLE_ASSOCIATIONS = Handle associations between resources using an XLSX file which represents the mapping
export const BulkCreateEndpoint = Object.freeze({
  BULK_CREATE: `${config.API_URL}/resources/bulk_create/process/zip`,
  HANDLE_ASSOCIATIONS: `${config.API_URL}/resources/bulk_create/process/xlsx`,
})

export const bulkProcess = async (file, uploadId, endpoint) => {
  // Checksum is matched with the calculaetd checksum on the server in order to verify integrity
  const calculateFileChecksum = async (file) => {
    const buffer = await file.arrayBuffer();
    const digest = await crypto.subtle.digest('SHA-256', buffer);
    return Array.from(new Uint8Array(digest)).map(b => b.toString(16).padStart(2, '0')).join('');
  }

  if (!endpoint) {
    throw new Error('Endpoint is missing or incorrect');
  }

  const accessToken = localStorage.getItem('token');
  
  const chunkSize = 5 * 1024 * 1024; // 5MB chunk size
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileChecksum = await calculateFileChecksum(file);

  for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
      const chunkStart = chunkNumber * chunkSize;
      const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
      const fileChunk = file.slice(chunkStart, chunkEnd);

      const formData = new FormData();
      formData.append('file', fileChunk, file.name);
      formData.append('chunk_number', chunkNumber + 1);
      formData.append('total_chunks', totalChunks);
      formData.append('upload_id', uploadId);
      formData.append('file_checksum', fileChecksum);

      try {
          const response = await fetch(endpoint, {
              method: 'POST',
              body: formData,
              headers: { 'X-Auth-Token': accessToken }
          });
          if (!response.ok) {
              throw new Error('Upload failed for chunk ' + (chunkNumber + 1));
          }
          console.log('Chunk ' + (chunkNumber + 1) + ' uploaded successfully');
      } catch (error) {
          console.error('Error uploading chunk ' + (chunkNumber + 1), error);
          throw error;
      }
  }
}