import React, { useState } from 'react';

import config from '../../config';

import moment from 'moment';
import { toast } from 'react-toastify';
import { useRefresh } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
    Box,
    Button,
    Dialog,
    Accordion,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    AccordionSummary,
    AccordionDetails,
    DialogContentText,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import RestoreIcon from '@material-ui/icons/Restore';



const useStyles = makeStyles(theme => ({
    backupListContainer: {
        marginBottom: theme.spacing(2),
    },
    timestampContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '16px 0',
        borderBottom: `1px solid ${theme.palette.divider}`, // This adds the horizontal line
        '&:last-child': {
            borderBottom: 'none', // This removes the border from the last element
        },
    },
    accordionSummary: {
        marginLeft: theme.spacing(3),
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    accordionDetails: {
        marginLeft: theme.spacing(6),
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(15),
        borderColor: theme.palette.divider,
    },
    rollbackButton: {
        backgroundColor: 'lightcyan',
        color: 'white',
        paddingTop: '4px',
        paddingBottom: '4px',
        '&:hover': {
            backgroundColor: 'PowderBlue',
        },
    },
}));

// In case we wish to implement "Backup" confirmation dialog
const CustomConfirmDialog = ({ isOpen, title, content, onCancel, onDecline, onConfirm }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CancelIcon />} onClick={onCancel}>
                    Cancel Rollback
                </Button>
                <Button startIcon={<CloseIcon />} onClick={onDecline}>
                    Decline
                </Button>
                <Button startIcon={<CheckIcon />} onClick={onConfirm}>
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const BackupList = ({ expanded, title, timestamps, app, ...rest }) => {
    const classes = useStyles();
    return (
        <div className={classes.backupListContainer}>
            <BackupItem expanded={expanded} title={title} app={app} timestamps={timestamps} {...rest} />
        </div>
    );
};

export const BackupItem = ({ expanded, title, timestamps, app, ...rest }) => {
    const classes = useStyles();
    const refresh = useRefresh();

    const [isExpanded, setIsExpanded] = useState(expanded);
    const [openConfirm, setOpenConfirm] = useState(false); // State to manage confirm dialog visibility
    const [selectedTimestamp, setSelectedTimestamp] = useState(null); // State to hold the selected timestamp for rollback

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    const handleRollbackButtonClick = (timestamp) => {
        setSelectedTimestamp(timestamp); // Set the selected timestamp
        setOpenConfirm(true); // Show the confirm dialog
    };

    const handleCancelRollback = () => {
        setOpenConfirm(false); // Close the confirmation dialog
    };

    const handleDeclineBackup = async () => {
        await handleRollback(false);
    };

    const handleConfirmBackup = async () => {
        await handleRollback(true);
    }

    const handleRollback = async (performBackup) => {
        if (!selectedTimestamp) return;

        const token = localStorage.getItem("token");
        const resource = rest.basePath.split("/").pop();
        const SET_ROLLBACK_ENDPOINT = `${config.API_URL}/resources/${resource}/rollback/${app}/${selectedTimestamp}`;

        try {
            const putRequest = new Request(SET_ROLLBACK_ENDPOINT, {
                method: "PUT",
                body: JSON.stringify({
                    resource_id: rest?.id,
                    perform_backup: performBackup
                }),
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Auth-Token": token,
                }),
            });

            const rollbackResponse = await fetch(putRequest);

            if (!rollbackResponse.ok) {
                throw new Error(`Error: ${rollbackResponse.status}`);
            }

            toast.success(`Successfully rolled back to ${moment(selectedTimestamp, 'YYYYMMDD_HHmmss').format('DD/MM/YY [at] HH:mm:ss')}`, {
                position: toast.POSITION.BOTTOM_RIGHT, 
                autoClose: 15000
            });

            refresh(); // Refetches the data after rollback

        } catch (error) {
            console.error("Rollback error:", error);
            toast.error("Rollback error, please relogin.", {
                position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000
            });
        } finally {
            setOpenConfirm(false);
        }
    };


    return (
        <div>
            <Accordion expanded={isExpanded} onChange={toggleAccordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                    <Box display="flex" alignItems="center">
                        <CloudDownloadIcon style={{ marginRight: 8 }} /> {/* Style can be moved to useStyles */}
                        <Typography>{title}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {timestamps.map((timestamp, index, array) => (
                            <div
                                key={index}
                                className={classes.timestampContainer}
                                style={index === array.length - 1 ? { borderBottom: 'none' } : {}}
                            >
                                <span>{moment(timestamp, 'YYYYMMDD_HHmmss').format('MMMM Do YYYY, HH:mm:ss')}</span>
                                <Button
                                    variant="contained"
                                    className={classes.rollbackButton}
                                    onClick={() => handleRollbackButtonClick(timestamp)}
                                    startIcon={<RestoreIcon />}
                                >
                                    Rollback To Here
                                </Button>
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            <CustomConfirmDialog
                isOpen={openConfirm}
                title="Backup"
                content="Would you like to create a backup of the current configuration?"
                onCancel={handleCancelRollback}
                onDecline={handleDeclineBackup}
                onConfirm={handleConfirmBackup}
            />
        </div>
    );
};