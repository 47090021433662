import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Tab, 
  Show,
  Datagrid,
  TextField,
  ImageField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  ReferenceArrayField,
} from "react-admin";

export const CategoryShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source='name' label="Name" />
            <TextField source="sort_index" label="Sort Index" />
            <ImageField source="thumbnail.url" label="Thumbnail" />
          </Tab>

          <Tab label="Courses">
            <ReferenceArrayField source="courses_ids" reference="courses" fullWidth>
              <Datagrid>

                <ReferenceField 
                  source="id"
                  reference="courses"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="nick_name" label="Name" />
                </ReferenceField>

                <BooleanField source="is_active" label="Active" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}