import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, ReferenceField, ImageField, FunctionField } from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '150px',
      height: '150px',
    }
  },
});

export const KAppsShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source='name' label='Name' />
          <TextField source='type' label='Type' />
          
          <FunctionField
          label="Access"
          render={record => `${record.status === '2' ? 'Public' : 'Restricted - Enrolled users only'}`}
          />

          <TextField source='comment' label='Comment' />

          <ReferenceField
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            link={(record, reference) => `/${reference}/${record.parent_set_public_id}/show`}
          >
            <TextField source="full_name" label="Parent Set ID" />
          </ReferenceField>

          <ImageField source="bg_image_url.url" label="BG Thumbnail" className={classes.customImage} sortable={false} />
          <ImageField source="bg_wide_image_url.url" label="Wide BG Thumbnail" className={classes.customImage} sortable={false} />


        </SimpleShowLayout>
      </Show>
    </>
  );
}