import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { SelectInput, AutocompleteInput, ReferenceInput } from 'react-admin';

const AdminLogsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>

    <ReferenceInput
        source="admin_user_id"
        reference="admin_users"
        label="Full Name"
        filterToQuery={(searchText) => ({
          name: searchText,
            id: parseInt(searchText),
            _autocomplete: true,
          })}
    >
        <AutocompleteInput
          matchSuggestion={(filterValue, suggestion) => true}
          optionText={(choice) => {
            return `${choice?.id ? `${choice.full_name} (${choice.id})` : "None" }`
          }}
          />
    </ReferenceInput>

    <SelectInput
        label="Action"
        source="action"
        choices={[
          { id: 'create', name: "Create" },
          { id: 'update', name: "Update" },
          { id: 'delete', name: "Delete" },
        ]}
      />

      <DebouncedTextField label='Resource' source='resource' />

  </FilterContainer>
);

export default AdminLogsFilter;