import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { DownloadableField } from 'Downloadable/DownloadableComponents';
import { Show, SimpleShowLayout, TextField, ReferenceField } from "react-admin";

export const KDownloadsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source='name' label='Name' />
          <TextField source='type' label='Type' />

          <ReferenceField
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            link={(record, reference) => `/${reference}/${record.parent_set_public_id}/show`}
          >
            <TextField source="full_name" label="Parent Set ID" />
          </ReferenceField>

          <DownloadableField source='download_url.url' label='FILE' />
          
        </SimpleShowLayout>
      </Show>
    </>
  );
}