import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  required,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const DevicesEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
        <SelectInput
              label="Permission"
              source="permission"
              validate={required()}
              fullWidth
              choices={[
                { id: "maintainer", name: "Maintainer" },
                { id: "guest", name: "Guest" },
              ]}
        />
      </SimpleForm>
    </Edit>
    </>
  );
}