import XLSX from "xlsx";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import jsonExport from "jsonexport/dist";


import {
  CreateButton,
  downloadCSV,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from "react-admin";
import pluralize from "pluralize";
import React, { cloneElement, useState } from "react";


import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import CSVIcon from "@material-ui/icons/Subject";
import MenuItem from "@material-ui/core/MenuItem";
import ExcelIcon from "@material-ui/icons/GridOn";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import ExportButtonIcon from "@material-ui/icons/GetApp";

import { HelperListExporter } from '../../ListExporters/helper';
import { checkAndModifyExcelFileTypeData } from "./ListActionsAdditionalFunctions";

const ListActions = (props) => {
  const {
    className,
    exporter,
    listExporterType,
    filters,
    maxResults,
    filterByType,
    hideCreateButton,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
  const [excelExpandAnchorEl, setExcelExpandAnchorEl] = useState(null);
  let dynamicExportFileType;
  let dynamicExcelType;
  let finalExporter;
  let finalExporterData;

  const handleExcelExpandClick = (event) => {
    setExcelExpandAnchorEl(event.currentTarget);
  };
  const pdfHtmlResouceCustomize = (resource) => {
    return resource.replaceAll("/", "_");
  };
  const handleExcelExpandClose = (excelFileType) => {
    switch (excelFileType) {
      case "BASIC":
        dynamicExcelType = "BASIC";
        break;

      case "EXPANDED":
        dynamicExcelType = "EXPANDED";
        break;

      default:
        break;
    }
    handleClose("EXCEL");
    setExcelExpandAnchorEl(null);
    setExportButtonAnchorEl(null);
  };

  const handleClick = (event) => {
    setExportButtonAnchorEl(event.currentTarget);
  };

  const handleClose = (exportFileType) => {
    setExportButtonAnchorEl(null);

    switch (exportFileType) {
      case "EXCEL":
        dynamicExportFileType = "EXCEL";
        break;

      case "CSV":
        dynamicExportFileType = "CSV";
        break;

      case "PDF":
        dynamicExportFileType = "PDF";
        break;

      default:
        break;
    }
  };

  if (listExporterType) {
    let finalExcelData;
    let binaryWS;
    let webBook;

    finalExporter = async (records, fetchRelatedRecords) => {
      switch (listExporterType) {
        default:
          finalExporterData = await HelperListExporter(
            records,
            fetchRelatedRecords,
            pluralize(listExporterType)
          );
      }

      switch (dynamicExportFileType) {
        case "EXCEL":
          if (resource === "revenue_sharing") {
            finalExcelData = checkAndModifyExcelFileTypeData(
              dynamicExcelType,
              finalExporterData.data
              );
              
              switch (finalExcelData.type) {
                case "BASIC":
                  binaryWS = XLSX.utils.json_to_sheet(finalExcelData.data);
                  webBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(
                    webBook,
                    binaryWS,
                    finalExporterData.fileName
                    );
                    var stream = XLSX.stream.to_json(webBook, { raw: true });
                    XLSX.writeFile(webBook, `${finalExporterData.fileName}.xlsx`);
                    break;
                    
                    
                case "EXPANDED":
                  webBook = XLSX.utils.book_new();
                  
                  // creating a multi sheet webBook file:
                webBook = {
                  SheetNames: finalExcelData.names,
                  Sheets: finalExcelData.data,
                };
                
                XLSX.utils.book_append_sheet(
                  webBook,
                  binaryWS,
                  finalExporterData.fileName
                  );
                  
                  XLSX.writeFile(webBook, `${finalExporterData.fileName}.xlsx`);
                  break;
                  
                  default:
                    break;
                  }
                } else {
                  // XLSX does not display the data in Excel if the value is of array type, it is required to convert the array to a string
                  finalExporterData.data = finalExporterData.data.map((record) => {
                    for (const [key, value] of Object.entries(record)) {
                      if (Array.isArray(value)) {
                        record[key] = value.toString();
                      }
                    }
                    return record;
                  });
                  binaryWS = XLSX.utils.json_to_sheet(finalExporterData.data);
                  webBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(
                    webBook,
                    binaryWS,
              finalExporterData.fileName
            );
            XLSX.writeFile(webBook, `${finalExporterData.fileName}.xlsx`);
          }

          break;

        case "CSV":
          jsonExport(
            finalExporterData.data,
            {
              headers: finalExporterData.headers,
            },
            (err, csv) => {
              downloadCSV(csv, finalExporterData.fileName);
            }
          );
          break;

        case "PDF":
          var doc = new jsPDF("l", "pt");
          doc.autoTable({
            theme: "grid",
            html: `#${pdfHtmlResouceCustomize(resource)}-table`,
            styles: {
              minCellWidth: 40,
            },
            willDrawCell: (data) => {
              if (data.cell.text[0] && data.cell.text[0].includes("₪")) {
                data.cell.text[0] = data.cell.text[0].replace("₪", " ILS ");
              }
              return !(
                data.cell.text[0] === "Edit" ||
                data.cell.text[0] === "Transaction" ||
                data.cell.text[0] === "CLONE" ||
                data.cell.text[0] === "VIEW" ||
                (data.cell.section === "head" && data.cell.text[0] === "") ||
                (data.cell.section === "body" &&
                  data.cell.text[0] === "" &&
                  data.column.raw.dataKey === 0) ||
                (data.cell.section === "body" &&
                  data.cell.text[0] === "" &&
                  data.column.raw.dataKey === 1)
              );
            },
          });
          doc.save(`${finalExporterData.fileName}.pdf`);
          break;
        default:
          break;
      }
    };

    dynamicExportFileType = "";
  } else {
    finalExporter = exporter;
  }

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {hideCreateButton ? "" : <CreateButton basePath={basePath} />}

      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          size="small"
          onClick={handleClick}
          startIcon={<ExportButtonIcon />} // eslint-disable-line
        >
          EXPORT
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={exportButtonAnchorEl}
          keepMounted
          open={Boolean(exportButtonAnchorEl)}
          onClose={handleClose}
        >
          {resource === "revenue_sharing" ? (
            <MenuItem>
              <Button
                aria-controls="revShareSubMenu"
                aria-haspopup="true"
                size="small"
                onClick={handleExcelExpandClick}
                startIcon={<ExcelIcon />} // eslint-disable-line
                style={{ backgroundColor: "transparent" }}
              >
                EXCEL
              </Button>
              <Menu
                id="revShareSubMenu"
                anchorEl={excelExpandAnchorEl}
                keepMounted
                open={Boolean(excelExpandAnchorEl)}
                onClose={handleExcelExpandClose}
              >
                <MenuItem onClick={() => handleExcelExpandClose("BASIC")}>
                  <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={total}
                    exporter={finalExporter}
                    label="Basic"
                    icon={<ExcelIcon />}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={() => handleExcelExpandClose("EXPANDED")}>
                  <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={total}
                    exporter={finalExporter}
                    label="Expanded"
                    icon={<ExcelIcon />}
                    style={{ backgroundColor: "transparent" }}
                  />
                </MenuItem>
              </Menu>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => handleClose("EXCEL")}
              aria-controls="simple-menu"
              aria-haspopup="true"
              size="small"
              startIcon={<ExportButtonIcon />} // eslint-disable-line
            >
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                // TODO: release the 100 limit
                maxResults={resource === "subscriptions" ? 100 : total}
                exporter={finalExporter}
                label="EXCEL"
                icon={<ExcelIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>
          )}
          <MenuItem onClick={() => handleClose("CSV")}>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filterValues={filterValues}
              // TODO: release the 100 limit
              maxResults={resource === "subscriptions" ? 100 : total}
              exporter={finalExporter}
              label="CSV"
              icon={<CSVIcon />}
              style={{ backgroundColor: "transparent" }}
            />
          </MenuItem>
          <MenuItem onClick={() => handleClose("PDF")}>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filterValues={filterValues}
              // TODO: release the 100 limit
              maxResults={resource === "subscriptions" ? 100 : total}
              exporter={finalExporter}
              label="PDF"
              icon={<PDFIcon />}
              style={{ backgroundColor: "transparent" }}
            />
          </MenuItem>
        </Menu>
      </>
    </TopToolbar>
  );
};

export default ListActions;
