import CardsIcon from '@mui/icons-material/Style';

import { CardsBulkCreate } from './create';

export default {
  name: "bulk_create_flash_cards",
  options: { label: "Cards", menuParent: "bulk_create" },
  icon: CardsIcon,
  list: CardsBulkCreate, // head directly to the create page
};
