import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
  FormDataConsumer,
  required,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput
} from "react-admin";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { Typography } from "@mui/material";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const LessonCreate = (props) => {
  const query = useQuery();
  const unitId = query.get('unitId');

  return (
    <Create {...props} title="Create Lesson">
      <TabbedForm variant="standard" margin="normal" toolbar={<CreateToolbar hidedelete />}
      defaultValue={{ unit_id: unitId ? parseInt(unitId) : undefined }}>
        <FormTab label='Details'>
            <TextInput source="name" validate={required()} fullWidth />
            <BooleanInput source='is_active' label='Is Active' validate={required()} defaultValue={true} />

            <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="unit_id"
                reference="units"
                label="Unit"
                validate={required()}
                perPage={50}
                filterToQuery={(searchText) => ({
                  course_short_name: searchText,
                  _autocomplete: true,
                })}
                sort={{ field: "id", order: "ASC" }}
                fullWidth
                >
                <AutocompleteInput
                  matchSuggestion={(filterValue, suggestion) => true}
                  optionText={(choice) =>
                    choice && choice.id
                      ? `${choice.course_short_name} ${choice.name} (${choice.id})`
                      : "None"
                  }
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>

        </FormTab>

        <FormTab label="Cards">
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <Typography variant="body2" style={{ marginBottom: 16, marginTop: 16 }}>
                  <strong><u>Note</u>:</strong> The available cards are determined by the cards associated with the selected Unit / Episode.<br></br>To make more cards available, make sure to add them to the selected Unit first.
                </Typography>
                
                <ReferenceArrayInput
                    label="Cards"
                    source="cards_ids"
                    reference="cards"
                    perPage={25}
                    filterToQuery={(searchText) => ({
                      name: searchText,
                      unit_id: formData.unit_id,
                      _autocomplete: true,
                    })}
                    sort={{ field: "id", order: "ASC" }}
                    fullWidth
                    allowEmpty
                    disabled={!formData.unit_id}
                >
                    <AutocompleteArrayInput
                        matchSuggestion={(filterValue, suggestion) => true}
                        optionText={(choice) =>
                            choice && choice.id
                                ? `${choice.name} (${choice.id})`
                                : "None"
                        }
                    />
                </ReferenceArrayInput>
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        
      </TabbedForm>
    </Create>
  );
}

