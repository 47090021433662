import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { DownloadableInput } from 'Downloadable/DownloadableComponents';
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Edit, SimpleForm, TextInput, required, ReferenceInput, SelectInput, FileInput, FileField } from "react-admin";


export const KDownloadsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Edit title={<EditTitle source="name" />} {...props}>
        <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>

          <TextInput source="name" validate={required()} fullWidth />

          <SelectInput
            label="Type"
            source="type"
            choices={[
              { id: 'audio', name: "Audio" },
              { id: 'video', name: "Video" },
              { id: 'document', name: "Document" },
            ]}
          />

          <ReferenceInput
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            perPage={500}
          >
            <SelectInput optionText="full_name" />
          </ReferenceInput>

          <FileInput
            source="download_url_file"
            label="ZIP File (ZIP ONLY)"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <DownloadableInput source='src' />
          </FileInput>

        </SimpleForm>
      </Edit>
    </>
  );
}
