import { Resource } from 'react-admin';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import avatarViews from './Avatar/index';
import courseViews from './Course/index';

const streamRoutes = [
  <Resource
    name='stream'
    icon={MusicNoteIcon}
    options={{ label: 'Stream 2.0', isMenuParent: true }}
  />,
  <Resource {...avatarViews} />,
  <Resource {...courseViews} />,
];
export default streamRoutes;
