import React from "react";
import { SelectInput } from "react-admin";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const NotificationGroupsFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <DebouncedTextField label="Name" source="name" />
    <SelectInput
      source="is_visible"
      choices={[
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ]}
    />
  </FilterContainer>
);

export default NotificationGroupsFilter;
