import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import { MinimumVersionList } from './list';
import { MinimumVersionShow } from './show';
import { MinimumVersionEdit } from './edit';
import { MinimumVersionCreate } from './create';

export default {
  name: "minimum_versions",
  options: { label: "Minimum Versions", menuParent: "system" },
  icon: SystemUpdateAltIcon,
  list: MinimumVersionList,
  show: MinimumVersionShow,
  edit: MinimumVersionEdit,
  create: MinimumVersionCreate,
};
