import { List, TextField, ReferenceField } from "react-admin";
import NotificationTypesFilter from "./filter";
import DataGridList from "components/containers/List";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";
import ListActions from "components/ListAction/ListActions";
import Breadcrumbs from "components/Breadcrumbs";

export const NotificationTypesList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <List
        title="NotificationTypes"
        filters={<NotificationTypesFilter />}
        pagination={<Paginator />}
        perPage={15}
        sort={{ field: "id", order: "DESC" }}
        {...props}
        empty={<EmptyPage create />}
        actions={<ListActions listExporterType="NotificationTypes" hideCreateButton={true}/>}
      >
        <DataGridList show edit>
          <TextField source="name" />
          <ReferenceField
            source="client_action_id"
            reference="client_actions"
            label="Client Action"
            link={(record, reference) => 
               `/${reference}/${record.client_action_id}/show`
            }
          >
            <TextField source="name" />
          </ReferenceField>
        </DataGridList>
      </List>
    </>
  );
}
