import {
  SaveButton,
  useCreate,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import { set } from "lodash";
import { useCallback, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { generateRandomString } from 'utils/files/main';
import CircularProgress from "@material-ui/core/CircularProgress";

import config from "../../config";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    marginTop: "-3px",
  },
}));

export const UploadAndSave = (props) => {
  const classes = useStyles();
  const { basePath } = props;
  const resource = basePath.split("/").pop();
  const [create] = useCreate(resource);
  const [update] = useUpdate(resource);
  const redirectTo = useRedirect();
  const notify = useNotify();
  const token = localStorage.getItem("token");
  const [disableButton, setDisableButton] = useState(false);

  const uploadFile = async (uploadable, file,overrideResource) => {
    if (file) {
      const { rawFile } = file;

      if (rawFile) {
        const extension = rawFile.name.split(".").pop();
        const fieldName = uploadable;

        const presignedUrlRequest = new Request(
          `${config.API_URL}/resources/${overrideResource ?? resource}/presigned_urls`,
          {
            method: "POST",
            body: JSON.stringify({
              filename: `${uploadable}_${generateRandomString()}.${extension}`,
              field_name: fieldName,
              resource_id: props?.record?.id
            }),
            headers: new Headers({
              "Content-Type": "application/json",
              "X-Auth-Token": token,
            }),
          }
        );
        const presignedUrlResponse = await fetch(presignedUrlRequest);
        const presignedUrlResponseJson = await presignedUrlResponse.json();
        const { key, url } = presignedUrlResponseJson.response;

        const s3UploadRequest = new Request(url, {
          method: "PUT",
          body: rawFile,
        });

        const s3UploadResponse = await fetch(s3UploadRequest); // eslint-disable-line

        return key;
      } else {
        return file.key || file.name;
      }
    }

    return "";
  };

  const handleSave = useCallback(
    async (values, redirect) => {
      setDisableButton(true);
      const keys = new Object(); // eslint-disable-line

      // the following validation is in case of edit / create pages.
      if (
        values.id ||
        !values.thumbnail ||
        (values.thumbnail_file && values.thumbnail_file.rawFile)
      ) {
        for (let [attribute, value] of Object.entries(values)) {
          if (attribute.includes("_file")) {
            const originalAttributeName = attribute.split("_file").shift();

            if (Array.isArray(value)) {
              keys[originalAttributeName] = [];
              const files = value;
              for (const file of files) {
                keys[originalAttributeName] = await uploadFile(
                  originalAttributeName,
                  file,
                  resource,
                );
              }
            } else {
              const file = value;

              keys[originalAttributeName] = await uploadFile(
                originalAttributeName,
                file,
                resource,
              );
            }

            keys[attribute] = null;
          }else{
            if(Array.isArray(value)&& value.every(obj => typeof obj === 'object' && obj !== null)){
              const parentAttribute=attribute;
              for(let [index,object] of value.entries()){
                for(let [attribute, value] of Object.entries(object)){
                  if (attribute.includes("_file")) {
                    const originalAttributeName = attribute.split("_file").shift();
        
                    if (Array.isArray(value)) {
                      keys[originalAttributeName] = [];
                      const files = value;
                      for (const file of files) {
                        const upload_file = await uploadFile(
                          originalAttributeName,
                          file,
                          parentAttribute,
                        );
                        set(values,`${parentAttribute}[${index}].${originalAttributeName}`,upload_file);
                        // setIn(values,`${parentAttribute}[${index}].${originalAttributeName}`,upload_file);
                        set(values,`${parentAttribute}[${index}].${attribute}`,null);
                      }
                    } else {
                      const file = value;
                      const upload_file = await uploadFile(
                        originalAttributeName,
                        file,
                        parentAttribute,
                      );
                      set(values,`${parentAttribute}[${index}].${originalAttributeName}`,upload_file);
                      // setIn(values,`${parentAttribute}[${index}].${originalAttributeName}`,upload_file);
                      set(values,`${parentAttribute}[${index}].${attribute}`,null);

                      // keys[originalAttributeName] = await uploadFile(
                      //   originalAttributeName,
                      //   file
                      // );
                    }
        
                    keys[attribute] = null;
                }}
              }
            }
          }
        }

        // in case of cloning:
      } else if (values.thumbnail) {
        // if the thumbnail_file is null, it means that the user cloned a listing and removed it's image:
        if (!values.thumbnail_file) {
          values.thumbnail = null;
        } else {
          values.thumbnail = values.thumbnail.key;
        }
      }
      
      const id = values.id;
      let finalData = { ...values, ...keys };

      // in case the user is adding an email attachment for an event product,
      // here we're adding the file type to the file key than we send to the backend
      // e.g: email_attachment: 12sads-asd23_sadd-asddsad-dsa.pdf
      if (
        keys &&
        keys.email_attachment &&
        values.email_attachment_file.rawFile
      ) {
        let fileType =
          "." + values.email_attachment_file.rawFile.type.split("/").pop();
        keys.email_attachment = keys.email_attachment.concat(fileType);
        finalData.email_attachment = keys.email_attachment;
      }
      
      if (id) {
        update(
          {
            payload: { id, data: finalData },
          },
          {
            onSuccess: ({ data: record }) => {
              setDisableButton(false);
              notify("ra.notification.updated", "info", {
                smart_count: 1,
              });
              redirectTo(redirect, basePath, record.id, record);

            },
            onFailure: (error) => {
              setDisableButton(false);
              notify(error.message, "warning", {
                smart_count: 1,
              });
            },
          }
        );
      } else {
        create(
          {
            payload: { data: { ...values,  ...keys } },
          },
          {
            onSuccess: ({ data: newRecord }) => {
              setDisableButton(false);
              notify("ra.notification.created", "info", {
                smart_count: 1,
              });
              redirectTo(redirect, basePath, newRecord.id, newRecord);
            },
            onFailure: (error) => {
              setDisableButton(false);
              notify(error.message, "warning", {
                smart_count: 1,
              });
            },
          }
        );
      }
    },

    [create, update, notify, redirectTo, basePath] // eslint-disable-line
  );

  return (
    <SaveButton
      {...props}
      onSave={handleSave}
      disabled={disableButton}
      icon={
        <>
          {disableButton ? (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          ) : (
            <SaveIcon className={classes.icon} fontSize="small" />
          )}
        </>
      }
    />
  );
};
