import CoursesIcon from '@mui/icons-material/School';

import { CoursesEdit } from './edit';
import { CoursesList } from './list'
import { CoursesShow } from './show';
import { CourseCreate } from './create';

export default {
  name: "courses",
  options: { label: "Courses", menuParent: "flash_cards" },
  icon: CoursesIcon,
  list: CoursesList,
  show: CoursesShow,
  create: CourseCreate,
  edit: CoursesEdit,
};
