const clone = (obj) => Object.assign({}, obj);

export const renameKey = (object, key, newKey) => {
	const clonedObj = clone(object);
	const targetKey = clonedObj[key];
	delete clonedObj[key];
	clonedObj[newKey] = targetKey;

	return clonedObj;
};

export const modifyProductName = (product) => {
	if (product.product_type === "Event" && product.event_date) {
		let modifiedTime = product.event_date.slice(11, 16);
		let modifiedDateString = product.event_date.slice(0, 10);
		let modifiedYear = product.event_date.slice(0, 4);
		let modifiedMonth = product.event_date.slice(5, 7);
		let modifiedDay = product.event_date.slice(8, 10);
		modifiedDateString = `${modifiedMonth}/${modifiedDay}/${modifiedYear}`;

		return `${product.payment_scheme} ${product.product_type} (${product.id}) ${modifiedDateString} ${modifiedTime}`;
	} else {
		return `${product.payment_scheme} ${product.product_type} (${product.id})`;
	}
};
