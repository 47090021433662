import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { ColorField } from 'react-admin-color-input';
import { 
  Tab, 
  Show,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  ReferenceArrayField,
} from "react-admin";

import { Button } from '@material-ui/core';
import { useRedirect } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '80px',
      height: '100px',
    }
  },
});

const CreateAssetButton = (props) => {
  const redirect = useRedirect();

  const createAsset = () => {
    redirect(`/course_assets/create?courseId=${props.record.id}`);
  };

  return (
    <Button
      onClick={createAsset}
      color="primary"
      startIcon={<AddIcon />}
    >
      Add New Asset
    </Button>
  );
};

export const CoursesShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source='hdc_id' label="HDC ID" />
            <TextField source='hdc_name' label="HDC Name" />
            <TextField source="nick_name" label="Nick Name" />
            <TextField source="short_name" label="Short Name" />
            <TextField source="category_id" label="Category ID" />
            <TextField source='sort_index' label='Sort Index' />
            <TextField source='course_points' label='Course Points' fullWidth />
            <ColorField source='color_a' label="Color A" />
            <ColorField source='color_b' label="Color B" />
            <ColorField source='color_c' label="Color C" />
            <ColorField source='navbar_text_color' label="Navbar Text Color" />
            <ColorField source='background_color' label="Background Color" />
            <BooleanField source='is_active' label='Is Active' />
            <ImageField source="thumbnail.url" label="Thumbnail" />
            <ImageField source="navbar_thumbnail.url" label="Navbar Thumbnail" />
          </Tab>

          <Tab label="Units">
            <ReferenceArrayField source="units_ids" reference="units" fullWidth>
              <Datagrid>
                <TextField source="id" label="ID" />

                <ReferenceField 
                  source="id"
                  reference="units"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <TextField source="sessions_count" label="Sessions count" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>
          
          <Tab label="Assets">
            <CreateAssetButton props={props} />
            <ReferenceArrayField source="assets_ids" reference="course_assets" fullWidth>
              <Datagrid>
                <ReferenceField 
                  source="id"
                  reference="course_assets"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>

                <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} />
                <EditButton />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}