import React from "react";
import { SelectInput } from 'react-admin';
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const UserFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal" disableDates>
    <DebouncedTextField label="Username" source="username" />
    <DebouncedTextField label="Type" source="type" />
    <DebouncedTextField label="Status" source="status" />
    
  </FilterContainer>
);

export default UserFilter;
