import Breadcrumbs from "components/Breadcrumbs";
import DataGridList from "components/containers/List";
import ListActions from "components/ListAction/ListActions";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";
import React from "react";
import {
   FunctionField, UrlField, List, TextField
} from "react-admin";
import SettingsFilter from './filter';



export const SettingsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title="Settings"
      filters={<SettingsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: "id", order: "DESC" }}
      {...props}
      empty={<EmptyPage create/>}
      actions={<ListActions listExporterType="Settings" />}
    >
      <DataGridList show edit>

        <TextField source="key" label="Key" />
        <FunctionField 
          label="Value"
          render={(record) => {
            if (record.value_type === 'string' || record.value_type === 'json') {
              return  <TextField source='value'/>
            }
            return <UrlField source='value'/>
          }
        }/>
        <TextField label="Is Client Accessible" source="is_client_accessible" />

      </DataGridList>
    </List>
    </>
  );
}