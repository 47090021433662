import React from 'react';
import { useRecordContext, Labeled } from 'react-admin';

const buttonStyle = {
  background: '#4CAF50', // Green background
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '12px 25px',
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  transition: '0.3s',
  outline: 'none'
};

const hoverStyle = {
  backgroundColor: '#45a049', // Darker green on hover
  transform: 'scale(1.05)', // Slightly enlarge the button
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
};

export const DownloadableInput = ({ record, extension }) => {
  if (!record) return null;

  const downloadURL = record.src;
  const _extension = extension ?? 'zip';
  const filename = record?.rawFile?.name ?? `uploaded_file.${_extension}`;

  return (
    downloadURL ? 
      <a 
        href={downloadURL} 
        download={filename}
        style={buttonStyle}
        onMouseOver={e => Object.assign(e.target.style, hoverStyle)}
        onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
      > 
        Download 
      </a>
      : null
  );
};

export const DownloadableField = ({ source, label }) => {
  let record = useRecordContext();

  if (!record) return null;
    
  source = source.split('.');

  const sourceKey = source[0];
  const fileKey = record[sourceKey]?.key;

  const filename =  fileKey.split('/').pop() ?? `uploaded_file.zip`;

  let downloadURL;
  for (const key of source) {
    record = record?.[key];
    downloadURL = record;
  }

  return (
    downloadURL ? 
      <Labeled label={label}> 
        <a 
          href={downloadURL} 
          download={filename}
          style={buttonStyle}
          onMouseOver={e => Object.assign(e.target.style, hoverStyle)}
          onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
        > 
          Download 
        </a>
      </Labeled>
      : null
  );
};