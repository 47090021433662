import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Show,
  TabbedShowLayout,
  TextField,
  ReferenceField,
  Tab,
  Datagrid,
  ReferenceArrayField,
  ImageField,
  BooleanField
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { AudioField } from "components/Audio/AudioComponents";

import { Button } from '@material-ui/core';
import { useRedirect } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '80px',
      height: '100px',
    }
  },
});

const CreateLessonButton = (props) => {
  const redirect = useRedirect();

  const createLesson = () => {
    redirect(`/lessons/create?unitId=${props.record.id}`);
  };

  return (
    <Button
      onClick={createLesson}
      color="primary"
      startIcon={<AddIcon />}
    >
      Add New Lesson
    </Button>
  );
};

export const UnitsShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source="name" label="Name" />
            <TextField source="sessions_count" label="Sessions Count" />
            <TextField source='sort_index' label='Sort index' fullWidth />
            <BooleanField source='is_active' label='Is Active' />
            <TextField source='unit_points' label='Unit Points' />
            <TextField source='session_points' label='Session Points' />
            <ReferenceField
              source="course_id"
              reference='courses'
              link={(record, reference) =>
              `/${reference}/${record.course_id}/show`
              }
            >
              <TextField source="nick_name" />
            </ReferenceField>
          </Tab>

          <Tab label="Cards">
            <ReferenceArrayField source="cards_ids" reference="cards" fullWidth>
              <Datagrid>
                <ReferenceField 
                  source="id"
                  reference="cards"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
                <AudioField source='audio.url' label='Audio' />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label="Lessons">
            <CreateLessonButton props={props} />
            <ReferenceArrayField source="lessons_ids" reference="lessons" fullWidth>
              <Datagrid>
                <ReferenceField 
                  source="id"
                  reference="lessons"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <TextField source="sort_index" label="Sort index" />
                <TextField source="is_active" label="Is Active" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}