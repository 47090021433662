import React from "react";
import { SelectInput } from "react-admin";

import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const StreamCourseFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <DebouncedTextField label="Name" source="nick_name" />
    <DebouncedTextField label="Short Name" source="short_name" />

    <SelectInput
      label="Active"
      source="is_active"
      choices={[
        { id: true, name: "True" },
        { id: false, name: "False" },
      ]}
    />
  </FilterContainer>
);

export default StreamCourseFilter;
