import moment from "moment-timezone";

import { defaultTimezone } from "config";

export const HelperListExporter = async (data, fetchRelatedRecords, fileName, cb) => {
	let exportableRecords = data.map((record) => {
        cb?.(record,fetchRelatedRecords);
		const { created_at, updated_at, ...exportableFields } = record;
		if (created_at) {
			exportableFields.created_at = moment.tz(created_at, defaultTimezone)._i;
		}
		if (updated_at) {
			exportableFields.updated_at = moment.tz(updated_at, defaultTimezone)._i;
		}
		return exportableFields;
	});

	exportableRecords = exportableRecords.map(_o => {
		const o = quoteValues(_o);
		return o;
	});

	return {
		data: exportableRecords,
		headers: [],
		fileName
	};
};

export const quoteValues= (obj) => {
  // Create a new object to store the results
  let result = {};

  // Iterate over the keys of the object
  for (let key in obj) {
    // If the value is an object, recurse into it
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result[key] = quoteValues(obj[key]);
    } else {
      // Convert the value to a string and add quotes
      result[key] = JSON.stringify(obj[key]);
    }
  }

  // Return the new object
  return result;
}
