import React from "react";
import styles from "./svg.module.css";
const Testimonial = () => {
  return (
    <svg
      id="Capa_1"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      className={styles.iconRoot}
    >
      <g>
        <path d="m140 326c0-27.57-22.43-50-50-50s-50 22.43-50 50 22.43 50 50 50 50-22.43 50-50zm-50 30c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30z" />
        <path d="m10 456h201c5.523 0 10-4.478 10-10s-4.477-10-10-10h-29.996c4.646-22.794 24.85-40 48.996-40h52c24.146 0 44.349 17.206 48.996 40h-29.996c-5.523 0-10 4.478-10 10s4.477 10 10 10h201c5.523 0 10-4.478 10-10 0-38.598-31.402-70-70-70h-40c-25.436 0-47.742 13.641-60 33.988-12.258-20.347-34.564-33.988-60-33.988h-52c-25.436 0-47.742 13.641-60 33.988-12.258-20.347-34.564-33.988-60-33.988h-40c-38.598 0-70 31.402-70 70 0 5.522 4.477 10 10 10zm392-60h40c24.146 0 44.349 17.206 48.996 40h-137.992c4.647-22.794 24.85-40 48.996-40zm-332 0h40c24.146 0 44.349 17.206 48.996 40h-137.992c4.647-22.794 24.85-40 48.996-40z" />
        <path d="m306 326c0-27.57-22.43-50-50-50s-50 22.43-50 50 22.43 50 50 50 50-22.43 50-50zm-50 30c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30z" />
        <path d="m472 326c0-27.57-22.43-50-50-50s-50 22.43-50 50 22.43 50 50 50 50-22.43 50-50zm-50 30c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30z" />
        <path d="m165.491 164.88c-1.176-3.62-4.305-6.259-8.072-6.807l-43.158-6.272-19.303-39.106c-1.685-3.413-5.161-5.574-8.967-5.574s-7.282 2.161-8.967 5.574l-19.304 39.106-43.158 6.272c-3.767.548-6.896 3.187-8.072 6.807-1.176 3.619-.195 7.594 2.53 10.25l31.226 30.441-7.372 42.979c-.644 3.752.898 7.543 3.978 9.78 3.079 2.236 7.161 2.531 10.531.762l38.605-20.293c7.045 3.705 38.597 20.292 38.597 20.292 3.402 1.789 7.483 1.455 10.532-.76 3.08-2.237 4.623-6.029 3.979-9.781l-7.371-42.978 31.234-30.441c2.727-2.656 3.708-6.631 2.532-10.251zm-51.491 30.039c-2.357 2.297-3.433 5.607-2.876 8.852l4.835 28.189s-16.263-8.549-25.313-13.31c-2.915-1.532-6.396-1.534-9.309-.002l-25.326 13.313 4.835-28.19c.557-3.244-.519-6.554-2.875-8.851l-20.483-19.969 28.311-4.114c3.257-.474 6.072-2.52 7.528-5.471l12.664-25.653 12.663 25.653c1.457 2.951 4.272 4.997 7.529 5.471l28.307 4.114z" />
        <path d="m505.511 164.88c-1.176-3.62-4.306-6.259-8.072-6.807l-43.158-6.272-19.303-39.106c-1.685-3.413-5.161-5.574-8.967-5.574s-7.282 2.161-8.967 5.574l-19.303 39.106-43.158 6.272c-3.767.548-6.896 3.187-8.072 6.807s-.195 7.595 2.531 10.251l31.234 30.441-7.371 42.978c-.644 3.752.899 7.543 3.978 9.78 3.081 2.239 7.163 2.531 10.532.762l38.597-20.294 38.604 20.293c3.409 1.792 7.489 1.452 10.532-.76 3.08-2.237 4.623-6.029 3.979-9.781l-7.372-42.979 31.226-30.441c2.725-2.656 3.706-6.631 2.53-10.25zm-51.482 30.04c-2.356 2.297-3.432 5.606-2.875 8.851l4.835 28.19-25.325-13.312c-1.457-.767-3.055-1.149-4.654-1.149-1.598 0-3.197.383-4.654 1.148l-25.314 13.311 4.834-28.188c.557-3.244-.519-6.555-2.876-8.852l-20.488-19.968 28.307-4.114c3.257-.474 6.072-2.52 7.529-5.471l12.663-25.653 12.664 25.653c1.457 2.951 4.271 4.997 7.528 5.471l28.311 4.114z" />
        <path d="m355.66 128.409c-1.176-3.62-4.306-6.258-8.072-6.806l-57.088-8.293-25.533-51.735c-1.684-3.414-5.16-5.575-8.967-5.575s-7.283 2.161-8.967 5.574l-25.533 51.736-57.087 8.294c-3.767.548-6.896 3.186-8.072 6.806-1.177 3.62-.196 7.594 2.529 10.251l41.306 40.271-9.751 56.859c-.644 3.752.898 7.543 3.978 9.78 3.08 2.236 7.161 2.532 10.531.762l51.066-26.845 51.067 26.844c3.375 1.775 7.458 1.471 10.531-.762 3.08-2.237 4.622-6.028 3.978-9.78l-9.751-56.859 41.306-40.271c2.725-2.657 3.706-6.631 2.529-10.251zm-61.561 39.871c-2.356 2.297-3.432 5.606-2.875 8.851l7.215 42.071-37.787-19.863c-1.457-.766-3.055-1.148-4.653-1.148s-3.196.383-4.653 1.148l-37.787 19.863 7.215-42.071c.557-3.244-.519-6.554-2.875-8.851l-30.563-29.797 42.24-6.137c3.257-.474 6.073-2.52 7.529-5.471l18.895-38.279 18.893 38.28c1.457 2.951 4.272 4.997 7.529 5.471l42.24 6.137z" />
        <circle cx="256" cy="446" r="10" />
      </g>
    </svg>
  );
};
export default Testimonial;
