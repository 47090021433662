import { Admin } from "react-admin";
import jsonwebtoken from "jsonwebtoken";
import Menu from "components/Menu/Menu";
import dataProvider from "providers/Data";
import MomentUtils from "@date-io/moment";
import Login from "components/Login/Login";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import MyLayout from "components/AppBar/Layout";
import englishMessages from "ra-language-english";
import { ThemeContext } from "themes/ThemeContext";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "providers/Authentication";
import Dashboard from "components/Dashboard/Dashboard";
import { MyDarkTheme, MyLightTheme } from "themes/Themes";
import BackdropLoader from "components/Loaders/BackdropLoader";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

import kangiRoutes from "resources/Kangi";
import usersRoutes from "resources/Users";
import systemRoutes from "resources/System";
import flashCardRoutes from "resources/FlashCard";
import bulkCreateRoutes from "resources/BulkCreate";
import notificationsRoutes from "resources/Notification";
import streamRoutes from "resources/Stream";

let previousUrlPath;

const App = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);

  const [hasSystemPermissions, setHasSystemPermissions] = useState(false);
  const [hasModeratorPermissions, setHasModeratorPermissions] = useState(false);
  const [hasSupportPermissions, setHasSupportPermissions] = useState(false);
  
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (token) {
      const role = jsonwebtoken.decode(token)?.role;

      if (['root', 'admin'].includes(role)) {
        setHasSystemPermissions(true);
      } else if (role === 'moderator') {
        setHasModeratorPermissions(true);
      } else if (role === 'support') {
        setHasSupportPermissions(true);
      }
    }
  }, []);
  
  // if the current page is home (dashboard), don't show the loader:
  useEffect(() => {
    previousUrlPath = history.location.hash;
    if (
      history.location.hash.charAt(history.location.hash.length - 1) === "/"
    ) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, []); // eslint-disable-line

  // whenever the url changes, show loader (unless it is the dashboard):
  useEffect(() => {
    return history.listen(() => {
      // the following code prevents the bug where the user
      // clicks the browser back button from a create/show/edit page and add a filter:
      if (
        previousUrlPath.split("").filter((letter) => letter === "/").length >=
          2 &&
        history.location.hash.split("").filter((letter) => letter === "/")
          .length < 2
      ) {
        window.location.reload();
      }
      previousUrlPath = history.location.hash;

      // here we are showing the loader if the url is not home (dashboard):
      if (
        history.location.hash.charAt(history.location.hash.length - 1) === "/"
      ) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }
    });
  }, [history]);

  const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
    allowMissing: true,
  });
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <MuiPickersUtilsProvider  utils={MomentUtils}>
      <Admin
        dashboard={Dashboard}
        layout={MyLayout}
        theme={theme === "dark" ? MyDarkTheme : MyLightTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={Login}
        menu={Menu}
        i18nProvider={i18nProvider}
      >
        {showLoader ? <BackdropLoader /> : <></>}

        {(hasSystemPermissions || hasSupportPermissions) ? usersRoutes : <></>}

        {(hasSystemPermissions || hasModeratorPermissions) ? kangiRoutes : <></>}
        {streamRoutes}
        {(hasSystemPermissions || hasModeratorPermissions) ? flashCardRoutes : <></>}
        {(hasSystemPermissions || hasModeratorPermissions) ? bulkCreateRoutes : <></>}

        {(hasSystemPermissions) ? notificationsRoutes : <></>}
        {(hasSystemPermissions) ? systemRoutes : <></>}
        

        
      </Admin>
      </MuiPickersUtilsProvider>
      <ToastContainer/>
    </ThemeContext.Provider>
  );
};

export default App;
