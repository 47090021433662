import React from 'react';
import UnitFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { List, TextField, ReferenceField, BooleanField } from 'react-admin';

export const UnitsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Units'
      filters={<UnitFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Units' />}
    >
      <DataGridList edit show >
        <TextField source="name" label="Name" fullWidth />
        <TextField source="sessions_count" label="Sessions count" fullWidth />
        <TextField source='sort_index' label='Sort index' fullWidth />
        <BooleanField source='is_active' label="Is Active" fullWidth />
        <TextField source='unit_points' label='Unit Points' />
        <TextField source='session_points' label='Session Points' />

        <ReferenceField
              source="course_id"
              reference='courses'
              link={(record, reference) =>
              `/${reference}/${record.course_id}/show`
              }
          >
              <TextField source="nick_name" />
        </ReferenceField>

      </DataGridList>
      
    </List>
    </>
    )
};
