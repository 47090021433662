import React from 'react';
import SupportersFilter from './filter';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { List, TextField } from 'react-admin';


export const SupportersList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Supporters'
      filters={<SupportersFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='Supporters' />}
    >
      <DataGridList show>

        <TextField source='id' label='ID'/>
        <TextField source='full_name' label='Name'/>
        <TextField source='email' label='Email'/>
        <TextField source='role' label='Role'/>
        <TextField source='last_login' label='Last Login'/>

      </DataGridList>
    </List>
    </>
    )
};
