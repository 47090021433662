import CoursesIcon from '@mui/icons-material/School';

import { StreamCoursesEdit } from './edit';
import { StreamCoursesList } from './list'
import { StreamCoursesShow } from './show';
import { StreamCourseCreate } from './create';

export default {
  name: "stream_courses",
  options: { label: "Courses", menuParent: "stream" },
  icon: CoursesIcon,
  list: StreamCoursesList,
  show: StreamCoursesShow,
  create: StreamCourseCreate,
  edit: StreamCoursesEdit,
};
