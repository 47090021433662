import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

import {
  Edit,
  FormTab,
  required,
  TextInput,
  FileInput,
  TabbedForm,
  ImageField,
  SelectInput,
  FormDataConsumer,
} from "react-admin";

import StreamContentTab from "components/StreamHelpers/edit";


export const SetsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Edit title={<EditTitle source="name" />} {...props}>

        <TabbedForm variant="standard" margin="normal" toolbar={<EditToolbar {...props} />} {...props}>
          <FormTab label="Details">
            <TextInput source="full_name" validate={required()} fullWidth />
            <TextInput source="short_name" validate={required()} fullWidth />
            <SelectInput
              source='type'
              fullWidth
              choices={[
                { id: 'Bundle', name: 'Bundle' },
                { id: 'Base Course', name: 'Base Course' }
              ]}
            />

            <FileInput
              source="icon_url_file"
              accept="image/*"
              label="IconURL Thumbnail"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <ImageField source="src" />
            </FileInput>

            <FileInput
              source="bgl0_file"
              accept="image/*"
              label="BGL0 Thumbnail"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <ImageField source="src" />
            </FileInput>

            <FileInput
              source="bgl1_file"
              accept="image/*"
              label="BGL1 Thumbnail"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <ImageField source="src" />
            </FileInput>

            <FileInput
              source="bgl2_file"
              accept="image/*"
              label="BGL2 Thumbnail"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <ImageField source="src" />
            </FileInput>

            <FileInput
              source="bgl3_file"
              accept="image/*"
              label="BGL3 Thumbnail"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <ImageField source="src" />
            </FileInput>
          </FormTab>

          {/* Special handling for Stream content */}
          <FormTab label="Stream Content">
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return <StreamContentTab {...rest} />;
              }}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
}