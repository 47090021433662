import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Popover,
    Divider
  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {ExpandLess,ExpandMore,ViewList as DefaultIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginInlineStart: theme.spacing(2)
  },
}));

const ParentMenu = ({ open, onMenuClick, parent, primaryIcon,onParentClick,isParentOpen,closeParent,...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ParentClickHandler = (e) => {
    if (!open) {
        handleClick(e)
    } else {
        onParentClick(e)
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <>
      <ListItem button onClick={ParentClickHandler}>
        <ListItemIcon>{parent?.icon ? <parent.icon /> : <DefaultIcon />}</ListItemIcon>
        <ListItemText primary={parent?.options?.label} />
        {isParentOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      {
          open ? (
            <Collapse in={isParentOpen} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>
                     {props.children}
                 </List>
            </Collapse>
          ) : (
            <Popover
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
               <List component="div" disablePadding>
                   {props.children}
               </List>
          </Popover>
          )
      }
      {parent?.options?.withDivider  &&  <Divider className={classes.divider}/>}

    </>
  );
};

export default ParentMenu;
