import NewspaperIcon from '@mui/icons-material/Newspaper';

import { NewsList } from './list';
import { NewsEdit } from './edit';
import { NewsShow } from './show';
import { NewsCreate } from './create';

export default {
  name: "news",
  options: { label: "News", menuParent: "kangi" },
  icon: NewspaperIcon,
  list: NewsList,
  show: NewsShow,
  create: NewsCreate,
  edit: NewsEdit,
};
