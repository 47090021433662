import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { 
   Edit,
   TabbedForm,
   TextInput,
   required,
   FormDataConsumer,
   ReferenceInput,
   AutocompleteInput,
   FormTab,
   ReferenceArrayInput,
   AutocompleteArrayInput,
   BooleanInput
} from "react-admin";

export const UnitsEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>

        <TabbedForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
          <FormTab label='Details'>
              <TextInput source="name" validate={required()} fullWidth />
              <TextInput source="sessions_count" label="Sessions Count" validate={required()} />

              {/* Requirements were changed, not necessary anymore. */}
              {/* <TextInput 
                source="seen_cards_percentage" 
                label="Seen Cards Percentage" 
                helperText="The amount of cards (in percentages) that have already been seen, which will be added to each session | Example: If there is 10 unseen cards on each session, and the value of this field is 20, then 2 seen cards will be added, in total the session will contain 12 cards (10 unseen, 2 seen)
                | Example value: 20 (which means 20%) | If not defined, the value will be as the seen_cards_percentage value in settings" 
                />
                <TextInput 
                source="session_exposure_target" 
                label="Session Exposure Target"
                helperText="Target (in percentages) for displaying all the cards || Example: if the number of sessions in the unit is 5, and the target is 80%, then after 4 sessions the user will see all the cards of the unit, the rest will be practice of cards he has already seen || Example value: 80 (which means 80%) || if not defined, the value will be as the session_exposure_target value in settings"
              /> */}
              <TextInput source='sort_index' label="Sort Index" validate={required()} />
              <TextInput source='unit_points' label='Unit Points' validate={required()} />
              <TextInput source='session_points' label='Session Points' validate={required()} />
              <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />

              <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  source="course_id"
                  reference="courses"
                  label="Course"
                  validate={required()}
                  perPage={25}
                  filterToQuery={(searchText) => ({
                    nick_name: searchText,
                    short_name: searchText,
                    _autocomplete: true,
                  })}
                  fullWidth
                  // disabled
                  >
                  <AutocompleteInput
                    matchSuggestion={(filterValue, suggestion) => true}
                    optionText={(choice) =>
                      choice && choice.id
                        ? `${choice.nick_name} (${choice.id})`
                        : "None"
                    }
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>

          </FormTab>

          <FormTab label="Cards">
            <ReferenceArrayInput
              label="Cards"
              source="cards_ids"
              reference="cards"
              perPage={25}
              filterToQuery={(searchText) => ({
                name: searchText,
                _autocomplete: true,
              })}
              sort={{ field: "id", order: "ASC" }}
              fullWidth
              allowEmpty
            >
              <AutocompleteArrayInput
                matchSuggestion={(filterValue, suggestion) => true}
                optionText={(choice) =>
                  choice && choice.id
                    ? `${choice.name} (${choice.id})`
                    : "None"
                }
              />
            </ReferenceArrayInput>
          </FormTab>

        </TabbedForm>
			</Edit>
		</>
	);
}


