import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, CircularProgress } from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

import { bulkProcess, BulkCreateEndpoint } from 'providers/BulkCreate';

export const CardsBulkCreate = () => {
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedZipFile, setSelectedZipFile] = useState(null);
  const [selectedXlsxFile, setSelectedXlsxFile] = useState(null);

  const handleZipFileChange = (event) => {
    setSelectedZipFile(event.target.files[0]);
  };

  const handleXlsxFileChange = (event) => {
    setSelectedXlsxFile(event.target.files[0]);
  };

  const validateRequiredFields = () => {
    if (!selectedZipFile) {
      toast.error('ZIP file is missing!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  }

  const onSubmit = async () => {
    setIsLoading(true);
    const uploadId = uuidv4();

    if (!selectedZipFile) {
      setIsLoading(false);
      return;
    }

    try {
      await bulkProcess(selectedZipFile, uploadId, BulkCreateEndpoint.BULK_CREATE);

      if (selectedXlsxFile) {
        await bulkProcess(selectedXlsxFile, uploadId, BulkCreateEndpoint.HANDLE_ASSOCIATIONS);
      }
    } catch (error) {
      toast.error('Error', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
      setIsLoading(false);
      return;
    }

    toast.success('Success', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'colored',
    });
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>Bulk Cards Create</h1>

      <h3><i><u>Step 1: Choose the ZIP File Containing the Cards' Resources</u></i></h3>

      <div>
        <p><strong><u>Folders Structure:</u></strong></p>
        <p>
              ├── [card's name #1]
              <br></br>
              │   ├── [card #1 audio file].mp3
              <br></br>
              │   └── [card #1 image file].png
              <br></br>
              ├── [card's name #2]
              <br></br>
              │   ├── [card #2 audio file].mp3
              <br></br>
              │   └── [card #2 image file].png
              <br></br>
              .<br></br>.<br></br>.<br></br>
              ├── [card's name #n]
              <br></br>
              │   ├── [card #n audio file].mp3
              <br></br>
              │   └── [card #n image file].png
              <br></br>
        </p>
      </div>

      <b><u>Notes:</u></b>
      <ul>
        <li>The card's folder name will be used to create a new card with the specified name.</li>  
        <li>The names of the image & audio files can be anything, since the extension is used for identifying the resource type.</li>
        <li>The image files must be in PNG format.</li>
        <li>The audio files must be in MP3 format.</li>
      </ul>

      <br></br>

      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        onChange={handleZipFileChange}
      >
        Choose ZIP File
        <input type="file" hidden required />
      </Button>

      <span style={{ color: 'orange' }}>{selectedZipFile && ` ${selectedZipFile.name}`}</span>

      <br></br>
      <br></br>

      <h3><i><u>Step 2 (Optional): Choose the XLSX Specifying the Mapping</u></i></h3>

      <p>Description: The mapping file will be used to determine the mapping between the newly created cards and their associations between the Units / Episodes of the specified Course and between Lessons.</p>

      <div>
        <p><strong><u>XLSX File Columns:</u></strong></p>
        <ul>
          <li><b>card_folder:</b> The folder's name containing the card's resources in the attached ZIP file (e.g. 'rabbit_IBD').</li>
          <li><b>display_card_name:</b> The name of the card that should be displayed on the client application (e.g. 'Rabbit'). If left blank, the card_folder value will be used.</li>
          <li><b>course_name:</b> The name of the course holding the Unit / Episode (e.g. 'It's a Baby Dragon').</li>
          <li><b>course_short_name:</b> The short name of the Course. Can be found on Admin Panel > Flash Cards > Courses (e.g. 'IBD'). Will be used as a fallback value if the Course was not found using the full name and vice versa.</li>
          <li><b>section_sort_index:</b> The sort index of the section (Unit / Episode) that is requested to contain the card (e.g. 1). Each sort index can be found in Admin Panel > Flash Cards > Units</li>
          <li><b>display_lesson_name:</b> The name of the lesson that is requested to contain the card (e.g. 'Lesson 1'). Will be used to create a new lesson with the specified name (in the context of the Unit & Course) if the lesson could not be found using the lesson_sort_index.</li>
          <li><b>lesson_sort_index:</b> The sort index of the Lesson that is requested to contain the card (e.g. 1). Each sort index can be found in <b>Admin Panel > Flash Cards > Lessons</b> OR <b>Admin Panel > Flash Cards > Units > [Unit] > Lessons (tab).</b></li>
          <li><b>definition (optional):</b> Additional metadata for the card (e.g. Animals).</li>
        </ul>
      </div>

      <br></br>

      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        onChange={handleXlsxFileChange}
      >
        Choose XLSX File
        <input type="file" hidden />
      </Button>

      <span style={{ color: 'orange' }}>{selectedXlsxFile && ` ${selectedXlsxFile.name}`}</span>


      <br></br>
      <br></br>

      <Button
        type="submit"
        variant="contained"
        disabled={isLoading}
        onClick={validateRequiredFields}
        >
          {isLoading ? ( <CircularProgress size={24} /> ) : ("Submit")}
      </Button>

    </form>
  );
};
