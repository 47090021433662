import {
  DebouncedTextField,
  RangeFilter,
  SearchField,
} from "components/Fields";
import Filter from "components/Filter/Filter";
import React from "react";
import { DateInput } from "react-admin";

const FilterContainer = ({ children, disableDates, ...props }) => {
  return (
    <Filter {...props}>
      <SearchField source="q" alwaysOn />
      <DebouncedTextField label="ID" source="id" />
      {children}

      {!disableDates && (
      <RangeFilter label="Created At" source="created_at">
        <DateInput label="Created At (from)" source="min_created_at" />
        <DateInput label="Created At (to)" source="max_created_at" />
      </RangeFilter>)}

      {!disableDates && (
      <RangeFilter label="Updated At" source="updated_at">
        <DateInput label="Updated At (from)" source="min_updated_at" />
        <DateInput label="Updated At (to)" source="max_updated_at" />
      </RangeFilter>)}
    </Filter>
  );
};

export default FilterContainer;
