import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
  FormDataConsumer,
  required,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput
} from "react-admin";

export const UnitCreate = (props) => (
  <Create {...props} title="Create Unit">
    <TabbedForm variant="standard" margin="normal" toolbar={<CreateToolbar hidedelete />}>
      <FormTab label='Details'>
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="sessions_count" label="Sessions count" validate={required()} />
          <TextInput source='sort_index' label="Sort index" validate={required()} />
          <TextInput source='unit_points' label='Unit Points' validate={required()} />
          <TextInput source='session_points' label='Session Points' validate={required()} />
          <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />


          <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="course_id"
              reference="courses"
              label="Course"
              validate={required()}
              perPage={500}
              filterToQuery={(searchText) => ({
                nick_name: searchText,
                short_name: searchText,
                _autocomplete: true,
              })}
              fullWidth
              >
              <AutocompleteInput
                matchSuggestion={(filterValue, suggestion) => true}
                optionText={(choice) =>
                  choice && choice.id
                    ? `${choice.nick_name} (${choice.id})`
                    : "None"
                }
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>

      </FormTab>

      <FormTab label="Cards">
        <ReferenceArrayInput
          label="Cards"
          source="cards_ids"
          reference="cards"
          perPage={25}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.name} (${choice.id})`
                : "None"
            }
          />
        </ReferenceArrayInput>
      </FormTab>
      
    </TabbedForm>
  </Create>
  );

