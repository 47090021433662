import React from 'react';
import CourseAssetsFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { List, TextField, ReferenceField, ImageField } from 'react-admin';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const CourseAssetsList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Course Assets'
      filters={<CourseAssetsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Units' />}
    >
      <DataGridList edit show >
        <TextField source="name" label="Name" fullWidth />
        <ImageField source="thumbnail.url" label="Image" className={classes.customImage} sortable={false} />

        <ReferenceField
              source="course_id"
              reference='courses'
              link={(record, reference) =>
              `/${reference}/${record.course_id}/show`
              }
          >
              <TextField source="nick_name" />
        </ReferenceField>

      </DataGridList>
      
    </List>
    </>
    )
};
