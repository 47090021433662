import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Show,
  TextField,
  ReferenceArrayField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceField,
  ImageField,
  BooleanField
} from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const UsersShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>
          <Tab label="Personal Details">
            <TextField source="username" label="Username" />
            <TextField source="type" label="Type" />
            <TextField source="status" label="Status" />
            <TextField source='total_score' label="Total Score" />
            <TextField source='devices_limit' label="Devices Limit" />
            <BooleanField source='has_support_access' label="Single Support Access Allowed?" />
          </Tab>

          <Tab label="Courses">
            <ReferenceArrayField 
              source="courses_ids"
              reference="courses"
              fullWidth
            >
              <Datagrid>

                <ReferenceField 
                  source="id"
                  reference="courses"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="nick_name" label="Name" fullWidth />
                </ReferenceField>

                <TextField source="category" label="Category" fullWidth />
                <BooleanField source='is_active' label="Is Active" fullWidth />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label="Achievements">
            <ReferenceArrayField 
              source="achievements_ids"
              reference="achievements"
              sort={{ field: 'minimum_points', order: 'ASC'}}
              fullWidth
            >
              <Datagrid>

                <ReferenceField 
                  source="id"
                  reference="achievements"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="title" label="Title" fullWidth />
                </ReferenceField>

                <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage}/>
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}