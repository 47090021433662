import moment from "moment-timezone";
import { defaultTimezone } from "config";
import { quoteValues } from 'ListExporters/helper';

export const CouponExporter = async (data, fetchRelatedRecords,type) => {
	const coupons = await fetchRelatedRecords(data, "coupon_id", "coupons");
    const users = await fetchRelatedRecords([...(Object.values(coupons)?? [])],"user_id","users");


	const exportableRecords = data.map((record) => {
		const {
			created_at,
			updated_at,
            coupon_id,
            cart_id,
			...exportableFields
		} = record;


        exportableFields.coupon =  coupons[coupon_id].code;
        exportableFields.coupon_type = coupons[coupon_id].type
        exportableFields.formatted_value = coupons[coupon_id].formatted_value
        exportableFields.user_id = coupons[coupon_id].user_id
        exportableFields.user = users[coupons[coupon_id].user_id].name;
        exportableFields.email = users[coupons[coupon_id].user_id].email;
		exportableFields.created_at =
			created_at && moment.tz(created_at, defaultTimezone)._d;
		exportableFields.updated_at =
			updated_at && moment.tz(created_at, defaultTimezone)._d;

		return quoteValues(exportableFields);
	});

	return {
		data: exportableRecords,
		headers: [
			"id",
			"coupon",
            "coupon type",
            "formatted value",
			"user_id",
			"user",
			"email",
			"created at",
			"updated at",
		],
		fileName: `${type === "order" ? "Order": "Cart"} Coupons`,
	};
};
