import NotificationGroupsFilter from "./filter";
import Breadcrumbs from "components/Breadcrumbs";
import DataGridList from "components/containers/List";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";
import ListActions from "components/ListAction/ListActions";
import { List, TextField, NumberField } from "react-admin";


export const NotificationGroupsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <List
        title="NotificationGroups"
        filters={<NotificationGroupsFilter />}
        pagination={<Paginator />}
        perPage={15}
        sort={{ field: "id", order: "DESC" }}
        {...props}
        empty={<EmptyPage />}
        actions={<ListActions listExporterType="NotificationGroups" />}
      >
        <DataGridList show edit>
          <TextField source="name" />
          <TextField source="description" />
          <NumberField source="notification_types_count" label="Notification Types Count"/>
          <TextField source="is_visible" label="Is Visible" />
        </DataGridList>
      </List>
    </>
  );
}
