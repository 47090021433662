import React from "react";
import { CreateToolbar } from "../../../components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  SelectInput,
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const MinimumVersionCreate = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Create {...props} title="Create Minimum Version">
      <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
        <SelectInput
            validate={required()}
            source="application"
            choices={[
              { id: "kangi", name: "Kangi" },
              { id: "stream", name: "Stream" },
              { id: "flashcards", name: "Flashcards" },
            ]}
        />

        <SelectInput
            validate={required()}
            source="platform"
            choices={[
              { id: "ios", name: "iOS" },
              { id: "android", name: "Android" },
            ]}
        />

        <TextInput source='version' label='Version' />
      </SimpleForm>
    </Create>
    </>
  );
}