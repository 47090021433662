import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { SupportersList } from './list';
import { SupportersShow } from './show';
import { SupportersEdit } from './edit';
import { SupportersCreate } from './create';

export default {
  name: "supporters",
  options: { label: "Support Users", menuParent: "system" },
  icon: AccountCircleIcon,
  list: SupportersList,
  show: SupportersShow,
  edit: SupportersEdit,
  create: SupportersCreate,
};
