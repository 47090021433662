import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

let ErrorBoundary = null;

try {
	const bugsnagClient = bugsnag(process.env.REACT_APP_BUGS_SNAG_KEY);
	bugsnagClient.use(bugsnagReact, React);
	ErrorBoundary = bugsnagClient.getPlugin("react");
} catch (e) {
	// console.log("initializer error:", e);
}

export { ErrorBoundary };
