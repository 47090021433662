import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";
import { DateFieldShow } from "components/Fields";
import { ViewTitle } from "../../../components/AppBar/Titles";

export const DeviceLimitShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Show title={<ViewTitle source="name" />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="application" label="Application" />
        <TextField source="user_type" label="User Type" />
        <TextField source="user_type" label="User Type" />
        <TextField source="value" label="Value" />
        <TextField source="description" label="Description" />
        <DateFieldShow label="Created At" source="created_at" />
        <DateFieldShow label="Updated At" source="updated_at" />
      </SimpleShowLayout>
    </Show>
    </>
  );
}