import React from "react";
import { AudioInput } from 'components/Audio/AudioComponents';
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  ImageField,
  required,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

export const CardCreate = (props) => (
  <Create {...props} title="Create Card">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={required()} />
      <TextInput source="display_name" validate={required()} />
      <TextInput source="definition" fullWidth />
      <TextInput source="example" fullWidth />
      <BooleanInput source="is_active" label='Is Active?' defaultValue={true} />

      <ReferenceArrayInput
        label="Units"
        source="units_ids"
        reference="units"
        validate={required()}
        perPage={500}
        filterToQuery={(searchText) => ({
          course_short_name: searchText, // Special field name, handled specifically by the admin's Unit handler
          _autocomplete: true,
        })}
        sort={{ field: "id", order: "ASC" }}
        fullWidth
        allowEmpty
      >
        <AutocompleteArrayInput
          matchSuggestion={(filterValue, suggestion) => true}
          optionText={(choice) =>
            choice && choice.id
              ? `${choice.course_short_name} ${choice.name} (${choice.id})`
              : "None"
          }
        />
      </ReferenceArrayInput>

      <FileInput
        source="thumbnail_file"
        accept="image/*"
        label="Thumbnail"
        validate={required()}
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>

      <FileInput
        source="audio_file"
        accept="audio/*"
        label="Audio"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <AudioInput source='src' />
      </FileInput>
    </SimpleForm>
  </Create>
  );

