import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import {
  Show, TextField,
  ReferenceField, ImageField,
  SimpleForm,
  SimpleShowLayout
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '80px',
      height: '100px',
    }
  },
});

export const CourseAssetsShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>

          <TextField source="name" label="Name" />
          <ImageField source="thumbnail.url" label="Image" />

          <ReferenceField
            source="course_id"
            reference='courses'
            link={(record, reference) =>
            `/${reference}/${record.course_id}/show`
            }
          >
            <TextField source="nick_name" />
          </ReferenceField>
          
        </SimpleShowLayout>
      </Show>
  </>
);
}