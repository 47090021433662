import { NotificationTypeEdit } from "./edit";
import { NotificationTypesList } from './list';
import { NotificationTypesShow } from "./show";
import { NotificationTypesCreate } from './create';
import NotificationTypesIcon from "@material-ui/icons/NotificationImportant";

export default {
    name: "notification_types",
    options: { label: "Notification Types", menuParent: "_notifications" },
    icon: NotificationTypesIcon,
    list: NotificationTypesList,
    show: NotificationTypesShow,
    edit: NotificationTypeEdit,
    create: NotificationTypesCreate
}