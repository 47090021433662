import React, { useState } from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  FunctionField,
  FileField,
  FileInput,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";
import { FileContainer } from "components/ImageContainer/FileContainer";
import { FileUploaderPlaceholder } from "components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder";

export const SettingsEdit = (props) => {
  const menuParent = props.options.menuParent;
  const [selectedValueType, setSelectedValueType] = useState();
  const [isValueChanged, setIsValueChanged] = useState(false);

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
        <TextInput source="key" validate={required()} fullWidth />
        <SelectInput
            validate={required()}
            source="value_type"
            onChange={(e) => {
              setSelectedValueType(e.target.value);
              setIsValueChanged(true);
            }}
            choices={[
              { id: "string", name: "string" },
              { id: "file", name: "file" },
              { id: "image", name: "image" },
              { id: "video", name: "video" },
              { id: "json", name: "json"}
            ]}
        />

        <FunctionField source="value" validate={required()} render={(record) => {
          if (((record.value_type === 'string' || record.value_type ==='json') && !isValueChanged) || selectedValueType === 'string') {
            return <TextInput source='value' label='' multiline fullWidth />
          }

          return <FileInput
            source="value_file"
            accept="application/pdf,image/*"
            label="File"
            placeholder={<FileUploaderPlaceholder type="file" />}
            fullWidth
          >
            <FunctionField
              render={(record) => {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FileField source="src" />
                    {/* NOTICE: when uploading new file on a nested structure like the following, the src blob will be as a undefined key*/}
                    <FileContainer
                      url={record.src ? record.src : record.undefined}
                      type={
                        record?.rawFile
                          ? record.rawFile.type.includes("pdf")
                            ? "pdf"
                            : "image"
                          : "image"
                      }
                    />
                  </div>
                );
              }}
            />
          </FileInput>
        }}
        />
        <TextInput source="description" fullWidth />
        <BooleanInput
          source="is_client_accessible"
          label="Is Client Accessible"
          fullWidth
        />
      </SimpleForm>
    </Edit>
    </>
  );
}