import React from 'react';
import DevicesFilter from './filter';
import { DateField } from 'components/Fields';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import { List, TextField, BulkDeleteButton, ReferenceField, Datagrid, EditButton, ShowButton } from 'react-admin';


export const DevicesList = (props) => {
  const menuParent = props.options.menuParent;

  const DevicesBulkActionButtons = (props) => (
    <>
        <BulkDeleteButton {...props} />
        {/* You can add more bulk action buttons here if needed */}
    </>
  );

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Devices'
      filters={<DevicesFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='Devices' hideCreateButton={true} bulkActionButtons={<DevicesBulkActionButtons/>} />}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ReferenceField 
          source="owner_id"
          reference="users"
          label="User"
          link={(record, reference) =>
            `/${reference}/${record.owner_id}/show`
          }
        >
          <TextField source="username" label="Username" />
        </ReferenceField>
        <TextField source='nick_name' label="Nick Name" />
        <TextField source='token' label="Token" />
        <TextField source='permission' label="Permission" />
        <TextField source='os_type' label="Os Type" />
        <TextField source='app_name' label="Application" />
        <TextField source='last_login' label="Last Login" />
        <DateField label="Created At" source="created_at" />
        <DateField label="Updated At" source="updated_at" />
        <EditButton />
        <ShowButton />

      </Datagrid>
    </List>
    </>
    )
};
