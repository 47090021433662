import UserIcon from '@mui/icons-material/Person';

import { UsersList } from './list';
import { UsersEdit } from './edit';
import { UsersShow } from './show';

export default {
  name: "users",
  options: { label: "Main", menuParent: "user" },
  icon: UserIcon,
  list: UsersList,
  show: UsersShow,
  // create: UserCreate,
  edit: UsersEdit,
};
