import React from "react";
import {
  SelectInput,
  TextInput
} from "react-admin";
import {
  DebouncedTextField
} from "components/Fields";
import FilterContainer from "components/containers/Filter";

const SettingsFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">

    <TextInput label="Key" source="key" />

    <DebouncedTextField label="Value" source="value" />
    <SelectInput
      source="is_client_accessible"
      label="Is Client Accessible"
      choices={[
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ]}
    />
  </FilterContainer>
);

export default SettingsFilter;
