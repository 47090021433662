import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, BooleanField, ReferenceField } from "react-admin";

const styles = {
  multiline : {
      whiteSpace: 'pre-wrap'
  }
};

export const SupportTicketsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          
        <TextField source="id" label="ID"/>
        <ReferenceField 
          source="user_id"
          reference="users"
          label="User"
          link={(record, reference) =>
            `/${reference}/${record.id}/show`
          }
        >
          <TextField source="username" label="Username" />
        </ReferenceField>

        <TextField source='content' label="Content" />

        <TextField source='app_name' label='Application Name' />
        <BooleanField source='is_resolved' label='is Resolved' />
      
        </SimpleShowLayout>
      </Show>
  </>
);
}