import React from 'react';
import CourseFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import { ColorField } from 'react-admin-color-input';
import { makeStyles } from '@material-ui/core/styles';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { BooleanField, List, TextField, ImageField } from 'react-admin';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const StreamCoursesList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Courses'
      filters={<CourseFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='Courses' hideCreateButton={false} />}
    >
      <DataGridList edit show >
        <TextField source="name" label="Name" fullWidth />
        <TextField source="short_name" label="Short Name" fullWidth />
        <TextField source='sort_index' label='Sort Index' fullWidth />
        <BooleanField source='is_active' label="Is Active" fullWidth />
        <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
        <ImageField source="featured_banner_image.url" label="Featured Banner Image" className={classes.customImage} sortable={false} />
        <ImageField source="roadmap_background.url" label="Roadmap Background" className={classes.customImage} sortable={false} />
      </DataGridList>
      
    </List>
    </>
    )
};
