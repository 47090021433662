import React from "react";
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";
import { DateFieldShow } from "components/Fields";
import { ViewTitle } from "../../../components/AppBar/Titles";

export const MinimumVersionShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Show title={<ViewTitle source="application" />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="application" label="Application" />
        <TextField source="platform" label="Platform" />

        <FunctionField
            label="Version"
            render={(record) => record.version ?? "N/A"}
          />

        <DateFieldShow label="Created At" source="created_at" />
        <DateFieldShow label="Updated At" source="updated_at" />
      </SimpleShowLayout>
    </Show>
    </>
  );
}