import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { SelectInput, AutocompleteInput, ReferenceInput } from 'react-admin';

const UserAccessFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>

    <ReferenceInput
        source="user_id"
        reference="users"
        label="Username"
        filterToQuery={(searchText) => ({
          username: searchText,
            id: parseInt(searchText),
            _autocomplete: true,
          })}
    >
        <AutocompleteInput
          matchSuggestion={(filterValue, suggestion) => true}
          optionText={(choice) => {
            return `${choice?.id ? `${choice.username} (${choice.id})` : "None" }`
          }}
          />
    </ReferenceInput>

    <SelectInput
        source='client_app_id'
        label="Application ID"
        choices={[
          { id: "streamapp", name: "streamapp" },
          { id: "kmobileapp", name: "kmobileapp" },
          { id: "kwebapp", name: "kwebapp" },
          { id: "readapp", name: "readapp" },
          { id: "teensDLDapp", name: "teensDLDapp" },
          { id: "flashcards", name: "flashcards" },
        ]}
    />

  </FilterContainer>
);

export default UserAccessFilter;