import FilterContainer from "components/containers/Filter";
import { ReferenceInput, AutocompleteInput } from "react-admin";

const LessonFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <ReferenceInput
      source="unit_id"
      reference="units"
      label="Unit"
      perPage={25}
      filterToQuery={(searchText) =>
        searchText
        ? { "name": searchText,
           _autocomplete: true }
          : ""
      }
    >
      <AutocompleteInput
        matchSuggestion={(filterValue, suggestion) => true}
        optionText={(choice) =>
           `${choice?.name} (${choice?.id})`
        }
      />
    </ReferenceInput>
  </FilterContainer>
);

export default LessonFilter;
