import React from 'react';
import { SelectInput } from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const NewsFilter = (props) => (
  <FilterContainer disableDates {...props} variant='standard' margin='normal'>

    <DebouncedTextField label='Title' source='title' />
    <DebouncedTextField label='Description' source='description' />

    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: 'active', name: "Active" },
        { id: 'inactive', name: "Inactive" },
      ]}
    />

    <SelectInput
      label="Users Group"
      source="user_group"
      choices={[
        { id: 'all', name: "All" },
        { id: 'network', name: "Network" },
        { id: 'students', name: "Students" },
      ]}
    />

    <SelectInput
      label="Attachment Type"
      source="attachment_type"
      choices={[
        { id: 'file', name: "File" },
        { id: 'website', name: "Website URL" },
      ]}
    />

  </FilterContainer>
);

export default NewsFilter;