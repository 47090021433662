import React from 'react';
import AdminLogsFilter from './filter';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { List, TextField, FunctionField, ReferenceField } from 'react-admin';


export const AdminLogsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Logs'
      filters={<AdminLogsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='AdminLogs' hideCreateButton={true} />}
    >
      <DataGridList show>

        <TextField source='admin_user_id' label='Admin ID'/>
        <ReferenceField 
          source="admin_user_id"
          reference="admin_users"
          label="Admin's Name"
          link={false}
        >
          <FunctionField render={(record) => `${record.full_name ?? record.email}`} />
        </ReferenceField>
        

        <FunctionField 
          label="Action" 
          render={(record) => {
          // Capitalize first letter of the record.action
          return record.action.charAt(0).toUpperCase() + record.action.slice(1);
          }}
        />
        
        <TextField source='resource' label='Resource'/>

      </DataGridList>
    </List>
    </>
    )
};
