import React from "react";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const CategoryFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <DebouncedTextField label="Name" source="name" />
    <DebouncedTextField label="Sort Index" source="sort_index" />
  </FilterContainer>
);

export default CategoryFilter;
