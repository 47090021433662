import FilterContainer from "components/containers/Filter";
import { ReferenceInput, AutocompleteInput, SelectInput } from "react-admin";

const UnitFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <ReferenceInput
      source="course_id"
      reference="courses"
      label="Course"
      perPage={500}
      filterToQuery={(searchText) =>
        searchText
        ? { "nick_name": searchText,
            "short_name": searchText,
           _autocomplete: true }
          : ""
      }
    >
      <AutocompleteInput
        matchSuggestion={(filterValue, suggestion) => true}
        optionText={(choice) =>
           `${choice?.nick_name} (${choice?.id})`
        }
      />
    </ReferenceInput>

    <SelectInput
      label="Active"
      source="is_active"
      choices={[
        { id: true, name: "True" },
        { id: false, name: "False" },
      ]}
    />
  </FilterContainer>
);

export default UnitFilter;
