import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ModeratorsList } from './list';
import { ModeratorsShow } from './show';
import { ModeratorsEdit } from './edit';
import { ModeratorsCreate } from './create';

export default {
  name: "moderators",
  options: { label: "Moderators", menuParent: "system" },
  icon: AccountCircleIcon,
  list: ModeratorsList,
  show: ModeratorsShow,
  edit: ModeratorsEdit,
  create: ModeratorsCreate,
};
