import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { SelectInput, AutocompleteInput, ReferenceInput } from 'react-admin';

const DevicesFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>    
  
    <ReferenceInput
      source="owner_id"
      reference="users"
      label="Username"
      perPage={25}
      filterToQuery={(searchText) =>
        searchText
        ? { "username": searchText,
           _autocomplete: true }
          : ""
      }
    >
      <AutocompleteInput
        matchSuggestion={(filterValue, suggestion) => true}
        optionText={(choice) =>
           `${choice?.username} (${choice?.id})`
        }
      />
    </ReferenceInput>

    <SelectInput
      source="permission"
      label="Permission"
      choices={[
        { id: "maintainer", name: "Maintainer" },
        { id: "guest", name: "Guest" },
      ]}
    />

    <SelectInput
      source="os_type"
      label="Os Type"
      choices={[
        { id: "web", name: "Web" },
        { id: "ios", name: "IOS" },
        { id: "android", name: "Android" },
        { id: "other", name: "Other" },
      ]}
    />

    <SelectInput
      source="app_name"
      label="Application"
      choices={[
        { id: "kangi", name: "Kangi" },
        { id: "stream", name: "Stream" },
        { id: "flashcards", name: "Flashcards" },
      ]}
    />


  </FilterContainer>
);

export default DevicesFilter;