import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Edit, SimpleForm, TextInput, required, SelectInput, ReferenceInput, FileInput, ImageField } from "react-admin";


export const KAppsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Edit title={<EditTitle source="name" />} {...props}>
        <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="comment" fullWidth />

          <SelectInput
            source='status'
            label='Access'
            fullWidth
            choices={[
              {id: '1', name:'Restricted: Enrolled users only'},
              {id: '2', name:'Public'}
            ]}
          />

          <ReferenceInput
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            perPage={500}
          >
            <SelectInput optionText="full_name" />
          </ReferenceInput>

          <FileInput
            source="bg_image_url_file"
            accept="image/*"
            label="BG Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FileInput
            source="bg_wide_image_url_file"
            accept="image/*"
            label="Wide BG Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

        </SimpleForm>
      </Edit>
    </>
  );
}
