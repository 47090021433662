import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ColorInput } from "react-admin-color-input";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Edit, SimpleForm, TextInput, required, FileInput, ImageField, BooleanInput } from "react-admin";

export const CoursesEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>
				<SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete={false} />}>
          <TextInput source='hdc_id' label='HDC Id' validate={required()} />
          <TextInput source='hdc_name' validate={required()} />
					<TextInput source="nick_name" validate={required()} />
					<TextInput source="short_name" validate={required()} />
          <TextInput source='sort_index' validate={required()} />
          <TextInput source='course_points' validate={required()} />
          <TextInput source='category_id' label="Category ID" validate={required()} />
          <ColorInput source='color_a' label="Color A" picker='Swatches'/>
          <ColorInput source='color_b' label="Color B" picker='Swatches'/>
          <ColorInput source='color_c' label="Color C" picker='Swatches'/>
          <ColorInput source='navbar_text_color' label="Navbar Text Color" picker='Swatches'/>
          <ColorInput source='background_color' label="Background Color" picker='Swatches'/>
          <BooleanInput source="is_active" label='Is Active?' />

          <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FileInput
            source="navbar_thumbnail_file"
            accept="image/*"
            label="Navbar Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <TextInput source="sort_index" validate={required()} fullWidth />
				</SimpleForm>
			</Edit>
		</>
	);
}
