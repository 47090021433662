import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  BooleanField
} from "react-admin";

export const NotificationsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="sender_id" label="Sender id" />
          <TextField source="sender_type" label="Sender type" />
          <TextField source="receiverId" label="Reciever id" />

          <TextField source="notification_type_id" label="Notification type id" />
          <TextField source="title" label="Title" />
          <TextField source="body" label="Body" />
          <TextField source="linkable_id" label="Linkeable id" />
          <TextField source="linkeable_type" label="Linkable type" />
          <TextField source="delivery_status" label="Delivery status" />
          <BooleanField source="is_visible" label="Is Visible?" />
        </SimpleShowLayout>
      </Show>
  </>
);
}