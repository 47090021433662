import {NotificationGroupsList} from './list';
import {NotificationGroupsShow} from "./show";
import { NotificationGroupsEdit } from "./edit";
import NotificationTypesIcon from "@material-ui/icons/NotificationImportant";

export default {
    name: "notification_groups",
    options: { label: "NotificationGroups", menuParent: "_notifications" },
    icon: NotificationTypesIcon,
    list: NotificationGroupsList,
    show: NotificationGroupsShow,
     edit: NotificationGroupsEdit
}