import UnitsIcon from '@mui/icons-material/LocalLibrary';

import { UnitsEdit } from './edit';
import { UnitsList } from './list'
import { UnitsShow } from './show';
import { UnitCreate } from './create';

export default {
  name: "units",
  options: { label: "Units", menuParent: "flash_cards" },
  icon: UnitsIcon,
  list: UnitsList,
  show: UnitsShow,
  create: UnitCreate,
  edit: UnitsEdit,
};
