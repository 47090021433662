import { createTheme } from "@material-ui/core/styles";

export const MyDarkTheme = createTheme({
  fontFamily: "'Spartan', sans-serif",
  palette: {
    type: "dark",
    primary: { main: "#248ad3" },
    secondary: { main: "#616161e6" },
    error: { main: "#e5455c" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Spartan', sans-serif",
  },
  overrides: {
    // changing the bulk actions button styling:
    RaTopToolbar: {
      root: {
        paddingTop: "0",
        paddingBottom: "0",
        color: "white !important",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        // backgroundColor: "#fff",
      },
    },
    RaFileInput: {
      dropZone: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    RaLayout: {
      root: {
        overflowY: "auto",
      },
      // content: {
      // overflow: "auto !important",
      // 	maxWidth: "77vw",
      // 	"@media only screen and (min-width: 48rem)": {
      // 		maxWidth: "81vw !important",
      // 	},
      // 	"@media only screen and (min-width: 120rem)": {
      // 		maxWidth: "90vw !important",
      // 	},
      // },
    },

    RaCreate: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaList: {
      content: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaShow: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaEdit: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:hover": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:focus": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxWidth: "400px !important",
      },
    },

    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },

    MuiButton: {
      label: {
        fontWeight: "bold",
        color: "#248ad3",
      },
    },
    MuiLink: {
      root: {
        color: "#248ad3",
      },
    },
    RaReferenceField: {
      link: {
        color: "#248ad3",
      },
    },

    RaSaveButton: {
      button: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
    },
    RaSidebar: {
      root: {
        marginTop: "15px",
        position: "inherit",
        overflowY: "visible",
        zIndex: 1100,
        marginRight: 10,
      },
    },
    RaLoginForm: {
      loginButton: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
      verifyButton: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
    },

    RaListToolbar: {
      toolbar: {
        marginTop: "0px !important",
      },
    },
  },
});

export const MyLightTheme = createTheme({
  fontFamily: "'Spartan', sans-serif",
  palette: {
    type: "light",
    primary: { main: "#41cea5" },
    secondary: { main: "#248ad3" },
    error: { main: "#e5455c" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Spartan', sans-serif",
  },
  overrides: {
    // changing the bulk actions button styling:
    RaTopToolbar: {
      root: {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    RaFileInput: {
      dropZone: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    RaLayout: {
      root: {
        overflowY: "auto",
      },
      // content: {
      // 	overflow: "auto !important",
      // 	maxWidth: "77vw",
      // 	"@media only screen and (min-width: 48rem)": {
      // 		maxWidth: "81vw !important",
      // 	},
      // 	"@media only screen and (min-width: 120rem)": {
      // 		maxWidth: "90vw !important",
      // 	},
      // },
    },

    RaCreate: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaList: {
      content: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaShow: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaEdit: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:hover": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:focus": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxWidth: "400px !important",
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },

    MuiButton: {
      label: {
        fontWeight: "bold",
        color: "#41cea5",
      },
    },

    MuiLink: {
      root: {
        color: "#41cea5",
      },
    },

    RaReferenceField: {
      link: {
        color: "#41cea5",
      },
    },
    RaSaveButton: {
      button: {
        backgroundColor: "#dddddd",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      },
    },
    RaSidebar: {
      root: {
        marginTop: "15px",
        position: "inherit",
        overflowY: "visible",
        zIndex: 1100,
        marginRight: 10,
      },
    },
    RaLoginForm: {
      loginButton: {
        backgroundColor: "#dddddd",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      },
      verifyButton: {
        backgroundColor: "#dddddd",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      },
    },
    RaAppBar: {
      toolbar: {
        color: "white",
      },
      colorSecondary: {
        color: "#248ad3",
      },
    },
  },
});
