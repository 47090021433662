import FilterContainer from "components/containers/Filter";
import { DebouncedTextField } from "components/Fields";
import React from "react";

const NotificationTypesFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <DebouncedTextField label="Name" source="name" />
  </FilterContainer>
);

export default NotificationTypesFilter;
