import { UploadAndSave } from "./UploadAndSave";
import DeleteWithConfirmation from "./DeleteButtonWithConfirmation";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "react-admin";

const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
});

export const EditToolbar = (props) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <UploadAndSave label="Save" redirect="show" submitOnEnter />
      {!props.hidedelete && <DeleteWithConfirmation />}
    </Toolbar>
  );
};
