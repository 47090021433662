import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  required,
  TextInput,
  SimpleForm,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const MinimumVersionEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="application" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
        
        <SelectInput
            validate={required()}
            source="application"
            choices={[
              { id: "kangi", name: "Kangi" },
              { id: "stream", name: "Stream" },
              { id: "flashcards", name: "Flashcards" },
            ]}
        />

        <SelectInput
            validate={required()}
            source="platform"
            choices={[
              { id: "ios", name: "iOS" },
              { id: "android", name: "Android" },
            ]}
        />

        <TextInput source='version' label='Version (X.Y.Z)' />

      </SimpleForm>
    </Edit>
    </>
  );
}