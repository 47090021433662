import moment from "moment-timezone";
import { defaultTimezone } from "config";
import * as Functions from "../Functions";
import { quoteValues } from 'ListExporters/helper';

export const ItemExporter = async (data, fetchRelatedRecords, type) => {
	const cartPaymentMethod = await fetchRelatedRecords(data, "payment_method_id", "cart_payment_methods");
	const products = await fetchRelatedRecords(data, "product_id", "products");
	
	const exportableRecords = data.map((record) => {
		const {
			payment_method_id,
			product_id,
			created_at,
			updated_at,
			...exportableFields
		} = record;
		
		record = Functions.renameKey(record, "payment_method_id", "cart_payment_methods");
		record = Functions.renameKey(record, "product_id", "product");
		
		exportableFields.products = products[product_id]?.name;
		if(cartPaymentMethod[payment_method_id]){
			// exportableFields.amount = cartPaymentMethod[payment_method_id].amount
			exportableFields.type = cartPaymentMethod[payment_method_id].amount_type
		}
		
		exportableFields.created_at = 
		created_at && moment.tz(created_at, defaultTimezone)._d;
		exportableFields.updated_at =
		updated_at &&  moment.tz(updated_at, defaultTimezone)._d;
		
		return quoteValues(exportableFields);
	});
	
	switch (type) {
		case "OrderItem":
			{
				return {
					data: exportableRecords,
					headers: [
						"id",
						"product",
						"amount",
						"type",
						"created_at",
						"updated_at",
					],
					fileName: `Order Item`,
				};
			}
		case "OrderPaymentMethod":
			{
				return {
					data: exportableRecords,
					headers: [
						"id",
						"amount",
						"amount_type",
						"payment_method_type",
						"payment_method_token",
						"created_at",
						"updated_at",
					],
					fileName: `Payment Methods`,
				};
			}
		default:
			return [];

	}
};
