import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { 
  Edit,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TabbedForm,
  FormTab,
  BooleanInput
} from "react-admin";

export const UsersEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>
				<TabbedForm variant="standard" margin="normal" toolbar={<EditToolbar />}>

          <FormTab label="Personal Details">
            <TextInput source="first_name" label="First Name" fullWidth />
            <TextInput source="last_name" label="Last Name" fullWidth />
            <TextInput source="devices_limit" label="Devices Limit" fullWidth />
            <BooleanInput source="has_support_access" label="Allow single support access" fullWidth />
          </FormTab>

          <FormTab label="Achievements">

            <ReferenceArrayInput
              label="Achievements"
              source="achievements_ids"
              reference="achievements"
              perPage={25}
              filterToQuery={(searchText) => ({
                id: searchText,
                name: searchText,
                _autocomplete: true,
              })}
              sort={{ field: "id", order: "ASC" }}
              fullWidth
              allowEmpty
            >
              <AutocompleteArrayInput
                matchSuggestion={(filterValue, suggestion) => true}
                optionText={(choice) =>
                  choice && choice.id
                    ? `${choice.title} (${choice.id})`
                    : "None"
                }
              />
            </ReferenceArrayInput>
          </FormTab>

				</TabbedForm>
			</Edit>
		</>
	);
}
