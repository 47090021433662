import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { List, ImageField, TextField } from 'react-admin';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const BadgesList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Badges'
      // filters={<SetsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'sort_index', order: 'ASC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Badges' />}
    >
      <DataGridList edit show >
        <TextField source='sort_index' label='Sort Index' />
        <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
      </DataGridList>
      
    </List>
    </>
    )
};
