import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import DataGridList from "components/containers/List";
import ListActions from "components/ListAction/ListActions";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";

import {
   List,
   TextField,
   FunctionField
} from "react-admin";

import DeviceLimitFilter from './filter';

export const DeviceLimitList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title="Device Limit"
      filters={<DeviceLimitFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: "id", order: "ASC" }}
      {...props}
      empty={<EmptyPage create/>}
      actions={<ListActions listExporterType="DeviceLimit" />}
    >
      <DataGridList show edit>

        <TextField source="application" label="Application" />
        <FunctionField 
            label="User Type"
            render={(record) => record.user_type ?? "N/A"}
          />
        <FunctionField 
            label="Value"
            render={(record) => record.value ?? "N/A"}
          />
        <TextField source="description" label="Description" />

      </DataGridList>
    </List>
    </>
  );
}