import { Resource } from 'react-admin';
import FlashCardsIcon from '@mui/icons-material/Style';

import cardViews from './Card/index';
import unitViews from './Unit/index';
import lessonViews from './Lesson/index';
import badgeViews from './Badge/index';
import courseViews from './Course/index';
import avatarViews from './Avatar/index';
import categoryViews from './Category/index';
import acheivementViews from './Achievement/index';
import courseAssetsViews from './CourseAsset/index';

const flashCardRoutes = [
  <Resource
    name='flash_cards'
    icon={FlashCardsIcon}
    options={{ label: 'Flash Cards', isMenuParent: true }}
  />,
  <Resource {...courseViews} />,
  <Resource {...courseAssetsViews} />,
  <Resource {...categoryViews} />,
  <Resource {...unitViews} />,
  <Resource {...lessonViews} />,
  <Resource {...cardViews} />,
  <Resource {...badgeViews} />,
  <Resource {...avatarViews} />,
  <Resource {...acheivementViews} />
];
export default flashCardRoutes;
