import { Resource } from 'react-admin';

import notificationsViews from './Main/index';
import clientActionsViews from './ClientAction/index';
import notificationTypesViews from './NotificationType/index';
import notificationGroupsViews from './NotificationGroup/index'

const notificationsRoutes = [
  <Resource
    name='_notifications'
    // icon={ExpertsIcon}
    options={{ label: 'Notifications', isMenuParent: true }}
  />,
  <Resource {...notificationsViews} />,
  <Resource {...notificationTypesViews} />,
  <Resource {...clientActionsViews} />,
  <Resource {...notificationGroupsViews} />

];
export default notificationsRoutes;
