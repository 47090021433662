import LessonIcon from '@mui/icons-material/MenuBook';

import { LessonEdit } from './edit';
import { LessonList } from './list'
import { LessonShow } from './show';
import { LessonCreate } from './create';

export default {
  name: "lessons",
  options: { label: "Lessons", menuParent: "flash_cards" },
  icon: LessonIcon,
  list: LessonList,
  show: LessonShow,
  edit: LessonEdit,
  create: LessonCreate,
};
