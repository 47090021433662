import React from 'react';
import { BooleanField } from 'react-admin';

/**
 * Converts the 'source' value to a boolean and returns a BooleanField
 * @param source - The source
 * @param record - The record
 * @returns 
 */
const BooleanConvertedField = ({ source, record = {} }) => {
    const value = record[source];
    const booleanValue = !!value; // Convert the field to a boolean
    return <BooleanField source={source} record={{...record, [source]: booleanValue}}/>;
}

export default BooleanConvertedField;
