import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import { ReferenceInput, SelectInput } from 'react-admin';
import FilterContainer from 'components/containers/Filter';

const KDownloadsFilter = (props) => (
  <FilterContainer disableDates {...props} variant='standard' margin='normal'>

    <DebouncedTextField label='Name' source='name' />
    
    <SelectInput
      label="Type"
      source="type"
      choices={[
        { id: 'audio', name: "Audio" },
        { id: 'video', name: "Video" },
        { id: 'document', name: "Document" },
      ]}
    />

    <ReferenceInput
      source="parent_set_public_id"
      reference="sets"
      label="Parent Set"
      perPage={500}
    >
      <SelectInput optionText="full_name" />
    </ReferenceInput>

  </FilterContainer>
);

export default KDownloadsFilter;