import { DateFieldShow } from "components/Fields";
import { BooleanField } from "react-admin";
import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
import { ViewTitle } from "../../../components/AppBar/Titles";
import Breadcrumbs from "components/Breadcrumbs";

export const NotificationGroupsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <TextField source="description" />
          <BooleanField source="is_visible" />
          <DateFieldShow label="Created At" source="created_at" />
          <DateFieldShow label="Updated At" source="updated_at" />
        </SimpleShowLayout>
      </Show>
      </>
    );
}
