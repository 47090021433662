import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";
import { AutocompleteInput, ReferenceInput, SelectInput } from "react-admin";

const CourseAssetsFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">
    <DebouncedTextField label="Name" source="name" />

    <ReferenceInput
      source="course_id"
      reference="courses"
      label="Course"
      perPage={500}
      filterToQuery={(searchText) =>
        searchText
        ? { "nick_name": searchText,
            "short_name": searchText,
           _autocomplete: true }
          : ""
      }
    >
      <AutocompleteInput
        matchSuggestion={(filterValue, suggestion) => true}
        optionText={(choice) =>
           `${choice?.nick_name} (${choice?.id})`
        }
      />
    </ReferenceInput>
  </FilterContainer>
);

export default CourseAssetsFilter;
