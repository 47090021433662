import React from 'react';
import UserFilter from './filter';
import { List, TextField } from 'react-admin';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const UsersList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Users'
      filters={<UserFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Users' hideCreateButton={true} />}
    >
      <DataGridList edit show >
        <TextField source="username" label="Username" fullWidth />
        <TextField source="type" label="Type" fullWidth />
        <TextField source="status" label="Status" fullWidth />
        <TextField source='total_score' label="Total Score" fullWidth />
        
      </DataGridList>
      
    </List>
    </>
    )
};
