import XLSX from "xlsx";
import moment from "moment-timezone";
import { defaultTimezone } from "config";

// Rev Share Report Excels:
export const checkAndModifyExcelFileTypeData = (
  dynamicExcelType,
  finalExporterData
) => {
  switch (dynamicExcelType) {
    case "BASIC":
      let modifiedBasicExporterData = finalExporterData;
      for (const key in modifiedBasicExporterData) {
        if (modifiedBasicExporterData[key].details) {
          delete modifiedBasicExporterData[key].details;
        }
        modifiedBasicExporterData[key].grossPayment =
          modifiedBasicExporterData[key].grossPayment.USD &&
          "$" +
            modifiedBasicExporterData[key].grossPayment.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
        modifiedBasicExporterData[key].processingFee =
          modifiedBasicExporterData[key].processingFee.USD &&
          "$" +
            modifiedBasicExporterData[key].processingFee.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ) +
            ` (${modifiedBasicExporterData[key].processingFee.fee * 100}%)`;

        modifiedBasicExporterData[key].irs_fee =
          modifiedBasicExporterData[key].irs &&
          modifiedBasicExporterData[key].irs.fee &&
          "$" +
            modifiedBasicExporterData[key].irs.fee.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

        modifiedBasicExporterData[key].irs_pay =
          modifiedBasicExporterData[key].irs &&
          modifiedBasicExporterData[key].irs.pay &&
          "$" +
            modifiedBasicExporterData[key].irs.pay.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );

        modifiedBasicExporterData[key].irs_type =
          modifiedBasicExporterData[key].irs &&
          modifiedBasicExporterData[key].irs.type &&
          modifiedBasicExporterData[key].irs.type;

        delete modifiedBasicExporterData[key].irs;

        modifiedBasicExporterData[key].netPaymentAfterIrs =
          modifiedBasicExporterData[key].netPaymentAfterIrs &&
          modifiedBasicExporterData[key].netPaymentAfterIrs.USD &&
          "$" +
            modifiedBasicExporterData[
              key
            ].netPaymentAfterIrs.USD.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        modifiedBasicExporterData[key].netPaymentBeforeIrs =
          modifiedBasicExporterData[key].netPaymentBeforeIrs &&
          modifiedBasicExporterData[key].netPaymentBeforeIrs.USD &&
          "$" +
            modifiedBasicExporterData[
              key
            ].netPaymentBeforeIrs.USD.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      }

      return { data: modifiedBasicExporterData, type: "BASIC" };

    case "EXPANDED":
      let modifiedExpandedExporterData = finalExporterData;
      let sheetObj = {};
      let seperatedDetailsArray = [];
      let finalExpandedData = [
        {
          expertNames: [],
        },
      ];

      for (const key in modifiedExpandedExporterData) {
        modifiedExpandedExporterData[key].grossPayment =
          modifiedExpandedExporterData[key].grossPayment.USD &&
          "$" +
            modifiedExpandedExporterData[key].grossPayment.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
        modifiedExpandedExporterData[key].processingFee =
          modifiedExpandedExporterData[key].processingFee.USD &&
          "$" +
            modifiedExpandedExporterData[key].processingFee.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ) +
            ` (${modifiedExpandedExporterData[key].processingFee.fee * 100}%)`;

        modifiedExpandedExporterData[key].irs_fee =
          modifiedExpandedExporterData[key].irs &&
          modifiedExpandedExporterData[key].irs.fee &&
          "$" +
            modifiedExpandedExporterData[key].irs.fee.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );

        modifiedExpandedExporterData[key].irs_pay =
          modifiedExpandedExporterData[key].irs &&
          modifiedExpandedExporterData[key].irs.pay &&
          "$" +
            modifiedExpandedExporterData[key].irs.pay.USD.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );

        modifiedExpandedExporterData[key].irs_type =
          modifiedExpandedExporterData[key].irs &&
          modifiedExpandedExporterData[key].irs.type &&
          modifiedExpandedExporterData[key].irs.type;

        delete modifiedExpandedExporterData[key].irs;

        modifiedExpandedExporterData[key].netPaymentAfterIrs =
          modifiedExpandedExporterData[key].netPaymentAfterIrs &&
          modifiedExpandedExporterData[key].netPaymentAfterIrs.USD &&
          "$" +
            modifiedExpandedExporterData[
              key
            ].netPaymentAfterIrs.USD.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        modifiedExpandedExporterData[key].netPaymentBeforeIrs =
          modifiedExpandedExporterData[key].netPaymentBeforeIrs &&
          modifiedExpandedExporterData[key].netPaymentBeforeIrs.USD &&
          "$" +
            modifiedExpandedExporterData[
              key
            ].netPaymentBeforeIrs.USD.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

        // replacing spaces in expert names in order to make it equal to a object key name (sheets order):
        finalExpandedData[0].expertNames.push(
          modifiedExpandedExporterData[key].name.replace(/ /g, "_")
        );

        // pushing all of the expert expand details:
        for (const detailsKey in modifiedExpandedExporterData[key].details) {
          modifiedExpandedExporterData[key].details[detailsKey].amount =
            modifiedExpandedExporterData[key].details[detailsKey].amount &&
            modifiedExpandedExporterData[key].details[
              detailsKey
            ].amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "$";
          modifiedExpandedExporterData[key].details[detailsKey].expertRev =
            modifiedExpandedExporterData[key].details[detailsKey].expertRev &&
            modifiedExpandedExporterData[key].details[
              detailsKey
            ].expertRev.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "$";
          modifiedExpandedExporterData[key].details[detailsKey].revInPrecent =
            modifiedExpandedExporterData[key].details[detailsKey]
              .revInPrecent &&
            modifiedExpandedExporterData[key].details[
              detailsKey
            ].revInPrecent.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) *
              100 +
              "%";

          // here we're adding eventDate to the plan name in the same key
          modifiedExpandedExporterData[key].details[detailsKey].planName =
            modifiedExpandedExporterData[key].details[detailsKey].planName &&
            modifiedExpandedExporterData[key].details[detailsKey].eventDate !=
              null
              ? modifiedExpandedExporterData[key].details[detailsKey].planName +
                " " +
                moment.tz(
                  modifiedExpandedExporterData[key].details[detailsKey]
                    .eventDate
                ,defaultTimezone).format("MM/DD/YYYY HH:mm")
              : modifiedExpandedExporterData[key].details[detailsKey].planName;

          // after adding the eventDate to planName, here we're deleting the eventDate key
          delete modifiedExpandedExporterData[key].details[detailsKey]
            .eventDate;

          seperatedDetailsArray.push(
            modifiedExpandedExporterData[key].details[detailsKey]
          );
        }

        // after pushing all modified details to a seperate array, we
        // can delete it to present it as an expert with details
        // below in the export file
        if (modifiedExpandedExporterData[key].details) {
          delete modifiedExpandedExporterData[key].details;
        }

        let binaryWS = XLSX.utils.json_to_sheet([
          modifiedExpandedExporterData[key],
        ]);

        // organizing the first table as the expert and the second table as the rev share details:
        binaryWS = XLSX.utils.sheet_add_json(binaryWS, seperatedDetailsArray, {
          skipHeader: false,
          origin: 4,
        });

        // organizing the final values for the multi sheet webBook:
        sheetObj = {
          ...sheetObj,
          [modifiedExpandedExporterData[key].name.replace(/ /g, "_")]: binaryWS,
        };

        // reseting the details array to present the right values in each sheet specific sheet:
        seperatedDetailsArray = [];
      }

      return {
        names: finalExpandedData[0].expertNames,
        data: sheetObj,
        type: "EXPANDED",
      };

    default:
      break;
  }
};
