import MovieFilterIcon from '@mui/icons-material/MovieFilter';

import { KAppsList } from './list';
import { KAppsEdit } from './edit';
import { KAppsShow } from './show';

export default {
  name: "kapps",
  options: { label: "KApps", menuParent: "kangi" },
  icon: MovieFilterIcon,
  list: KAppsList,
  show: KAppsShow,
  // create: KAppCreate,
  edit: KAppsEdit,
};
