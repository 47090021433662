import { FormHelperText, TextField, InputAdornment } from "@material-ui/core";
import { useInput } from "ra-core";
import React, { useEffect, useState } from "react";
import { useDebounce } from "utils/hooks";

const DebouncedTextField = (props) => {
  const { Icon, debounce = 400, ...rest } = props;
  const {
    input: { onChange, value },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const [inputValue, setInputValue] = useState(value);
  const debouncedSearchTerm = useDebounce(inputValue, debounce);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        onChange(inputValue);
      } else {
        onChange("");
      }
    },
    // eslint-disable-next-line
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  return (
    <TextField
      margin="normal"
      id="debounceTextFeild-input"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      error={!!(touched && error)}
      required={isRequired}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{Icon && <Icon />}</InputAdornment>
        ),
      }}
      helperText={() => <FormHelperText>{touched && error}</FormHelperText>}
    />
  );
};

export default DebouncedTextField;
