import React from "react";
import { CreateToolbar } from "../../../components/Toolbar/CreateToolbar";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  PasswordInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const SupportersCreate = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Create {...props} title="Create Support User">
      <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
        <TextInput source="email" label="Email" validate={required()} fullWidth />
        <TextInput source="first_name" label="First Name" validate={required()} fullWidth />
        <TextInput source="last_name" label="Last Name" validate={required()} fullWidth />
        <TextInput source="username" label="Username" validate={required()} fullWidth />
        <PasswordInput source="password" label="Password" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
    </>
  );
}