import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const SupportersFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>

      <DebouncedTextField label='First Name' source='first_name' />
      <DebouncedTextField label='Last Name' source='last_name' />
      <DebouncedTextField label='Email' source='email' />

  </FilterContainer>
);

export default SupportersFilter;