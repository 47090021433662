import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Show,
  TabbedShowLayout,
  TextField,
  ReferenceField,
  Tab,
  Datagrid,
  ReferenceArrayField,
  BooleanField,
  ImageField,
  FunctionField
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { AudioField } from "components/Audio/AudioComponents";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '80px',
      height: '100px',
    }
  },
});

export const LessonShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source="name" label="Name" fullWidth />
            <BooleanField source="is_active" label="Is Active" fullWidth />
            <TextField source='sort_index' label='Sort index' fullWidth />

            <ReferenceField
                  source="unit_id"
                  reference='units'
                  link={(record, reference) =>
                  `/${reference}/${record.unit_id}/show`
                  }
              >
                  <FunctionField render={record => `${record.course_short_name} ${record.name}`} />
            </ReferenceField>
          </Tab>

          <Tab label="Cards">
            <ReferenceArrayField source="cards_ids" reference="cards" fullWidth>
              <Datagrid>
                <ReferenceField 
                  source="id"
                  reference="cards"
                  label="Name"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
                <AudioField source='audio.url' label='Audio' />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}