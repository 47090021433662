import { Resource } from 'react-admin';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import flashCardBulkCreateResource from '../BulkCreate/FlashCard/index';

const bulkCreateRoutes = [
  <Resource
    name='bulk_create'
    icon={DriveFolderUploadIcon}
    options={{ label: 'Bulk Create', isMenuParent: true }}
  />,
  <Resource {...flashCardBulkCreateResource} />
];
export default bulkCreateRoutes;
