import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField, BooleanField } from "react-admin";

export const DevicesShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <ReferenceField 
            source="owner_id"
            reference="users"
            label="User"
            link={(record, reference) =>
              `/${reference}/${record.owner_id}/show`
            }
          >
            <TextField source="username" label="Username" />
          </ReferenceField>
          <TextField source='nick_name' label="Nick Name" />
          <TextField source='os_version' label="Os Version" />
          <TextField source='app_version' label="App Version" />
          <BooleanField source='is_sandbox' label="Is Sandbox" />
          <TextField source='is_logged_in' label="Is Logged In" />
          <TextField source='permission' label="Permission" />
          <TextField source='os_type' label="Os Type" />
          <TextField source='app_name' label="Application" />
          <TextField source='type' label="Type" />
          <TextField source='token' label="Token" />
          <TextField source='fcm_token' label="FCM Token" />
          <TextField source='last_login' label="Last Login" />
        </SimpleShowLayout>
      </Show>
  </>
);
}