import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  PasswordInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const SupportersEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
        <TextInput source="email" label="Email" validate={required()} fullWidth />
        <TextInput source="first_name" label="First Name" validate={required()} fullWidth />
        <TextInput source="last_name" label="Last Name" validate={required()} fullWidth />
        <TextInput source="username" label="Username" validate={required()} fullWidth />
        <PasswordInput source="password" label="Password" validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
    </>
  );
}