import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { 
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
} from "react-admin";

export const AvatarsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source='sort_index' label='Sort Index' />
          <ImageField source="thumbnail.url" label="Thumbnail" />
        </SimpleShowLayout>
      </Show>
  </>
);
}