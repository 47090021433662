import React from 'react';
import CardFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { AudioField } from 'components/Audio/AudioComponents';
import { DateField } from 'components/Fields';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  ShowButton,
  BooleanField,
  BulkDeleteButton,
} from 'react-admin';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const CardsList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Cards'
      filters={<CardFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Cards' />}
      bulkActionButtons={<BulkDeleteButton/>}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" fullWidth />
        <TextField source="display_name" Name="Display Name" fullWidth />
        <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
        <AudioField source='audio.url' label='Audio' />
        <BooleanField source='is_active' label="Is Active" fullWidth />
        <DateField label="Created At" source="created_at" />
        <DateField label="Updated At" source="updated_at" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
    </>
  )
};
