import config from "../config";

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${config.API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password: password,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const response = await fetch(request);
    const token = response.headers.get('x-auth-token');

    if (response.status < 200 || response.status >= 300 || !token) {
      throw new Error(response.statusText);
    }

    localStorage.setItem("token", token);
    return Promise.resolve();
  },
  // Not in use currently
  verify: async ({ code }) => {
    const request = new Request(`${config.API_URL}/auth/verify`, {
      method: "POST",
      body: JSON.stringify({
        code: code,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
    });

    const response = await fetch(request);
    const token = response.headers.get("x-auth-token");

    if (token) {
      localStorage.setItem("token", token);
    } else {
      throw new Error(response.statusText);
    }

    return response.json();
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth:  () => 
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    if(error.status === 401){
      return Promise.reject(); //If it returns a rejected promise, react-admin calls the authProvider.logout() method immediately, and asks the user to log in again.
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};

export default authProvider;
