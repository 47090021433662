import React from 'react';
import { useRecordContext, Labeled } from 'react-admin';

export const AudioInput = ({ record }) => {
  const audioUrl = record.src;

  return (
    audioUrl ? <audio controls>
      <source src={audioUrl} type="audio/mpeg" />
    </audio> : <></>
  );
};

export const AudioField = ({ source, label, ...props }) => {
  let recordContext = useRecordContext();
  let record = props.record ?? recordContext;

  if (!record) return null;
  
  source = source.split('.');

  let audioUrl;
  for (const key of source) {
    record = record?.[key];
    audioUrl = record;
  }

  return (
      audioUrl ? <Labeled label={label}>
        <audio controls>
          <source src={audioUrl}/>
        </audio>
      </Labeled> : <></>
  );
};