import React, {useEffect, useState} from 'react';
import { TextField, ImageField } from 'react-admin';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AudioField } from "components/Audio/AudioComponents";
import { VideoComponent } from "components/ImageContainer/VideoComponent";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { Grid, Typography, Card, CardContent, CardMedia } from '@material-ui/core';

// Verifies that the image is able to be fetch from the url and renders it if so.
const CheckedImage = ({ src, alt, onImageLoadStatus, ...props }) => {
    const [imageExists, setImageExists] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImageExists(true);
            onImageLoadStatus(true);
        };
        img.onerror = () => {
            setImageExists(false);
            onImageLoadStatus(false);
        };
    }, [src, onImageLoadStatus]);

    if (!imageExists) {
        return null;
    }

    return <img src={src} alt={alt} {...props} />;
};

export const SectionList = ({ sections }) => (
    <div>
        {sections.map((section, index) => (
            <Section key={index} section={section} />
        ))}
    </div>
);

const Section = ({ section }) => (
    <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TextField source="name" record={section} />
        </AccordionSummary>
        <AccordionDetails>
            <ul>
                {section.items.map((item, itemIndex) => (
                    <StyledSectionItem key={itemIndex} item={item} sectionType={section.type} />
                ))}
            </ul>
        </AccordionDetails>
    </Accordion>
);

const SectionItem = ({ item, sectionType }) => (
    <li>
        <TextField source="name" record={item} />
        <ImageField source="thumbUrl" record={item} />
        {sectionType === "audio" && <AudioField label="Audio" source="fileUrl" record={item} />}
        {sectionType === "video" && <VideoComponent url={item.fileUrl} />}
    </li>
);

const StyledSectionItem = ({ item, sectionType }) => {
    const [showImage, setShowImage] = useState(true);

    return (
        <Card style={{ marginBottom: '15px', overflow: 'hidden' }}>
            <CardContent>
                <Grid container spacing={3}>
                    {showImage && (
                        <Grid item xs={12} sm={3}>
                            <CheckedImage
                                alt="Thumbnail"
                                height="140"
                                src={item.thumbUrl}
                                title="Thumbnail"
                                onImageLoadStatus={exists => setShowImage(exists)} // Handles correct placing on card (removes blank space if image not exists)
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">
                            <TextField source="name" record={item} />
                        </Typography>
                        {sectionType === "audio" && <AudioField label="Audio" source="fileUrl" record={item} />}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {sectionType === "video" &&
                            <video controls width="100%">
                                <source src={item.fileUrl} type="video/mp4" />
                            </video>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

