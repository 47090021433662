import CategoryIcon from '@mui/icons-material/Category';

import { CategoryList } from './list'
import { CategoryEdit } from './edit';
import { CategoryShow } from './show';
import { CategoryCreate } from './create';

export default {
  name: "categories",
  options: { label: "Categories", menuParent: "flash_cards" },
  icon: CategoryIcon,
  list: CategoryList,
  show: CategoryShow,
  create: CategoryCreate,
  edit: CategoryEdit,
};
