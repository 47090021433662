import { useCallback, useState } from "react";

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(
    (value) =>
      setState((state) => (typeof value === "boolean" ? value : !state)),
    []
  );

  return [state, toggle];
};
