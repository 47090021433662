import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const SetsFilter = (props) => (
  <FilterContainer disableDates {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Full Name' source='full_name' />
    <DebouncedTextField label='Short Name' source='short_name' />

  </FilterContainer>
);

export default SetsFilter;