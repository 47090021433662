import React from "react";
import ReactDOM from "react-dom";
import moment from "moment-timezone";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import { defaultTimezone } from "config";
import { ErrorBoundary } from "./initializer";
import reportWebVitals from "./reportWebVitals";

// in order to unset default time zone of UTC - comment out
moment.tz.setDefault(defaultTimezone);

if (ErrorBoundary) {
  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
