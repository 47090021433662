import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import DataGridList from "components/containers/List";
import ListActions from "components/ListAction/ListActions";
import EmptyPage from "components/Page/Empty/EmptyPage";
import { Paginator } from "components/Paginator/Paginator";

import {
   List,
   TextField,
   FunctionField
} from "react-admin";

import MinimumVersionFilter from './filter';

export const MinimumVersionList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title="Minimum Versions"
      filters={<MinimumVersionFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: "id", order: "ASC" }}
      {...props}
      empty={<EmptyPage create/>}
      actions={<ListActions listExporterType="MinimumVersion" />}
    >
      <DataGridList show edit>

        <TextField source="application" label="Application" />
        <TextField source="platform" label="Platform" />

        <FunctionField 
            label="Version"
            render={(record) => record.version ?? "N/A"}
          />

      </DataGridList>
    </List>
    </>
  );
}