import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { 
  Edit,
  required,
  TextInput,
  FileInput,
  ImageField,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

export const CourseAssetsEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>

				<SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
          <TextInput source="name" validate={required()} fullWidth />

          <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Image"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="course_id"
                reference="courses"
                label="Course"
                validate={required()}
                perPage={25}
                filterToQuery={(searchText) => ({
                  name: searchText,
                  _autocomplete: true,
                })}
                fullWidth
                disabled
                >
                <AutocompleteInput
                  matchSuggestion={(filterValue, suggestion) => true}
                  optionText={(choice) =>
                    choice && choice.id
                      ? `${choice.nick_name} (${choice.id})`
                      : "None"
                  }
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>

        </SimpleForm>
			</Edit>
		</>
	);
}


