import React from "react";
import { DateField } from "components/Fields";
import { ShowButton } from "components/Buttons";
import { Datagrid, TextField, EditButton } from "react-admin";
import  GenerateTempCredentails from 'components/Buttons/GenerateTempCredentails/index';

const DataGridList = ({ disableDates, expandable, show, edit, generatePanelExpander,
    generateTempCredentails, children, resource }) => {
  return (
    <Datagrid id={`${resource}-table`} expand={expandable ? generatePanelExpander() : null}>
      <TextField source="id" label="ID" />
      {children}
      { disableDates ? null : <DateField label="Created At" source="created_at" /> }
      { disableDates ? null : <DateField label="Updated At" source="updated_at" /> }
      {edit && <EditButton />}
      {show && <ShowButton />}
      {generateTempCredentails && <GenerateTempCredentails />}
    </Datagrid>
  );
};
export default DataGridList;
