import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import { List, TextField, ReferenceField, BulkUpdateButton, FunctionField, Datagrid, DateField, EditButton, ShowButton, BulkDeleteButton } from 'react-admin';

import KAppsFilter from './filter';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'

const KAppsBulkActionButtons = () => (
  <>
      <BulkUpdateButton data={{ status: "1" }} label="Set Restricted Access" icon={<LockIcon />} />
      <BulkUpdateButton data={{ status: "2" }} label="Set Public Access" icon={<PublicIcon />} />
      <BulkDeleteButton />
  </>
);

export const KAppsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='KApps'
      filters={<KAppsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='KApps' hideCreateButton={true} />}
      bulkActionButtons={<KAppsBulkActionButtons /> }
    >
      <Datagrid>
        <TextField source='id' label='ID'/>
        <TextField source='name' label='Name'/>
        <TextField source='type' label='Type'/>

        <FunctionField
          label="Access"
          render={record => `${record.status === '2' ? 'Public' : 'Restricted'}`}
        />

        <ReferenceField
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            link={(record, reference) => `/${reference}/${record.parent_set_public_id}/show`}
          >
            <TextField source="full_name" label="Parent Set ID" />
          </ReferenceField>

        <DateField label="Created At" source="created_at" />
        <DateField label="Updated At" source="updated_at" />
        <EditButton />
        <ShowButton />
      
      </Datagrid>
      
    </List>
    </>
    )
};
