import { Resource } from 'react-admin';
import UserIcon from '@mui/icons-material/Person';

import userViews from '../Users/Main/index'

const usersRoutes = [
  <Resource
    name='user'
    icon={UserIcon}
    options={{ label: 'Users', isMenuParent: true }}
  />,
  <Resource {...userViews} />,
];
export default usersRoutes;
