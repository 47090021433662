import {
    Show,
    SimpleShowLayout,
    TextField,
    BooleanField, 
    ImageField,  
    ReferenceField
} from "react-admin";

import { DateFieldShow } from "components/Fields";
import { ViewTitle } from "../../../components/AppBar/Titles";
import Breadcrumbs from "components/Breadcrumbs";

export const NotificationTypesShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <TextField source="title_pattern" />
          <TextField source="body_pattern" />
          <ImageField source="thumbnail.url" label="Thumbnail" />
          <BooleanField source="use_sender_thumbnail" />
          <BooleanField source="send_push" />
          <BooleanField source="send_sms" />
          <ReferenceField
                source="client_action_id"
                reference="client_actions"
                label="Client Action"
              >
                <TextField source="name" />
              </ReferenceField> 
          <DateFieldShow label="Created At" source="created_at" />
          <DateFieldShow label="Updated At" source="updated_at" />
        </SimpleShowLayout>
      </Show>
      </>
    );
}
