import React from 'react';
import LessonFilter from './filter';
import { DateField } from 'components/Fields';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { List, TextField, ReferenceField, BooleanField, Datagrid, EditButton, ShowButton } from 'react-admin';

export const LessonList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Lessons'
      filters={<LessonFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Lessons' />}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" fullWidth />
        <BooleanField source="is_active" label="Is Active" fullWidth />
        <TextField source='sort_index' label='Sort index' fullWidth />

        <ReferenceField
              source="unit_id"
              reference='units'
              label="Course"
              link={false}
          >
              <TextField source="course_short_name" label="Course Short Name" />
        </ReferenceField>

        <ReferenceField
              source="unit_id"
              reference='units'
              link={(record, reference) =>
              `/${reference}/${record.unit_id}/show`
              }
          >
              <TextField source="name" />
        </ReferenceField>

        <DateField label="Created At" source="created_at" />
        <DateField label="Updated At" source="updated_at" />
        <EditButton />
        <ShowButton />

      </Datagrid>
      
    </List>
    </>
    )
};
