import DeviceLimitIcon from '@mui/icons-material/PhonelinkLock';

import { DeviceLimitList } from './list';
import { DeviceLimitShow } from './show';
import { DeviceLimitCreate } from './create';
import { DeviceLimitEdit } from './edit';

export default {
  name: "device_limit",
  options: { label: "Devices Limit", menuParent: "system" },
  icon: DeviceLimitIcon,
  list: DeviceLimitList,
  show: DeviceLimitShow,
  create: DeviceLimitCreate,
  edit: DeviceLimitEdit,
};
