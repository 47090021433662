import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "../../themes/ThemeContext";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LightThemeIcon from "@material-ui/icons/Brightness4";
import DarkThemeIcon from "@material-ui/icons/BrightnessHigh";
import HelenDoronLogo from '../../assets/images/Logo/HelenDoronLogo.svg'
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { AppBar } from "react-admin";
import Container from "@material-ui/core/Container";
import { AutoCompleteResources } from "./AutoCompleteResources";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 560,
  },
  spacer: {
    flex: 1,
  },
  logoContainer: {
    marginlLeft: 50,
    margin: 10,
    marginRight: "auto",
    left: "0",
    right: "0",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "-1",
    cursor: "pointer",
    width: "100%"
  },
  logo: {
    width: "100px"
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },

  searchIcon: {
    padding: "12px",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "5",
    cursor: "pointer",
    marginLeft: "16px",
    color: "inherit",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    height: "30px",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "80%",
    marginLeft: "8px",
    [theme.breakpoints.up("sm")]: {
      width: "1px",
      "&:focus": {
        width: "15ch",
      },
    },
  },
  option: {
    fontSize: 16,
    marginTop: 2,
    "& > span": {
      marginTop: 6,
      marginRight: 10,
      fontSize: 20,
    },
  },

  stagingIcon: {
    marginLeft: "10px",
    color: theme.palette.type === "dark" ? "#248ad3" : "#616161e6",
    transform: "scale(0.7)"
  },
}));

const MyAppBar = (props) => {
  const classes = useStyles();
  const [ThemeIcon, setThemeIcon] = useState(DarkThemeIcon);
  const { theme: myThemeType, setTheme: setMyThemeType } = useContext(ThemeContext);
  const [toggleSearchBarBoolean, setToggleSearchBarBoolean] = useState(false);
  const [clearInputValue, setClearInputValue] = useState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const inputEl = useRef(null);

  useEffect(() => {
    if (myThemeType === "dark") {
      setThemeIcon(DarkThemeIcon);
    } else {
      setThemeIcon(LightThemeIcon);
    }
  }, []); // eslint-disable-line

  const toggleTheme = () => {
    console.log(myThemeType)
    switch (myThemeType) {
      case "dark":
        localStorage.setItem("myThemeType", "light");
        setMyThemeType("light");
        setThemeIcon(LightThemeIcon);
        break;

      case "light":
        localStorage.setItem("myThemeType", "dark");
        setMyThemeType("dark");
        setThemeIcon(DarkThemeIcon);
        break;

      default:
        break;
    }
  };

  const showSearchBarHandler = (element) => {
    switch (element) {
      case "onClick":
        if (!toggleSearchBarBoolean) {
          inputEl.current.focus();
          setToggleSearchBarBoolean(true);
        }
        break;
      case "onBlur":
        setToggleSearchBarBoolean(false);
        break;

      default:
        break;
    }
  };

  const autocompleteSelectHandler = (event, value) => {
    // every time the key prop value changes, it re-renders the autocomplete with the default value:
    setClearInputValue(Math.floor(Math.random() * Math.floor(1000)));

    if (value) {
      switch (value.label) {
        case "Experts":
          history.push("/experts");
          break;
        case "Time Zones":
          history.push("/time_zones");
          break;
        case "Hubs":
          history.push("/groups");
          break;
        case "Categories":
          history.push("/categories");
          break;
        case "Rev. Share Model":
          history.push("/revenue_sharing_settings");
          break;
        case "Memberships":
          history.push("/memberships");
          break;
        case "Products":
          history.push("/plans");
          break;
        case "Meetings":
          history.push("/meetings");
          break;
        case "Languages":
          history.push("/languages");
          break;
        case "Skills":
          history.push("/skills");
          break;
        case "Campaigns":
          history.push("/campaigns");
          break;
        case "Campaigns Templates":
          history.push("/campaign_templates");
          break;
        case "Template Categories":
          history.push("/template_categories");
          break;
        case "Users":
          history.push("/users");
          break;
        case "Subscriptions":
          history.push("/subscriptions");
          break;
        case "Transactions":
          history.push("/payments");
          break;
        case "Reviews":
          history.push("/reviews");
          break;
        case "Collections":
          history.push("/feeds");
          break;
        case "Feed Items":
          history.push("/feed_items");
          break;
        case "Countries":
          history.push("/countries");
          break;
        case "States":
          history.push("/states");
          break;
        case "Cities":
          history.push("/cities");
          break;
        case "Admin Users":
          history.push("/admin_users");
          break;
        case "Admin Roles":
          history.push("/admin_roles");
          break;
        case "Settings":
          history.push("/settings");
          break;
        case "Event Log":
          history.push("/app_events");
          break;
        case "Coupons":
          history.push("/coupons");
          break;
        case "Contents":
          history.push("/contents");
          break;
        case "Articles":
          history.push("/articles");
          break;
        case "MAU Report":
          history.push("/mau");
          break;
        case "Rev. Share Report":
          history.push("/revenue_sharing");
          break;

        case "Pages":
          history.push("/pages");
        case "Banners":
          history.push("/banners");
        case "Trending Search":
          history.push("/trending_searches");
        case "Accessories":
          history.push("/accessories");
          break;

        case "Testimonial":
          history.push("/testimonials");
          break;
        case "MoM Report":
          history.push("/mom");
          break;
        case "Partner Stages":
          history.push("/partner_stages");
          break;
        default:
          history.push("/");
          break;
      }
    }
  };

  return (
    <AppBar {...props} container={Container}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />

      <div className={classes.logoContainer} onClick={() => history.push("./")}>
        {/* <img className={classes.logo} src={HelenDoronLogo} alt="" /> */}

        {/* Showing staging icon inside AppBar when the environment is staging: */}
        {process.env.REACT_APP_ENV &&
        process.env.REACT_APP_ENV === "production" ? (
          ""
        ) : (
          <span className={classes.stagingIcon}>
            {/* <BuildIcon /> */}
          </span>
        )}

    
      </div>
      {matches && (
        <div className={classes.search}>
          <Tooltip
            title="Search Resource"
            placement="bottom"
            className={classes.searchIcon}
            onClick={() => showSearchBarHandler("onClick")}
          >
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <Autocomplete
            blurOnSelect
            clearOnBlur
            key={clearInputValue}
            onChange={autocompleteSelectHandler}
            id="custom-input-demo"
            options={AutoCompleteResources}
            noOptionsText="No resources"
            classes={{
              option: classes.option,
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <span>{option.icon}</span>
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                placeholder="Search resource"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputRef={inputEl}
                inputProps={{ ...params.inputProps }}
                onBlur={() =>
                  setTimeout(() => {
                    showSearchBarHandler("onBlur");
                  }, [300])
                }
              />
            )}
          />
        </div>
      )}
      <Tooltip title="Change Theme" placement="bottom">
        <IconButton
          style={{ cursor: "pointer", zIndex: "5", color: "white" }}
          onClick={() => toggleTheme()}
        >
          <ThemeIcon />
        </IconButton>
      </Tooltip>
    </AppBar>
  );
};

export default MyAppBar;
