

import pluralize from "pluralize";
import { Link } from "react-admin";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    breadcrumbContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
});

const firstLetterToUpperCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const handleUnderscore = (str) => {
    const splittedArr = str.split('_');

    return firstLetterToUpperCase(splittedArr[0]) + ' ' + firstLetterToUpperCase(splittedArr[1]);
}
  
const Breadcrumbs = ({parent}) => {
    const classes = useStyles();
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);
    const parentUpperCase = parent[0] === '_' ? firstLetterToUpperCase(parent.slice(1)) : firstLetterToUpperCase(parent);
    const pathsUpperCase = paths.map((path) => path.includes('_') ?
    handleUnderscore(path)
    : firstLetterToUpperCase(path));

    let hasMainResource = false;

    if (parent[0] === '_') { //means we have main resource
        hasMainResource = true;
    }

    const renderBreadcrumbs = () => {
        switch (pathsUpperCase.length) {
            case 1: //list
                return hasMainResource ? 'Main' : pathsUpperCase
            case 2: //edit or create
            { switch (paths[paths.length - 1]) {
                case "create": //create
                    return <>
                    <Link to={'/' + paths[0]}>{hasMainResource ? 'Main' : pathsUpperCase[0]}</Link>
                    &nbsp;
                    {'>'}
                    &nbsp;
                    {'Create'}
                    </>
                default: //edit
                    return <>
                        <Link to={'/' + paths[0]}>{hasMainResource ? 'Main' : pathsUpperCase[0]}</Link>
                        &nbsp;
                        {'>'}
                        &nbsp;
                        <Link to={location.pathname + '/show'}>{pluralize.singular(pathsUpperCase[0]) + ' #' + pathsUpperCase[1]}</Link>
                        &nbsp;
                        {'> Edit'}
                    </>
            }}
            case 3: //show 
                return <>
                <Link to={'/' + paths[0]}>{hasMainResource ? 'Main' : pathsUpperCase[0]}</Link>
                &nbsp;
                {'>'}
                &nbsp;
                {pluralize.singular(pathsUpperCase[0]) + ' #' + pathsUpperCase[1]}
                </>
            default:
                break;
        }
    }

    return <div className={classes.breadcrumbContainer}>
        {parentUpperCase}
        &nbsp;
        {'>'}
        &nbsp;
        {renderBreadcrumbs()}
    </div>
}


export default Breadcrumbs;