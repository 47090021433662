import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField } from "react-admin";

export const SupportersShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>

          <TextField source='id' label='ID'/>
          <TextField source='full_name' label='Name'/>
          <TextField source='email' label='Email'/>
          <TextField source='role' label='Role'/>
          <TextField source='last_login' label='Last Login'/>
      
        </SimpleShowLayout>
      </Show>
  </>
);
}