import React from 'react';
import AdminLogsFilter from './filter';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { List, TextField, FunctionField, ReferenceField } from 'react-admin';
import UserAccessFilter from './filter';


export const UserAccessList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='User Access'
      filters={<UserAccessFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='UserAccess' hideCreateButton={true}  />}
    >
      <DataGridList show>

        <TextField source='user_id' label='User ID'/>
        <ReferenceField 
          source="user_id"
          reference="users"
          label="Username"
          link={false}
        >
          <FunctionField render={(record) => `${record.username}`} />
        </ReferenceField>
        
        <TextField source='client_app_id' label='Application ID'/>
        <TextField source='last_login' label='Last Login'/>

      </DataGridList>
    </List>
    </>
    )
};
