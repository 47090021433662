import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "components/AppBar/Titles";
import { AudioField } from 'components/Audio/AudioComponents';
import { 
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  SingleFieldList,
  ReferenceArrayField,
  FunctionField,
  BooleanField
} from "react-admin";

import { Chip } from '@material-ui/core';

export const CardsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="name" label="Name" />
          <TextField source="display_name" label="Display Name" />
          <BooleanField source='is_active' label='Is Active' />
          <TextField source="definition" label="Definition" />
          <TextField source="example" label="Example" />

          <ReferenceArrayField source="units_ids" reference="units">
              <SingleFieldList>
                  <FunctionField render={record => 
                      <Chip label={`${record.course_short_name} ${record.name}`} />
                  } />
              </SingleFieldList>
          </ReferenceArrayField>

          <ImageField source="thumbnail.url" label="Thumbnail" />
          <AudioField label="Audio" source="audio.url" />
          
        </SimpleShowLayout>
      </Show>
    </>
  );
}