import CourseAssetsIcon from '@mui/icons-material/Collections';

import { CourseAssetsEdit } from './edit';
import { CourseAssetsList } from './list'
import { CourseAssetsShow } from './show';
import { CourseAssetsCreate } from './create';

export default {
  name: "course_assets",
  options: { label: "Course Assets", menuParent: "flash_cards" },
  icon: CourseAssetsIcon,
  list: CourseAssetsList,
  show: CourseAssetsShow,
  create: CourseAssetsCreate,
  edit: CourseAssetsEdit,
};
