import React from 'react';
import KDownloadsFilter from './filter';
import { List, TextField, ReferenceField } from 'react-admin';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { DownloadableField } from 'Downloadable/DownloadableComponents';

export const KDownloadsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='KDownloads'
      filters={<KDownloadsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='KDownloads' />}
    >
      <DataGridList edit show>

        <TextField source='name' label='Name'/>
        <TextField source='type' label='Type'/>

        <ReferenceField
            source="parent_set_public_id"
            reference="sets"
            label="Parent Set"
            link={(record, reference) => `/${reference}/${record.parent_set_public_id}/show`}
          >
            <TextField source="full_name" label="Parent Set ID" />
        </ReferenceField>

        <DownloadableField source='download_url.url' label='FILE' />

      </DataGridList>
      
    </List>
    </>
    )
};
