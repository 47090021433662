import AvatarIcon from '@mui/icons-material/Face';

import { AvatarsEdit } from './edit';
import { AvatarsList } from './list'
import { AvatarsShow } from './show';
import { AvatarCreate } from './create';

export default {
  name: "fc_avatars",
  options: { label: "Avatars", menuParent: "flash_cards" },
  icon: AvatarIcon,
  list: AvatarsList,
  show: AvatarsShow,
  create: AvatarCreate,
  edit: AvatarsEdit,
};
