import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import {
  Create,
  required,
  TextInput,
  FileInput,
  ImageField,
  SimpleForm,
} from "react-admin";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";

export const CategoryCreate = (props) => (
  <Create {...props} title="Create Category">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source='name' validate={required()} />
      <TextInput source="sort_index" validate={required()} />
      <FileInput
        source="thumbnail_file"
        accept="image/*"
        label="Thumbnail"
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source="src" />
      </FileInput>
    </SimpleForm>
  </Create>
  );