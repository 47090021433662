import BadgesIcon from '@mui/icons-material/MilitaryTech';

import { BadgesEdit } from './edit';
import { BadgesList } from './list'
import { BadgesShow } from './show';
import { BadgeCreate } from './create';

export default {
  name: "badges",
  options: { label: "Badges", menuParent: "flash_cards" },
  icon: BadgesIcon,
  list: BadgesList,
  show: BadgesShow,
  create: BadgeCreate,
  edit: BadgesEdit,
};
