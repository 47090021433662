import React from "react";
import { Field, Form } from "react-final-form";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslate } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

const formStyles = makeStyles(
	(theme) => ({
		form: {
			padding: "0 1em 1em 1em",
		},
		input: {
			marginTop: "1em",
		},
		verifyButton: {
			width: "100%",
			marginTop: "30px",
			backgroundColor: "#00d4b5",
		},
		icon: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			maxWidth: "300px",
			textAlign: "center",
			margin: "0 auto",
			marginBottom: "40px",
		},
	}),
	{ name: "RaLoginForm" }
);

export const VerificationForm = ({ onSubmit, loading, ...props }) => {
	const classes = formStyles(props);
	const translate = useTranslate();

	const validate = (values) => {
		const errors = { code: undefined };

		if (!values.code) {
			errors.code = translate("ra.validation.required");
		}

		return errors;
	};

	return (
		<div>
			<h2>Two-Factor Authentication</h2>
			<p className={classes.instructions}>
				We've just sent an SMS to the phone number associated with your account. Please
				enter it below.
			</p>

			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} noValidate>
						<div className={classes.form}>
							<div className={classes.input}>
								<Field
									autoFocus
									id="code"
									name="code"
									component={Input}
									type="password"
									label="Verification Code"
									disabled={loading}
								/>
							</div>
						</div>
						<CardActions>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={loading}
								className={classes.verifyButton}
							>
								{loading && (
									<CircularProgress className={classes.icon} size={18} thickness={2} />
								)}
								Submit
							</Button>
						</CardActions>
					</form>
				)}
			/>
		</div>
	);
};
