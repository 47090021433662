import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/ViewList";
import { MenuItemLink, getResources, DashboardMenuItem } from "react-admin";
import ParentMenu from "./ParentMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    padding: 0,
    marginTop: "1em",
  },
  rootOpen: {
    width: 240,
    maxWidth: 360,
    padding: 0,
    // marginTop: "1em",
    height: "100%",
    background: "#303030",
    transition: "width 0.3s",
  },
  rootClose: {
    width: 55,
    maxWidth: 360,
    padding: 0,
    // marginTop: "1em",
    height: "100%",
    background: "#303030",
    transition: "width 0.3s",
  },
  nested: {
    paddingLeft: theme.spacing(3),
    "&:hover": {
      transform: 'scale(1.1)',
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  avatar: {
    height: 30,
    width: 30,
  },
  menuWithNoList: {
    paddingLeft: "10px",
  },
  disabledHover:{

    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "transparent"
    }
  }
}));
const Menu = ({ onMenuClick, logout, ...rest }) => {
  const history = useHistory();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const [openResource, setOpenResource] = useState();
  const parentMenu = resources.filter(
    (resource) => resource.options.isMenuParent
  );
  const classes = useStyles();
  const handleParentResouce = (index) => (e) => {
    if (index === openResource) setOpenResource();
    else setOpenResource(index);
  };
  const handleClose = (e) => {
    setOpenResource();
  };
  return (
    <List
      className={open ? classes.rootOpen : classes.rootClose}
      component="nav"
    >
      {rest.hasDashboard && (
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      )}
      {parentMenu?.map((parent, index) => {
        return (
          <ParentMenu
            key={parent.name}
            onMenuClick={onMenuClick}
            open={open}
            parent={parent}
            onParentClick={handleParentResouce(index)}
            isParentOpen={index === openResource ? true : false}
            closeParent={handleClose}
          >
            {resources
              .filter((resource) => resource.options.menuParent === parent.name)
              .map((parentChild) => {
                return (
                  <ListItem className={classes.nested} key={parentChild.name}>
                    <MenuItemLink
                      className={classes.disabledHover}
                      style={{ padding: "5px",textDecoration: 'none' }}
                      to={`/${parentChild.name}`}
                      primaryText={
                        (parentChild.options && parentChild.options.label) ||
                        parentChild.name
                      }
                      leftIcon={
                        parentChild.icon ? (
                          <parentChild.icon />
                        ) : (
                          <DefaultIcon />
                        )
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                    />
                  </ListItem>
                );
              })}
          </ParentMenu>
        );
      })}
      {isXSmall && logout}
    </List>
  );
};

export default Menu;
