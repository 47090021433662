import React from "react";
import styles from "./svg.module.css";
const Accessories = () => {
  return (
    <svg
    className={styles.iconRoot}
    id="outline"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <path d="m318.78223 269.04a45.49861 45.49861 0 0 0 41.12695-54.289l-3.22461-16.1211 42.3457-42.3457a30.6228 30.6228 0 0 0 -13.28564-51.11157 30.6365 30.6365 0 0 0 -34.16431-47.57253 30.62114 30.62114 0 0 0 -48.75293-19.34473 30.6228 30.6228 0 0 0 -51.11157-13.28564l-233.373 233.373 11.31446 11.31446 58.34272-58.34274 116.68555 116.68555-154.34278 154.34277 11.31446 11.31446 221.90771-221.90772zm17.36914-24.50586a29.5146 29.5146 0 0 1 -18.59571 8.55273l-16.60566 1.27763 42.13721-42.13721 1.13257 5.66236a29.4393 29.4393 0 0 1 -8.06841 26.64453zm-215.16137-22.8586-21.67555-21.67554 44.68555-44.68555 21.67554 21.67555a23.83531 23.83531 0 0 0 -6.64624 4.66626l-33.37305 33.37304a23.83531 23.83531 0 0 0 -4.66625 6.64624zm49.35376 49.35376-33.37305-33.373a8.00771 8.00771 0 0 1 0-11.3125l33.37305-33.37305a8.00653 8.00653 0 0 1 11.3125 0l33.37305 33.37305a8.00771 8.00771 0 0 1 0 11.3125l-33.37305 33.373a8.00771 8.00771 0 0 1 -11.31251 0zm50.34276-7.0293-12.68652 12.68652-4.68579-4.68652 12.686-12.68555zm-4.68652 52.68555-29.67578-29.67578a23.791 23.791 0 0 0 5.62646-3.74463l10.39209 10.39209a8.00181 8.00181 0 0 0 11.31446 0l24-24a8.00122 8.00122 0 0 0 0-11.31446l-10.39209-10.39209a23.79781 23.79781 0 0 0 3.74487-5.62622l29.67554 29.67554zm-60.68555-172.68555 107.71582-107.71582a14.62689 14.62689 0 1 1 20.68555 20.68555l-1.37305 1.373-16 16 11.31446 11.31446 16-16 1.373-1.373 16-16a14.62689 14.62689 0 1 1 20.68555 20.68555l-9.37305 9.373-8 8 11.31446 11.31446 8-8 9.373-9.373 8-8a14.62689 14.62689 0 1 1 20.68555 20.68555l-16 16-1.37305 1.373-16 16 11.31446 11.31446 16-16 1.373-1.373a14.62689 14.62689 0 1 1 20.68555 20.68555l-115.7157 115.71579z" />
    <path d="m472.48584 245.17139 7.43408-52.03955a8.00468 8.00468 0 0 0 -2.26269-6.78907l-16-16a8.003 8.003 0 0 0 -11.31446 0l-32 32-216 216-32 32a8.00122 8.00122 0 0 0 0 11.31446l16 16a8.00719 8.00719 0 0 0 6.78907 2.26269l52.03955-7.43408 9.85791 9.85791a46.62921 46.62921 0 0 0 65.9414 0l53.40137-53.40137a24.05059 24.05059 0 0 0 5.501-25.39746l-14.19727-37.873 37.86914 14.20117a24.05248 24.05248 0 0 0 25.39746-5.501l53.40137-53.40137a46.62921 46.62921 0 0 0 0-65.9414zm-16.48584-57.85791 7.51465 7.51464-5.17165 36.20044-23.02855-23.02856zm-261.17188 276.20117-7.51464-7.51465 20.68652-20.68555 23.02856 23.02832zm276.20118-153.8584-53.40137 53.40137a8.01856 8.01856 0 0 1 -8.46582 1.835l-37.874-14.2041a15.99986 15.99986 0 0 0 -20.59864 20.59961l14.20313 37.874a8.01707 8.01707 0 0 1 -1.835 8.46582l-53.40135 53.40135a30.62563 30.62563 0 0 1 -43.3125 0l-47.03027-47.0293 204.68652-204.68652 47.0293 47.03027a30.62563 30.62563 0 0 1 0 43.3125z" />
    <path d="m416 264h16v64h-16z" />
    <path d="m384 288h16v24h-16z" />
    <path d="m304 376h16v64h-16z" />
    <path d="m272 400h16v24h-16z" />
  </svg>
  );
};
export default Accessories;
