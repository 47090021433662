import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import { SelectInput, ReferenceInput } from 'react-admin';
import FilterContainer from 'components/containers/Filter';

const KAppsFilter = (props) => (
  <FilterContainer disableDates {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Name' source='name' />
    <DebouncedTextField label='Type' source='type' />

    <ReferenceInput
      source="parent_set_public_id"
      reference="sets"
      label="Parent Set"
      perPage={500}
    >
      <SelectInput optionText="full_name" />
    </ReferenceInput>

  </FilterContainer>
);

export default KAppsFilter;