import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ColorInput } from "react-admin-color-input";
import { EditTitle } from "components/AppBar/Titles";
import { EditToolbar } from "components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Edit, SimpleForm, TextInput, required, FileInput, ImageField, BooleanInput } from "react-admin";

export const StreamCoursesEdit = (props) => {
	const menuParent = props.options.menuParent;

	return (
		<>
			<Breadcrumbs parent={menuParent}/>
			<Edit title={<EditTitle source="name" />} {...props}>
				<SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete={false} />}>
          <TextInput source='hdc_id' label='HDC Id' validate={required()} />
          <TextInput source='name' validate={required()} />
					<TextInput source="short_name" validate={required()} />
          <TextInput source='sort_index' validate={required()} />
          <BooleanInput source="is_active" label='Is Active?' />
          <TextInput source='progression_points' validate={required()} />
          <ColorInput source='main_screen_card_color' label="Main Screen Card Color" picker='Swatches'/>

          <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FileInput
            source="featured_banner_image_file"
            accept="image/*"
            label="Featured Banner Image"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>

          <FileInput
            source="roadmap_background_file"
            accept="image/*"
            label="Roadmap Background"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
          </FileInput>
				</SimpleForm>
			</Edit>
		</>
	);
}
