import AdminLogsIcon from '@mui/icons-material/ManageSearch';

import { AdminLogsList } from './list';
import { AdminLogsShow } from './show';

export default {
  name: "logs",
  options: { label: "Logs", menuParent: "system" },
  icon: AdminLogsIcon,
  list: AdminLogsList,
  show: AdminLogsShow
};
