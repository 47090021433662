import { Resource } from 'react-admin';
import BungalowIcon from '@mui/icons-material/Bungalow';

import setViews from './Set/index';
import kappViews from './KApp/index';
import newsViews from './News/index';
import kdownloadViews from './KDownload/index';

const kangiRoutes = [
  <Resource
    name='kangi'
    icon={BungalowIcon}
    options={{ label: 'Kangi', isMenuParent: true }}
  />,
  <Resource {...setViews} />,
  <Resource {...kappViews} />,
  <Resource { ...kdownloadViews } />,
  <Resource { ...newsViews } />
];
export default kangiRoutes;
