import React from "react";

import { SelectInput } from "react-admin";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const DeviceLimitFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">

    <SelectInput
      source="application"
      label="Application"
      choices={[
        { id: "kangi", name: "Kangi" },
        { id: "stream", name: "Stream" },
        { id: "flashcards", name: "Flashcards" },
      ]}
    />
    <DebouncedTextField source="user_type" label="User Type" />
    <DebouncedTextField source="value" label="Value" />
    <DebouncedTextField source="description" label="Description" />

  </FilterContainer>
);

export default DeviceLimitFilter;
