import React from "react";

import { SelectInput } from "react-admin";
import FilterContainer from "components/containers/Filter";

const MinimumVersionFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal">

    <SelectInput
      source="application"
      label="Application"
      choices={[
        { id: "kangi", name: "Kangi" },
        { id: "stream", name: "Stream" },
        { id: "flashcards", name: "Flashcards" },
      ]}
    />

    <SelectInput
      source="platform"
      label="Platform"
      choices={[
        { id: "ios", name: "iOS" },
        { id: "android", name: "Android" },
      ]}
    />

  </FilterContainer>
);

export default MinimumVersionFilter;
