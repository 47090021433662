import React from 'react';
import SetsFilter from './filter';
import { List, TextField } from 'react-admin';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import BooleanConvertedField from 'components/BooleanConvertedField/BooleanConvertedField';

export const SetsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <List
        title='Sets'
        filters={<SetsFilter />}
        pagination={<Paginator />}
        perPage={15}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
        empty={<EmptyPage create />}
        actions={<ListActions listExporterType='Sets' hideCreateButton={true} />}
      >
        <DataGridList edit show>
          <TextField source='full_name' label='Full Name' />
          <TextField source='short_name' label='Short Name' />
          <TextField source='type' label='Type' />

          { /* TODO: Remove, needed only for tab visibility in Show */}
          <BooleanConvertedField source='value_from_stream_global_sets_json' label='Is Included in Stream?' />
        </DataGridList>


      </List>
    </>
  )
};
