import React from 'react';
import { List, TextField, ReferenceField } from 'react-admin';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';

export const NotificationsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Units'
      // filters={<SetsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='Notifications' />}
    >
      <DataGridList edit show >
        <TextField source="title" label="Title" />
        <TextField source="body" label="Body" fullWidth />
        <TextField source="delivery_status" label="Delivery status" fullWidth />

      </DataGridList>
      
    </List>
    </>
    )
};
