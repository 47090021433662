import React from "react";
import { JSONField } from 'components/JSON';
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField } from "react-admin";

export const AdminLogsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          
        <TextField source="id" label="ID"/>
        <TextField source="admin_user_id" label="Admin's ID"/>

        <ReferenceField 
          source="admin_user_id"
          reference="admin_users"
          label="Admin's Name"
          link={false}
        >
          <FunctionField render={(record) => `${record.full_name ?? record.email}`} />
        </ReferenceField>

        <FunctionField 
          label="Action" 
          render={(record) => {
          // Capitalize first letter of the record.action
          return record.action.charAt(0).toUpperCase() + record.action.slice(1);
        }}
        />

        <TextField source='resource' label='Resource'/>

        <JSONField source='previous_values' label="Previous Values" />
        <JSONField source='new_values' label="New Values" />
      
        </SimpleShowLayout>
      </Show>
  </>
);
}