import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { SectionList } from 'components/StreamHelpers/show';
import { BackupList } from "components/StreamHelpers/backups";
import { ViewTitle } from "../../../components/AppBar/Titles";
import {
  Tab,
  Show,
  TextField,
  ImageField,
  FunctionField,
  TabbedShowLayout,
} from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '100px',
      height: '100px',
    }
  },
});

export const SetsShow = (props) => {
  const menuParent = props.options.menuParent;
  const classes = useStyles();

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source="short_name" label="Short Name" />
            <TextField source="type" label="Type" />
            <ImageField source="icon_url.url" label="Icon Thumbnail" className={classes.customImage} sortable={false} />
            <ImageField source="bgl0.url" label="bgl0 Thumbnail" className={classes.customImage} sortable={false} />
            <ImageField source="bgl1.url" label="bgl1 Thumbnail" className={classes.customImage} sortable={false} />
            <ImageField source="bgl2.url" label="bgl2 Thumbnail" className={classes.customImage} sortable={false} />
            <ImageField source="bgl3.url" label="bgl3 Thumbnail" className={classes.customImage} sortable={false} />
          </Tab>

          <Tab label="Stream Content">
            <FunctionField addLabel={false} render={(record) => (
              (record.stream_set_data_json?.sections &&
                <SectionList sections={record.stream_set_data_json.sections} />)
            )} />
          </Tab>

          { /* Removed Backup Ability (set_data.json file handling changed on server) */ }
          {/* <Tab label="Backups">
            <FunctionField addLabel={false} render={(record) => (
              <>
                {record.value_from_stream_global_sets_json &&
                  <BackupList expanded={true} title="Stream Backups" timestamps={record.stream_set_data_backups} app={"stream"} {...props} />
                }
              </>
            )} />
          </Tab> */}

        </TabbedShowLayout>
      </Show>
    </>
  );
}