import React from 'react';
import SupportTicketsFilter from './filter';
import Breadcrumbs from '../../../components/Breadcrumbs/index'
import EmptyPage from '../../../components/Page/Empty/EmptyPage';
import { Paginator } from '../../../components/Paginator/Paginator';
import ListActions from '../../../components/ListAction/ListActions'
import DataGridList from '../../../components/containers/List/index';
import { List, TextField, ReferenceField, BooleanField } from 'react-admin';


export const SupportTicketsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Logs'
      filters={<SupportTicketsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='AdminLogs' hideCreateButton={true} />}
    >
      <DataGridList show edit>

        <ReferenceField 
          source="user_id"
          reference="users"
          label="User"
          link={(record, reference) =>
            `/${reference}/${record.id}/show`
          }
        >
          <TextField source="username" label="Username" />
        </ReferenceField>

        <TextField source='app_name' label='Application Name' />
        <BooleanField source='is_resolved' label='is Resolved' />

      </DataGridList>
    </List>
    </>
    )
};
