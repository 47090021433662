import UserAccessIcon from '@mui/icons-material/Key';

import { UserAccessList } from './list';
import { UserAccessShow } from './show';

export default {
  name: "user_access",
  options: { label: "User Access", menuParent: "system" },
  icon: UserAccessIcon,
  list: UserAccessList,
  show: UserAccessShow
};
