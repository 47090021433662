import AvatarIcon from '@mui/icons-material/Face';

import { StreamAvatarsEdit } from './edit';
import { StreamAvatarsList } from './list'
import { StreamAvatarsShow } from './show';
import { StreamAvatarCreate } from './create';

export default {
  name: "stream_avatars",
  options: { label: "Avatars", menuParent: "stream" },
  icon: AvatarIcon,
  list: StreamAvatarsList,
  show: StreamAvatarsShow,
  create: StreamAvatarCreate,
  edit: StreamAvatarsEdit,
};
