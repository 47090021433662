import React, { useState } from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { DownloadableInput } from 'Downloadable/DownloadableComponents';
import { FileUploaderPlaceholder } from 'components/FileUploaderPlaceholder';
import { Create, SimpleForm, TextInput, required, SelectInput, FileInput, FunctionField } from "react-admin";

export const NewsCreate = (props) => {
  const [selectedAttachmentType, setSelectedAttachmentType] = useState("file");

  return (
    <Create {...props} title="Create News Item">
      <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>

        <TextInput source="title" validate={required()} fullWidth />
        <TextInput source="description" validate={required()} fullWidth />

        <SelectInput
          label="Status"
          source="status"
          defaultValue="active"
          choices={[
            { id: 'active', name: "Active" },
            { id: 'inactive', name: "Inactive" },
          ]}
        />

        <SelectInput
          label="Users Group"
          source="user_group"
          defaultValue="all"
          choices={[
            { id: 'all', name: "All" },
            { id: 'network', name: "Network" },
            { id: 'students', name: "Students" },
          ]}
        />

        <SelectInput
          source="attachment_type"
          defaultValue="file"
          onChange={(e) => {
            setSelectedAttachmentType(e.target.value);
          }}
          choices={[
            { id: "website", name: "Website URL" },
            { id: "file", name: "New File" },
            { id: null, name: "None" },
          ]}
        />

        <FunctionField label="Attachment" render={(record) => {
          if (selectedAttachmentType === null) return null;

          if (selectedAttachmentType === 'website') {
            return <TextInput source='website_url' label='Full URL' multiline fullWidth />
          }

          return <FileInput
            source="file_key_file"
            label="File"
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <DownloadableInput source='src' extension='pdf' />
          </FileInput>
        }}
        />

      </SimpleForm>
    </Create>
  )
};

