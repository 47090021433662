import React, { useState } from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { DownloadableInput } from 'Downloadable/DownloadableComponents';
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Edit, SimpleForm, TextInput, required, SelectInput, FileInput, FunctionField } from "react-admin";


export const NewsEdit = (props) => {
  const menuParent = props.options.menuParent;
  const [selectedAttachmentType, setSelectedAttachmentType] = useState("file");

  return (
    <>
      <Breadcrumbs parent={menuParent} />
      <Edit title={<EditTitle source="name" />} {...props}>
        <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>

          <TextInput source="title" validate={required()} fullWidth />
          <TextInput source="description" validate={required()} fullWidth />

          <SelectInput
            label="Status"
            source="status"
            choices={[
              { id: 'active', name: "Active" },
              { id: 'inactive', name: "Inactive" },
            ]}
          />

          <SelectInput
            label="Users Group"
            source="user_group"
            choices={[
              { id: 'all', name: "All" },
              { id: 'network', name: "Network" },
              { id: 'students', name: "Students" },
            ]}
          />

          <SelectInput
            source="attachment_type"
            defaultValue="file"
            onChange={(e) => {
              setSelectedAttachmentType(e.target.value);
            }}
            choices={[
              { id: "website", name: "Website URL" },
              { id: "file", name: "New File" },
              { id: null, name: "None" },
            ]}
          />

          <FunctionField label="Attachment" render={(record) => {
            if (selectedAttachmentType === null) return null;

            if (selectedAttachmentType === 'website') {
              return <TextInput source='website_url' label='Full URL' multiline fullWidth />
            }

            return <FileInput
              source="file_key_file"
              label="File"
              placeholder={<FileUploaderPlaceholder />}
              fullWidth
            >
              <DownloadableInput source='src' extension='pdf' />
            </FileInput>
          }}
          />

        </SimpleForm>
      </Edit>
    </>
  );
}
