import React from "react";
import styles from "./svg.module.css";
export const Banner = () => {
  return (
    <svg
      className={styles.iconRoot}
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 344.406 344.406"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M243.243,0h-142.08c-13.767,0.044-24.916,11.193-24.96,24.96v298.8c0,21,21.04,31.2,37.48,5l58.52-93.28l58.52,93.28
			c16.44,26.2,37.48,15.96,37.48-5V24.96C268.159,11.193,257.01,0.044,243.243,0z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
