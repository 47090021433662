import DownloadingIcon from '@mui/icons-material/Downloading';

import { KDownloadsList } from './list';
import { KDownloadsEdit } from './edit';
import { KDownloadsShow } from './show';
import { KDownloadCreate } from './create';

export default {
  name: "kdownloads",
  options: { label: "KDownloads", menuParent: "kangi" },
  icon: DownloadingIcon,
  list: KDownloadsList,
  show: KDownloadsShow,
  create: KDownloadCreate,
  edit: KDownloadsEdit,
};
