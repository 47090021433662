import moment from 'moment-timezone';
import {
  Pagination,
  ExportButton,
  useListContext,
  downloadCSV,
  useRecordContext,
  useDataProvider
} from "react-admin";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import AddSourceButton from "components/Buttons/AddSource";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExportButtonIcon from "@material-ui/icons/GetApp";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import ExcelIcon from "@material-ui/icons/GridOn";
import CSVIcon from "@material-ui/icons/Subject";

import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import jsonExport from "jsonexport/dist";
import { ItemExporter } from "AdditionalExporters/CartAndOrder/ItemExporter";
import { CouponExporter } from "AdditionalExporters/CartAndOrder/CouponExporter";
import { PaymentMethodExporter } from "AdditionalExporters/CartAndOrder/PaymentMethodExporter";

const pdfHtmlResouceCustomize = (resource) => {
  return resource.replaceAll("/", "_");
};

// @params:
// dataProvider - The object received from useDataProvider()
// resource - The requested model for which the record is exported
// record - The model with which there is an association with the model requested for export.
// key - A foreign key used to find the resource from the record.
// example: resource: order_items (Model), record: order(e.g id 2688) (Instance), key: order_id (foreign key)
const fetchResource = async (dataProvider, resource, record, key) => {
  const { data } = await dataProvider.getList(resource, {
    filter: {},
    sort: { field: "id", order: "ASC" },
    pagination: { page: 1, perPage: 1000000 },
  });
  const orderItems = data.filter((_record) => _record[key] === record.id);

  return orderItems;
}

const exportTimesAsUTC = async (exporterData) => {
  exporterData?.data.forEach((record) => {
    const { created_at, updated_at } = record;
    if (created_at && updated_at) {
      const offset = (new Date(created_at)).getTimezoneOffset();
      record.created_at = moment(created_at).add(moment.duration(offset, 'minutes'))._d;
      record.updated_at = moment(updated_at).add(moment.duration(offset, 'minutes'))._d;
    }
  });
}

const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    overflowY: "hidden",
    marginTop: "13px",
  },
}));

export const Paginator = (props) => {
  const classes = useStyles();
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const {
    className,
    maxResults,
    showExportButton,
    type,
    data,
    originalResource,
    subList,
    userID,
    ...rest // eslint-disable-line
  } = props;
  const { currentSort, resource, total } = useListContext();
  const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
  const modifiedData = data && Object.values(data);

  let dynamicExportFileType;
  let finalExporterData;
  
  const finalExporter = async (records, fetchRelatedRecords) => {
    switch (type) {
      case "CartItem":
      case "OrderItem":
        {
          const orderItems = await fetchResource(dataProvider, resource, record, "order_id");
          finalExporterData = await ItemExporter(modifiedData ?? orderItems, fetchRelatedRecords, type);
          exportTimesAsUTC(finalExporterData);
          break;
        }
      case "CartCoupon":
      case "OrderCoupon":
        {
          finalExporterData = await CouponExporter(modifiedData ?? records, fetchRelatedRecords,type.includes("Order") ? "order": "cart");
          break;
        }
      case "CartPaymentMethod":
      case "OrderPaymentMethod":
        {
          const payemntMethods = await fetchResource(dataProvider, resource, record, "order_id");
          finalExporterData = await ItemExporter(modifiedData ?? payemntMethods, fetchRelatedRecords, type);
          exportTimesAsUTC(finalExporterData);
          break;
        }
      default:
        break;

      }

    switch (dynamicExportFileType) {
      case "EXCEL":
        let binaryWS = XLSX.utils.json_to_sheet(finalExporterData.data);
        let webBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          webBook,
          binaryWS,
          finalExporterData.fileName
        );
        XLSX.writeFile(webBook, `${finalExporterData.fileName}.xlsx`);
        break;

      case "CSV":
        jsonExport(
          finalExporterData.data,
          {
            headers: finalExporterData.headers,
          },
          (err, csv) => {
            downloadCSV(csv, finalExporterData.fileName);
          }
        );
        break;

      case "PDF":
        var doc = new jsPDF("l", "pt");
        doc.autoTable({
          theme: "grid",
          head: [Object.values(finalExporterData.headers)],
          // html: `#${originalResource}-${subList}-sub-table`,
          // html: `#${pdfHtmlResouceCustomize(resource)}-table`,
          body: finalExporterData.data.map((record) => {
            switch (type) {
              case "OrderItem":
                {
                  return [
                    record.id,
                    record.product_type,
                    record.unit_price_cents/100,
                    record.type,
                    record.created_at,
                    record.updated_at
                  ];
                }
              case "OrderPaymentMethod":
                {
                  return [
                    record.id,
                    record.amount,
                    record.amount_type,
                    record.payment_method_type,
                    record.payment_method_token,
                    record.created_at,
                    record.updated_at
                  ];
                }
              default:
                return [];
        
              }
          }),
          styles: {
            minCellWidth: 40,
          },
        });
        doc.save(`${finalExporterData.fileName}.pdf`);
        break;
      default:
        break;
    }
  };

  const handleClick = (event) => {
    setExportButtonAnchorEl(event.currentTarget);
  };

  const handleClose = (exportFileType) => {
    setExportButtonAnchorEl(null);

    switch (exportFileType) {
      case "EXCEL":
        dynamicExportFileType = "EXCEL";
        break;

      case "CSV":
        dynamicExportFileType = "CSV";
        break;

      case "PDF":
        dynamicExportFileType = "PDF";
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.paginationContainer}>
      {showExportButton && total !== 0 && (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
            startIcon={<ExportButtonIcon />} // eslint-disable-line
          >
            EXPORT
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={exportButtonAnchorEl}
            keepMounted
            open={Boolean(exportButtonAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleClose("EXCEL")}
              aria-controls="simple-menu"
              aria-haspopup="true"
              size="small"
              startIcon={<ExportButtonIcon />} // eslint-disable-line
            >
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finalExporter}
                label="EXCEL"
                icon={<ExcelIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>

            <MenuItem onClick={() => handleClose("CSV")}>
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finalExporter}
                label="CSV"
                icon={<CSVIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleClose("PDF")}>
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finalExporter}
                label="PDF"
                icon={<PDFIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>
          </Menu>
        </>
      )}

      {type === "UserSources" && <AddSourceButton userID={userID} />}

      <Pagination
        style={{ marginLeft: "auto", overflowY: "hidden" }}
        rowsPerPageOptions={[15, 25, 50, 100, 500]}
        {...props}
      />
    </div>
  );
};
