import React, { useState } from "react";

import config from '../../config';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';

import {
    Confirm,
    required,
    TextInput,
    FileInput,
    useRefresh,
    ImageField,
    SelectInput,
    useRecordContext,
} from "react-admin";


const StreamContentTab = (props) => {
    const record = useRecordContext();
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [createItemSectionIndex, setCreateItemSectionIndex] = useState(null);
    const [createdItemName, setCreatedItemName] = useState("");

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const refresh = useRefresh();

    const handleSelectItem = (sectionIndex, itemIndex) => {
        if (itemIndex === 'None') { // Clearing the value if selection is "None"
            setSelectedItemIndex(null);
        } else {
            setCreateItemSectionIndex(null);
            setSelectedItemIndex({ sectionIndex, itemIndex });
        }
    };

    const handleClickCreateButton = (sectionIndex) => {
        setSelectedItemIndex(null);
        setCreateItemSectionIndex(prevState => prevState === sectionIndex ? null : sectionIndex);
    };

    const handleNewItemNameChange = (event) => {
        setCreatedItemName(event.target.value);
    };

    // Handle deletion of the selected Stream section item
    const handleDeleteItem = (selectedItemIndex, itemName) => {
        if (selectedItemIndex?.sectionIndex === null || selectedItemIndex?.itemIndex === null) return;

        setDeletingItem({ selectedItemIndex, itemName });
        setDeleteDialogOpen(true);
    };

    const handleCancelDelete = () => {
        // Close the dialog without deleting
        setDeleteDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (!deletingItem) return;

        const { sectionIndex, itemIndex } = deletingItem.selectedItemIndex;

        const token = localStorage.getItem("token");
        const resource = props.basePath.split("/").pop();
        const DELETE_STREAM_ITEM_ENDPOINT = `${config.API_URL}/resources/${resource}/stream/section/${sectionIndex}/item/${itemIndex}`;

        try {
            const deleteRequest = new Request(DELETE_STREAM_ITEM_ENDPOINT, {
                method: "DELETE",
                body: JSON.stringify({ resource_id: props?.record?.id }),
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Auth-Token": token,
                }),
            });

            const deleteResponse = await fetch(deleteRequest);

            if (!deleteResponse.ok) {
                throw new Error(`Error: ${deleteResponse.status}`);
            }

            toast.success(`Successfully Removed '${deletingItem.itemName}!'`, {
                position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000
            });

            refresh(); // Refetches the data after deletion

        } catch (error) {
            console.error("Deletion error:", error);
            toast.error("Stream Item Deletion Error", {
                position: toast.POSITION.BOTTOM_RIGHT, autoClose: 15000
            });
        } finally {
            setDeleteDialogOpen(false);
        }
    };

    return (
        <div>
            {record.stream_set_data_json?.sections?.map((section, sectionIndex) => (
                <Accordion key={sectionIndex}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6">Section Name: {section.name}</Typography>
                            <Typography variant="h6">Section Type: {section.type}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>

                        {/* Edit Section */}
                        <div>
                            {/* Specific Item Selection Dropdown */}
                            <SelectInput
                                source={`selected_item_section_${sectionIndex}`}
                                label="Select Item"
                                choices={[
                                    { id: null, name: 'None' }, // Add "None" option
                                    ...section.items?.map((item, index) => ({
                                        id: index,
                                        name: item.name
                                    }))
                                ]}
                                onChange={(event) => handleSelectItem(sectionIndex, event.target.value)}
                            />

                            {/* Specific Item Editing */}
                            {section.items?.map((item, itemIndex) => {
                                if (selectedItemIndex && selectedItemIndex.sectionIndex === sectionIndex && selectedItemIndex.itemIndex === itemIndex) {
                                    return (
                                        <div key={itemIndex}>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextInput
                                                    disabled
                                                    source={`stream_set_data_json.sections[${sectionIndex}].items[${itemIndex}].name`}
                                                    label="Item Name"
                                                    defaultValue={item.name}
                                                />

                                                {/* Delete Button: Section Item */}
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: "#FF9494", marginLeft: 8 }}
                                                    onClick={() => handleDeleteItem(selectedItemIndex, item.name)}
                                                >
                                                    Remove Item
                                                </Button>
                                            </div>

                                            {section.type !== 'audio' && (
                                                <FileInput
                                                    source={`stream_edit_thumb_section_${sectionIndex}_item_${itemIndex}_file`}
                                                    accept="image/*"
                                                    label="Thumbnail"
                                                    placeholder={<FileUploaderPlaceholder />}
                                                    fullWidth
                                                >
                                                    <ImageField source="src" />
                                                </FileInput>
                                            )}
                                            <FileInput
                                                source={`stream_edit_content_section_${sectionIndex}_item_${itemIndex}_file`}
                                                accept="video/*,audio/*"
                                                label="File"
                                                placeholder={<FileUploaderPlaceholder />}
                                                fullWidth
                                            >
                                                <ImageField source="src" />
                                            </FileInput>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>

                        {/* Create Item */}
                        <div>
                            {/* Create Button */}
                            {(!selectedItemIndex || !(selectedItemIndex?.itemIndex)) &&
                                <Button
                                    variant="contained"
                                    style={{
                                        marginTop: 8,
                                        marginBottom: 16,
                                        backgroundColor: createItemSectionIndex === sectionIndex ? '#555' : '', // Change background when active
                                    }}
                                    onClick={() => handleClickCreateButton(sectionIndex)}
                                >
                                    Create new item
                                </Button>
                            }

                            {/* Create Item Form */}
                            {createItemSectionIndex === sectionIndex && (
                                <div>
                                    <TextInput
                                        source={`stream_set_data_json.sections[${sectionIndex}].newItem.name`}
                                        label="New Item Name"
                                        validate={required()}
                                        fullWidth
                                        onChange={handleNewItemNameChange}
                                    />
                                    {section.type !== 'audio' && (
                                        <FileInput
                                            source={`stream_create_thumb_section_${sectionIndex}_item_${section.items.length}_name_${createdItemName}_file`}
                                            accept="image/*"
                                            label="Thumbnail"
                                            placeholder={<FileUploaderPlaceholder />}
                                            fullWidth
                                        >
                                            <ImageField source="src" />
                                        </FileInput>
                                    )}
                                    <FileInput
                                        source={`stream_create_content_section_${sectionIndex}_item_${section.items.length}_name_${createdItemName}_file`}
                                        accept="video/*,audio/*"
                                        validate={required()}
                                        label="File"
                                        placeholder={<FileUploaderPlaceholder />}
                                        fullWidth
                                    >
                                        <ImageField source="src" />
                                    </FileInput>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}

            {/* Stream Content Item Delete Confirmation Dialog */}
            <Confirm
                isOpen={deleteDialogOpen}
                loading={false}
                title="Delete Item"
                content={`Are you sure you want to remove the item "${deletingItem?.itemName}" from Stream content?`}
                onConfirm={handleConfirmDelete}
                onClose={handleCancelDelete}
                confirm="Yes"
                cancel="No"
            />
        </div>
    );
};

export default StreamContentTab;