import CardsIcon from '@mui/icons-material/Style';

import { CardsEdit } from './edit';
import { CardsList } from './list'
import { CardsShow } from './show';
import { CardCreate } from './create';

export default {
  name: "cards",
  options: { label: "Cards", menuParent: "flash_cards" },
  icon: CardsIcon,
  list: CardsList,
  show: CardsShow,
  create: CardCreate,
  edit: CardsEdit,
};
