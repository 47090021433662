import { FileInput, FunctionField, FileField } from "react-admin";
import { useEffect, useState } from "react";
import { FileUploaderPlaceholder } from "./FilePlaceholder/FileUploaderPlaceholder";
export const FileContainer = ({ url, type, noPadding }) => {
  switch (type) {
    // case "pdf":
    //   return (
    //     <embed
    //       src={url}
    //       style={{ width: "320px", height: "275px", marginBottom: "2rem" }}
    //       type="application/pdf"
    //     ></embed>
    //   );
    // case "image":
    //   return (
    //     <img
    //       style={{
    //         objectFit: "contain",
    //         width: "320px",
    //         height: "240px",
    //         marginBottom: noPadding ? "0" : "2rem",
    //       }}
    //       src={url}
    //       alt=""
    //     />
    //   );
    default:
        return (
            <embed
              src={url}
              style={{ width: "320px", height: "275px", marginBottom: "2rem" }}
            ></embed>
          );
  }
};

export const EventProductEmailAttachment = ({ formData }) => {
  const [currentFileType, setCurrentFileType] = useState("pdf");

  const checkIfEmailAttachmentExists = () => {
    if (formData) {
      if (
        formData.email_attachment &&
        formData.email_attachment.key &&
        formData.email_attachment.url
      ) {
        if (formData.email_attachment.key.includes("pdf")) {
          setCurrentFileType("pdf");
        } else {
          setCurrentFileType("image");
        }
      }
    }
  };

  useEffect(() => {
    if (formData) {
      checkIfEmailAttachmentExists();
    }
  }, []); // eslint-disable-line

  return (
    <FileInput
      accept="image/*, application/pdf"
      source="email_attachment_file"
      label="Email Attachment"
      placeholder={<FileUploaderPlaceholder type="email_attachment" />}
      fullWidth
    >
      <FunctionField
        render={(record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FileField source="src" />
              {/* NOTICE: when uploading new file on a nested structure like the following, the src blob will be as a undefined key */}
              <FileContainer
                url={record.src ? record.src : record.undefined}
                type={
                  record?.rawFile
                    ? record.rawFile.type.includes("pdf")
                      ? "pdf"
                      : "image"
                    : currentFileType
                }
              />
            </div>
          );
        }}
      />
    </FileInput>
  );
};
