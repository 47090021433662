import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";


export const NotificationGroupsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
        <Edit title={<EditTitle source="name" />} {...props}>
            <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
            <TextInput source="name" />
            <TextInput source="description" fullWidth/>
            <BooleanInput source="is_visible" fullWidth />
            </SimpleForm>
        </Edit>
      </>
    );
}
